/* eslint-disable */
import styled from "styled-components";
import {device} from "../../../utils/breakpoints";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {Heading} from "../heading";
import React from "react";
import {Alert} from "@mui/material";
import {MdOutlineCloudUpload} from "react-icons/md";

const StyledModal = styled(Modal)`
  .css-1gkse3u {
    min-width: 300px !important;

    @media ${device.tablet} {
      min-width: 400px !important;

    }
  }
`
const StyledBox = styled(Box)`
  display: grid;
  grid-template-rows: auto;

  gap: 2rem;

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-u9osun.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }
`
const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(2, auto);
  gap: 1.5rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
  width: 100%;
  align-items: center;


  @media ${device.mobileXL} {
    max-width: 250px;
  }
`
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};
const Ul = styled.ul`
  list-style: inside;
`
const Container = styled.div`
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  gap: 2rem;
  align-items: center;
`
export const PricesUploadModal = ({showModal, handleClose, onSubmit, errors, setData, data}) => {
    const handleChangeExcelFile = (excelFile) => {
        const file = excelFile.target.files[0];
        setData({...data, file})
    }
    return <StyledModal
        open={showModal}
        onClose={handleClose}
    >
        <StyledBox sx={style}>
            <Heading h3 as='h3'>Wgraj cennik w Excelu</Heading>
            <Alert severity="info">
                Wgrywany plik, musi być zgodny z następującymi wymaganiami:
                <Ul>
                    <li>Kolumna "A" - Nazwa dziedziny (np. Konsultacje)</li>
                    <li>Kolumna "B" - Nazwa usługi (np. Konsultacja wybielanie)</li>
                    <li>Kolumna "C" - Cena usługi w PLN (np. 70)</li>
                </Ul>
            </Alert>
            <input type="file" name="excel_file"
                onChange={handleChangeExcelFile}
                   accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            {errors?.default !== undefined && <Alert severity="error">{errors.default}</Alert>}
            <Container>
                <AddItem
                    onClick={onSubmit}>
                    <span>Wgraj cennik</span>
                    <MdOutlineCloudUpload size='20px' color='black'/>
                </AddItem>
            </Container>
        </StyledBox>
    </StyledModal>;
}