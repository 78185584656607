/* eslint-disable */
import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
// import {register, reset} from '../../features/auth/authSlice'


import {Link} from "react-router-dom"
// import { device  } from '../../utils/breakpoints'
import {useForm} from "react-hook-form"
import {Controller} from "react-hook-form"

import styled from 'styled-components'

import logo from '../../images/logo-dents-play.png'
import {Button} from '../common/button'
import {Heading} from '../common/heading'
import {device} from '../../utils/breakpoints'
import {FcGoogle} from 'react-icons/fc'
import {FaFacebookF} from 'react-icons/fa'

import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import {authActionCreators} from '../../actions/authActionCreators'
import FacebookLogin from "@greatsumini/react-facebook-login";
import {useGoogleLogin} from "@react-oauth/google";


const ContainerLoginSide = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  gap: 3rem;
  justify-items: start;
  padding: 3rem 2rem;
  max-width: 800px;
  z-index: 10;

  @media ${device.tablet} {
    padding: 2rem 3rem;
  }

  @media ${device.laptop} {
    padding: 1rem 4rem;
    gap: 1rem;
  }

  .css-19hdqbp-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px !important;
  }
`

const ContainerLogo = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  img {
    width: 117px;
  }
`

const ContainerLogin = styled.div`
  width: 100%;
  display: grid;
  align-content: start;
  gap: 3rem;

`

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 2rem;
`

const Form = styled.form`
  display: grid;
  grid-template-rows: auto;
  gap: 1.8rem;

`

const ContainerTextFields = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  gap: 1.8rem;
  position: relative;

`

const ContainerStyledTextField = styled.div`
  display: grid;
  position: relative;


  span {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.lightBlack};
    font-weight: 700;
    font-size: ${({theme}) => theme.fonts.size.xxs};
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-u9osun.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }
`


const ContainerRegulations = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  align-items: center;
  font-size: ${({theme}) => theme.fonts.size.xs};

  /* .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
      color: red;
  } */

  .css-j204z7-MuiFormControlLabel-root {
    font-family: 'Open Sans' !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-family: 'Open Sans' !important;
    font-size: ${({theme}) => theme.fonts.size.xs};
  }

  .css-j204z7-MuiFormControlLabel-root {
    margin-right: 0px;
  }

  span {
    line-height: 1.3;
  }

  div {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5rem;
    justify-content: start;
  }

  .css-8je8zh-MuiTouchRipple-root {
    display: none;
  }

  .css-19hdqbp-MuiButtonBase-root-MuiCheckbox-root:hover {
    background-color: transparent;
  }


`

const StyledLink = styled(Link)`
  font-weight: 400;
  text-decoration: underline;
`


const ContainerButton = styled.div`


`

const StyledButton = styled(Button)`
  width: 100%;
  background: ${({theme}) => theme.colors.red};
  color: ${({theme}) => theme.colors.white};
  border: 1px solid #D22416;
  font-weight: 700;
  font-size: ${({theme}) => theme.fonts.size.s};
`

const ContainerSocialLogins = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
  gap: 2rem;
`

const SocialLogins = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: auto;
  gap: 0.5rem;

  @media ${device.mobileL} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const SocialLogin = styled.button`
  border: none;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 0.5rem;
  justify-content: center;
  cursor: pointer;
  padding: 1rem 1.8rem;
  background: ${({theme}) => theme.colors.gray};
  border-radius: 30px;
  text-align: center;
  border: 1px solid transparent;


  :hover {
    background: rgb(210, 36, 22, 0.04);
    border: 1px solid ${({ theme }) => theme.colors.red};
    border-color: ${({ theme }) => theme.colors.red};
  }

  span {
    font-weight: 700;
  }

`


const RegisterLink = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, auto);
  gap: 0.5rem;
  color: ${({theme}) => theme.colors.red};

  span, a {
    font-weight: 700;
    color: ${({theme}) => theme.colors.red};
  }

  a {
    text-decoration: underline;
  }
`

const ContainerForgetPassword = styled.div `
  display: grid;
  justify-items: end;

  span {
    font-weight: 700;
    cursor: pointer;
    font-size: ${({theme}) => theme.fonts.size.xs};
  }

`

//message

const MessageInput = styled.p`
  position: absolute;
  bottom: -20px;
  font-size: ${({theme}) => theme.fonts.size.xxs};
  color: ${({theme}) => theme.colors.red};
`


const {loginAction, loginFacebookAction, loginGoogleAction} = authActionCreators

export const LoginSide = () => {

  const auth = useSelector(state => state.auth)
  const errorsAuth = auth.errorsLogin

  const {control, handleSubmit, formState: {errors}} = useForm({
    defaultValues: {
      email: '',
      password: '',
    }
  })

  const dispatch = useDispatch()

  const onSubmit = ({email, password}) => {
    dispatch(loginAction(email, password))
  }
  const responseGoogle = response => {
    console.log(response)
    if(response.access_token) {
      dispatch(loginGoogleAction(response.access_token))
    }
  }
  console.log(errorsAuth)
  const loginGoogle = useGoogleLogin({
    onSuccess: responseGoogle,
  });
  return (
    <ContainerLoginSide>
      <ContainerLogo>
        <img src={logo} title="Logo" alt="Logo"/>
      </ContainerLogo>

      <ContainerLogin>
        <Heading h1 as='h1'>Logowanie</Heading>
        <ContainerForm>
          <Form
            onSubmit={handleSubmit(onSubmit)}>
            <ContainerTextFields>
              <ContainerStyledTextField>

                <Controller
                  id="email"
                  name="email"
                  label="E-mail"
                  control={control}
                  text="E-mail"
                  rules={{
                    required: 'To pole jest wymagane.',
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      onChange={onChange}
                      label="E-mail"
                      type='email'

                    />
                  }
                />
                <MessageInput>{errors.email?.message}</MessageInput>
              </ContainerStyledTextField>
              <ContainerStyledTextField>

                <Controller
                  id="password"
                  name="password"
                  label="Wpisz hasło"
                  control={control}
                  text="Hasło"
                  rules={{
                    required: 'To pole jest wymagane.',
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      onChange={onChange}
                      label="Hasło"
                      type="password"

                    />
                  }
                />
                <MessageInput>{errors.password?.message}</MessageInput>
              </ContainerStyledTextField>
              {errorsAuth.default !== undefined && <MessageInput>{errorsAuth.default}</MessageInput>}
            </ContainerTextFields>

            <ContainerButton>
              <StyledButton>Zaloguj się</StyledButton>
            </ContainerButton>
          </Form>

          <ContainerForgetPassword>
              <Link to='/nowe-haslo'>
                <span>Zapomniałeś hasła?</span>
              </Link>
          </ContainerForgetPassword>

          <RegisterLink>
            <span>Nie masz konta?</span>
            <a href="https://app.easycart.pl/checkout/6721340/applikacja?promo=1">Zarejestruj się</a>
          </RegisterLink>
        </ContainerForm>
      </ContainerLogin>
    </ContainerLoginSide>
  )
}

