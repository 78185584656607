import {apiRequest, unexpectedError} from "../client";

const addCostGroup = async (costGroup) => {
  const response = await apiRequest('POST', 'api/costGroups', costGroup)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const updateCostGroup = async (costGroup) => {
  const response = await apiRequest('PATCH', 'api/costGroups', costGroup)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const removeCostGroup = async (id) => {
  const response = await apiRequest('DELETE', 'api/costGroups', {id})

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}

const getCostGroups = async () => {
  const response = await apiRequest('GET', 'api/costGroups')

  if (response.status === 200) {
    const {success, errors, items} = response.data
    if (success) {
      return items
    }
    return errors
  }

  return unexpectedError
}

const costGroupService = {
  getCostGroups,
  updateCostGroup,
  removeCostGroup,
  addCostGroup,
}

export default costGroupService
