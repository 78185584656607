//AUTH ACTIONS
export const LOGIN_FACEBOOK_ACTION_REQUESTED = "LOGIN_FACEBOOK_ACTION_REQUESTED"
export const LOGIN_FACEBOOK_ACTION_BEGIN = "LOGIN_FACEBOOK_ACTION_BEGIN"
export const LOGIN_FACEBOOK_ACTION_SUCCEEDED = "LOGIN_FACEBOOK_ACTION_SUCCEEDED"
export const LOGIN_FACEBOOK_ACTION_FAILED = "LOGIN_FACEBOOK_ACTION_FAILED"
export const LOGIN_GOOGLE_ACTION_REQUESTED = "LOGIN_GOOGLE_ACTION_REQUESTED"
export const LOGIN_GOOGLE_ACTION_BEGIN = "LOGIN_GOOGLE_ACTION_BEGIN"
export const LOGIN_GOOGLE_ACTION_SUCCEEDED = "LOGIN_GOOGLE_ACTION_SUCCEEDED"
export const LOGIN_GOOGLE_ACTION_FAILED = "LOGIN_GOOGLE_ACTION_FAILED"
export const LOGIN_ACTION_REQUESTED = "LOGIN_ACTION_REQUESTED"
export const LOGIN_ACTION_BEGIN = "LOGIN_ACTION_BEGIN"
export const LOGIN_ACTION_SUCCEEDED = "LOGIN_ACTION_SUCCEEDED"
export const LOGIN_ACTION_FAILED = "LOGIN_ACTION_FAILED"
export const REGISTER_ACTION_REQUESTED = "REGISTER_ACTION_REQUESTED"
export const REGISTER_ACTION_BEGIN = "REGISTER_ACTION_BEGIN"
export const REGISTER_ACTION_SUCCEEDED = "REGISTER_ACTION_SUCCEEDED"
export const REGISTER_ACTION_FAILED = "REGISTER_ACTION_FAILED"
export const LOGOUT_ACTION_REQUESTED = "LOGOUT_ACTION_REQUESTED"
export const LOGOUT_ACTION_BEGIN = "LOGOUT_ACTION_BEGIN"
export const LOGOUT_ACTION_SUCCEEDED = "LOGOUT_ACTION_SUCCEEDED"
export const LOGOUT_ACTION_FAILED = "LOGOUT_ACTION_FAILED"
export const LOGIN_INFO_ACTION_REQUESTED = "LOGIN_INFO_ACTION_REQUESTED"
export const LOGIN_INFO_ACTION_BEGIN = "LOGIN_INFO_ACTION_BEGIN"
export const LOGIN_INFO_ACTION_SUCCEEDED = "LOGIN_INFO_ACTION_SUCCEEDED"
export const LOGIN_INFO_ACTION_FAILED = "LOGIN_INFO_ACTION_FAILED"

//COST TYPE ACTIONS
export const COST_TYPE_GET_ACTION_REQUESTED = "COST_TYPE_GET_ACTION_REQUESTED"
export const COST_TYPE_GET_ACTION_BEGIN = "COST_TYPE_GET_ACTION_BEGIN"
export const COST_TYPE_GET_ACTION_SUCCEEDED = "COST_TYPE_GET_ACTION_SUCCEEDED"
export const COST_TYPE_GET_ACTION_FAILED = "COST_TYPE_GET_ACTION_FAILED"
export const COST_TYPE_ADD_ACTION_REQUESTED = "COST_TYPE_ADD_ACTION_REQUESTED"
export const COST_TYPE_ADD_ACTION_BEGIN = "COST_TYPE_ADD_ACTION_BEGIN"
export const COST_TYPE_ADD_ACTION_SUCCEEDED = "COST_TYPE_ADD_ACTION_SUCCEEDED"
export const COST_TYPE_ADD_ACTION_FAILED = "COST_TYPE_ADD_ACTION_FAILED"
export const COST_TYPE_REMOVE_ACTION_REQUESTED = "COST_TYPE_REMOVE_ACTION_REQUESTED"
export const COST_TYPE_REMOVE_ACTION_BEGIN = "COST_TYPE_REMOVE_ACTION_BEGIN"
export const COST_TYPE_REMOVE_ACTION_SUCCEEDED = "COST_TYPE_REMOVE_ACTION_SUCCEEDED"
export const COST_TYPE_REMOVE_ACTION_FAILED = "COST_TYPE_REMOVE_ACTION_FAILED"
export const COST_TYPE_UPDATE_ACTION_REQUESTED = "COST_TYPE_UPDATE_ACTION_REQUESTED"
export const COST_TYPE_UPDATE_ACTION_BEGIN = "COST_TYPE_UPDATE_ACTION_BEGIN"
export const COST_TYPE_UPDATE_ACTION_SUCCEEDED = "COST_TYPE_UPDATE_ACTION_SUCCEEDED"
export const COST_TYPE_UPDATE_ACTION_FAILED = "COST_TYPE_UPDATE_ACTION_FAILED"

export const COST_TYPE_SHOW_ADDING_MODAL_ACTION = "COST_TYPE_SHOW_ADDING_MODAL_ACTION"
export const COST_TYPE_HIDE_ADDING_MODAL_ACTION = "COST_TYPE_HIDE_ADDING_MODAL_ACTION"
export const COST_TYPE_UPDATE_ADDING_MODAL_ACTION = "COST_TYPE_UPDATE_ADDING_MODAL_ACTION"
export const COST_TYPE_SHOW_EDITING_MODAL_ACTION = "COST_TYPE_SHOW_EDITING_MODAL_ACTION"
export const COST_TYPE_HIDE_EDITING_MODAL_ACTION = "COST_TYPE_HIDE_EDITING_MODAL_ACTION"
export const COST_TYPE_UPDATE_EDITING_MODAL_ACTION = "COST_TYPE_UPDATE_EDITING_MODAL_ACTION"
export const COST_TYPE_UPDATE_VALUE_ACTION = "COST_TYPE_UPDATE_VALUE_ACTION"
export const COST_TYPE_SAVE_VALUE_ACTION_REQUESTED = "COST_TYPE_SAVE_VALUE_ACTION_REQUESTED"
export const COST_TYPE_SAVE_VALUE_ACTION_BEGIN = "COST_TYPE_SAVE_VALUE_ACTION_BEGIN"
export const COST_TYPE_SAVE_VALUE_ACTION_SUCCEEDED = "COST_TYPE_SAVE_VALUE_ACTION_SUCCEEDED"
export const COST_TYPE_SAVE_VALUE_ACTION_FAILED = "COST_TYPE_SAVE_VALUE_ACTION_FAILED"
export const COST_TYPE_UPDATE_MONTH_ACTION = "COST_TYPE_UPDATE_MONTH_ACTION"


//DOCTOR ACTIONS
export const DOCTOR_GET_ACTION_REQUESTED = "DOCTOR_GET_ACTION_REQUESTED"
export const DOCTOR_GET_ACTION_BEGIN = "DOCTOR_GET_ACTION_BEGIN"
export const DOCTOR_GET_ACTION_SUCCEEDED = "DOCTOR_GET_ACTION_SUCCEEDED"
export const DOCTOR_GET_ACTION_FAILED = "DOCTOR_GET_ACTION_FAILED"
export const DOCTOR_ADD_ACTION_REQUESTED = "DOCTOR_ADD_ACTION_REQUESTED"
export const DOCTOR_ADD_ACTION_BEGIN = "DOCTOR_ADD_ACTION_BEGIN"
export const DOCTOR_ADD_ACTION_SUCCEEDED = "DOCTOR_ADD_ACTION_SUCCEEDED"
export const DOCTOR_ADD_ACTION_FAILED = "DOCTOR_ADD_ACTION_FAILED"
export const DOCTOR_REMOVE_ACTION_REQUESTED = "DOCTOR_REMOVE_ACTION_REQUESTED"
export const DOCTOR_REMOVE_ACTION_BEGIN = "DOCTOR_REMOVE_ACTION_BEGIN"
export const DOCTOR_REMOVE_ACTION_SUCCEEDED = "DOCTOR_REMOVE_ACTION_SUCCEEDED"
export const DOCTOR_REMOVE_ACTION_FAILED = "DOCTOR_REMOVE_ACTION_FAILED"
export const DOCTOR_UPDATE_ACTION_REQUESTED = "DOCTOR_UPDATE_ACTION_REQUESTED"
export const DOCTOR_UPDATE_ACTION_BEGIN = "DOCTOR_UPDATE_ACTION_BEGIN"
export const DOCTOR_UPDATE_ACTION_SUCCEEDED = "DOCTOR_UPDATE_ACTION_SUCCEEDED"
export const DOCTOR_UPDATE_ACTION_FAILED = "DOCTOR_UPDATE_ACTION_FAILED"

export const DOCTOR_SHOW_ADDING_MODAL_ACTION = "DOCTOR_SHOW_ADDING_MODAL_ACTION"
export const DOCTOR_HIDE_ADDING_MODAL_ACTION = "DOCTOR_HIDE_ADDING_MODAL_ACTION"
export const DOCTOR_UPDATE_ADDING_MODAL_ACTION = "DOCTOR_UPDATE_ADDING_MODAL_ACTION"
export const DOCTOR_SHOW_EDITING_MODAL_ACTION = "DOCTOR_SHOW_EDITING_MODAL_ACTION"
export const DOCTOR_HIDE_EDITING_MODAL_ACTION = "DOCTOR_HIDE_EDITING_MODAL_ACTION"
export const DOCTOR_UPDATE_EDITING_MODAL_ACTION = "DOCTOR_UPDATE_EDITING_MODAL_ACTION"



//BRANCH ACTIONS
export const BRANCH_GET_ACTION_REQUESTED = "BRANCH_GET_ACTION_REQUESTED"
export const BRANCH_GET_ACTION_BEGIN = "BRANCH_GET_ACTION_BEGIN"
export const BRANCH_GET_ACTION_SUCCEEDED = "BRANCH_GET_ACTION_SUCCEEDED"
export const BRANCH_GET_ACTION_FAILED = "BRANCH_GET_ACTION_FAILED"
export const BRANCH_ADD_ACTION_REQUESTED = "BRANCH_ADD_ACTION_REQUESTED"
export const BRANCH_ADD_ACTION_BEGIN = "BRANCH_ADD_ACTION_BEGIN"
export const BRANCH_ADD_ACTION_SUCCEEDED = "BRANCH_ADD_ACTION_SUCCEEDED"
export const BRANCH_ADD_ACTION_FAILED = "BRANCH_ADD_ACTION_FAILED"
export const BRANCH_REMOVE_ACTION_REQUESTED = "BRANCH_REMOVE_ACTION_REQUESTED"
export const BRANCH_REMOVE_ACTION_BEGIN = "BRANCH_REMOVE_ACTION_BEGIN"
export const BRANCH_REMOVE_ACTION_SUCCEEDED = "BRANCH_REMOVE_ACTION_SUCCEEDED"
export const BRANCH_REMOVE_ACTION_FAILED = "BRANCH_REMOVE_ACTION_FAILED"
export const BRANCH_UPDATE_ACTION_REQUESTED = "BRANCH_UPDATE_ACTION_REQUESTED"
export const BRANCH_UPDATE_ACTION_BEGIN = "BRANCH_UPDATE_ACTION_BEGIN"
export const BRANCH_UPDATE_ACTION_SUCCEEDED = "BRANCH_UPDATE_ACTION_SUCCEEDED"
export const BRANCH_UPDATE_ACTION_FAILED = "BRANCH_UPDATE_ACTION_FAILED"

export const BRANCH_SHOW_ADDING_MODAL_ACTION = "BRANCH_SHOW_ADDING_MODAL_ACTION"
export const BRANCH_HIDE_ADDING_MODAL_ACTION = "BRANCH_HIDE_ADDING_MODAL_ACTION"
export const BRANCH_UPDATE_ADDING_MODAL_ACTION = "BRANCH_UPDATE_ADDING_MODAL_ACTION"
export const BRANCH_SHOW_EDITING_MODAL_ACTION = "BRANCH_SHOW_EDITING_MODAL_ACTION"
export const BRANCH_HIDE_EDITING_MODAL_ACTION = "BRANCH_HIDE_EDITING_MODAL_ACTION"
export const BRANCH_UPDATE_EDITING_MODAL_ACTION = "BRANCH_UPDATE_EDITING_MODAL_ACTION"





//POSITION ACTIONS
export const POSITION_GET_ACTION_REQUESTED = "POSITION_GET_ACTION_REQUESTED"
export const POSITION_GET_ACTION_BEGIN = "POSITION_GET_ACTION_BEGIN"
export const POSITION_GET_ACTION_SUCCEEDED = "POSITION_GET_ACTION_SUCCEEDED"
export const POSITION_GET_ACTION_FAILED = "POSITION_GET_ACTION_FAILED"
export const POSITION_ADD_ACTION_REQUESTED = "POSITION_ADD_ACTION_REQUESTED"
export const POSITION_ADD_ACTION_BEGIN = "POSITION_ADD_ACTION_BEGIN"
export const POSITION_ADD_ACTION_SUCCEEDED = "POSITION_ADD_ACTION_SUCCEEDED"
export const POSITION_ADD_ACTION_FAILED = "POSITION_ADD_ACTION_FAILED"
export const POSITION_REMOVE_ACTION_REQUESTED = "POSITION_REMOVE_ACTION_REQUESTED"
export const POSITION_REMOVE_ACTION_BEGIN = "POSITION_REMOVE_ACTION_BEGIN"
export const POSITION_REMOVE_ACTION_SUCCEEDED = "POSITION_REMOVE_ACTION_SUCCEEDED"
export const POSITION_REMOVE_ACTION_FAILED = "POSITION_REMOVE_ACTION_FAILED"
export const POSITION_UPDATE_ACTION_REQUESTED = "POSITION_UPDATE_ACTION_REQUESTED"
export const POSITION_UPDATE_ACTION_BEGIN = "POSITION_UPDATE_ACTION_BEGIN"
export const POSITION_UPDATE_ACTION_SUCCEEDED = "POSITION_UPDATE_ACTION_SUCCEEDED"
export const POSITION_UPDATE_ACTION_FAILED = "POSITION_UPDATE_ACTION_FAILED"

export const POSITION_SHOW_ADDING_MODAL_ACTION = "POSITION_SHOW_ADDING_MODAL_ACTION"
export const POSITION_HIDE_ADDING_MODAL_ACTION = "POSITION_HIDE_ADDING_MODAL_ACTION"
export const POSITION_UPDATE_ADDING_MODAL_ACTION = "POSITION_UPDATE_ADDING_MODAL_ACTION"
export const POSITION_SHOW_EDITING_MODAL_ACTION = "POSITION_SHOW_EDITING_MODAL_ACTION"
export const POSITION_HIDE_EDITING_MODAL_ACTION = "POSITION_HIDE_EDITING_MODAL_ACTION"
export const POSITION_UPDATE_EDITING_MODAL_ACTION = "POSITION_UPDATE_EDITING_MODAL_ACTION"


//COST GROUP ACTIONS
export const COST_GROUP_GET_ACTION_REQUESTED = "COST_GROUP_GET_ACTION_REQUESTED"
export const COST_GROUP_GET_ACTION_BEGIN = "COST_GROUP_GET_ACTION_BEGIN"
export const COST_GROUP_GET_ACTION_SUCCEEDED = "COST_GROUP_GET_ACTION_SUCCEEDED"
export const COST_GROUP_GET_ACTION_FAILED = "COST_GROUP_GET_ACTION_FAILED"
export const COST_GROUP_ADD_ACTION_REQUESTED = "COST_GROUP_ADD_ACTION_REQUESTED"
export const COST_GROUP_ADD_ACTION_BEGIN = "COST_GROUP_ADD_ACTION_BEGIN"
export const COST_GROUP_ADD_ACTION_SUCCEEDED = "COST_GROUP_ADD_ACTION_SUCCEEDED"
export const COST_GROUP_ADD_ACTION_FAILED = "COST_GROUP_ADD_ACTION_FAILED"
export const COST_GROUP_REMOVE_ACTION_REQUESTED = "COST_GROUP_REMOVE_ACTION_REQUESTED"
export const COST_GROUP_REMOVE_ACTION_BEGIN = "COST_GROUP_REMOVE_ACTION_BEGIN"
export const COST_GROUP_REMOVE_ACTION_SUCCEEDED = "COST_GROUP_REMOVE_ACTION_SUCCEEDED"
export const COST_GROUP_REMOVE_ACTION_FAILED = "COST_GROUP_REMOVE_ACTION_FAILED"
export const COST_GROUP_UPDATE_ACTION_REQUESTED = "COST_GROUP_UPDATE_ACTION_REQUESTED"
export const COST_GROUP_UPDATE_ACTION_BEGIN = "COST_GROUP_UPDATE_ACTION_BEGIN"
export const COST_GROUP_UPDATE_ACTION_SUCCEEDED = "COST_GROUP_UPDATE_ACTION_SUCCEEDED"
export const COST_GROUP_UPDATE_ACTION_FAILED = "COST_GROUP_UPDATE_ACTION_FAILED"

export const COST_GROUP_SHOW_ADDING_MODAL_ACTION = "COST_GROUP_SHOW_ADDING_MODAL_ACTION"
export const COST_GROUP_HIDE_ADDING_MODAL_ACTION = "COST_GROUP_HIDE_ADDING_MODAL_ACTION"
export const COST_GROUP_UPDATE_ADDING_MODAL_ACTION = "COST_GROUP_UPDATE_ADDING_MODAL_ACTION"
export const COST_GROUP_SHOW_EDITING_MODAL_ACTION = "COST_GROUP_SHOW_EDITING_MODAL_ACTION"
export const COST_GROUP_HIDE_EDITING_MODAL_ACTION = "COST_GROUP_HIDE_EDITING_MODAL_ACTION"
export const COST_GROUP_UPDATE_EDITING_MODAL_ACTION = "COST_GROUP_UPDATE_EDITING_MODAL_ACTION"

//SETTINGS ACTIONS
export const SETTINGS_CHANGE_PASSWORD_ACTION_REQUESTED = "SETTINGS_CHANGE_PASSWORD_ACTION_REQUESTED"
export const SETTINGS_CHANGE_PASSWORD_ACTION_BEGIN = "SETTINGS_CHANGE_PASSWORD_ACTION_BEGIN"
export const SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED = "SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED"
export const SETTINGS_CHANGE_PASSWORD_ACTION_FAILED = "SETTINGS_CHANGE_PASSWORD_ACTION_FAILED"
export const SETTINGS_CHANGE_EMAIL_ACTION_REQUESTED = "SETTINGS_CHANGE_EMAIL_ACTION_REQUESTED"
export const SETTINGS_CHANGE_EMAIL_ACTION_BEGIN = "SETTINGS_CHANGE_EMAIL_ACTION_BEGIN"
export const SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED = "SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED"
export const SETTINGS_CHANGE_EMAIL_ACTION_FAILED = "SETTINGS_CHANGE_EMAIL_ACTION_FAILED"
export const SETTINGS_GET_ACTION_REQUESTED = "SETTINGS_GET_ACTION_REQUESTED"
export const SETTINGS_GET_ACTION_BEGIN = "SETTINGS_GET_ACTION_BEGIN"
export const SETTINGS_GET_ACTION_SUCCEEDED = "SETTINGS_GET_ACTION_SUCCEEDED"
export const SETTINGS_GET_ACTION_FAILED = "SETTINGS_GET_ACTION_FAILED"

export const SETTINGS_SET_CHANGE_PASSWORD_DATA_ACTION = "SETTINGS_SET_CHANGE_PASSWORD_DATA_ACTION"
export const SETTINGS_SET_CHANGE_EMAIL_DATA_ACTION = "SETTINGS_SET_CHANGE_EMAIL_DATA_ACTION"

//MONTH STAT ACTIONS
export const MONTH_STAT_GET_ACTION_REQUESTED = "MONTH_STAT_GET_ACTION_REQUESTED"
export const MONTH_STAT_GET_ACTION_BEGIN = "MONTH_STAT_GET_ACTION_BEGIN"
export const MONTH_STAT_GET_ACTION_SUCCEEDED = "MONTH_STAT_GET_ACTION_SUCCEEDED"
export const MONTH_STAT_GET_ACTION_FAILED = "MONTH_STAT_GET_ACTION_FAILED"
export const MONTH_STAT_ADD_ACTION_REQUESTED = "MONTH_STAT_ADD_ACTION_REQUESTED"
export const MONTH_STAT_ADD_ACTION_BEGIN = "MONTH_STAT_ADD_ACTION_BEGIN"
export const MONTH_STAT_ADD_ACTION_SUCCEEDED = "MONTH_STAT_ADD_ACTION_SUCCEEDED"
export const MONTH_STAT_ADD_ACTION_FAILED = "MONTH_STAT_ADD_ACTION_FAILED"
export const MONTH_STAT_REMOVE_ACTION_REQUESTED = "MONTH_STAT_REMOVE_ACTION_REQUESTED"
export const MONTH_STAT_REMOVE_ACTION_BEGIN = "MONTH_STAT_REMOVE_ACTION_BEGIN"
export const MONTH_STAT_REMOVE_ACTION_SUCCEEDED = "MONTH_STAT_REMOVE_ACTION_SUCCEEDED"
export const MONTH_STAT_REMOVE_ACTION_FAILED = "MONTH_STAT_REMOVE_ACTION_FAILED"
export const MONTH_STAT_UPDATE_ACTION_REQUESTED = "MONTH_STAT_UPDATE_ACTION_REQUESTED"
export const MONTH_STAT_UPDATE_ACTION_BEGIN = "MONTH_STAT_UPDATE_ACTION_BEGIN"
export const MONTH_STAT_UPDATE_ACTION_SUCCEEDED = "MONTH_STAT_UPDATE_ACTION_SUCCEEDED"
export const MONTH_STAT_UPDATE_ACTION_FAILED = "MONTH_STAT_UPDATE_ACTION_FAILED"

export const MONTH_STAT_SET_DATA_ACTION = "MONTH_STAT_SET_DATA_ACTION"

//SERVICE ACTIONS
export const SERVICE_GET_ACTION_REQUESTED = "SERVICE_GET_ACTION_REQUESTED"
export const SERVICE_GET_ACTION_BEGIN = "SERVICE_GET_ACTION_BEGIN"
export const SERVICE_GET_ACTION_SUCCEEDED = "SERVICE_GET_ACTION_SUCCEEDED"
export const SERVICE_GET_ACTION_FAILED = "SERVICE_GET_ACTION_FAILED"
export const SERVICE_ADD_ACTION_REQUESTED = "SERVICE_ADD_ACTION_REQUESTED"
export const SERVICE_UPLOAD_ACTION_REQUESTED = "SERVICE_UPLOAD_ACTION_REQUESTED"
export const SERVICE_ADD_ACTION_BEGIN = "SERVICE_ADD_ACTION_BEGIN"
export const SERVICE_ADD_ACTION_SUCCEEDED = "SERVICE_ADD_ACTION_SUCCEEDED"
export const SERVICE_ADD_ACTION_FAILED = "SERVICE_ADD_ACTION_FAILED"
export const SERVICE_UPLOAD_ACTION_BEGIN = "SERVICE_UPLOAD_ACTION_BEGIN"
export const SERVICE_UPLOAD_ACTION_SUCCEEDED = "SERVICE_UPLOAD_ACTION_SUCCEEDED"
export const SERVICE_UPLOAD_ACTION_FAILED = "SERVICE_UPLOAD_ACTION_FAILED"
export const SERVICE_REMOVE_ACTION_REQUESTED = "SERVICE_REMOVE_ACTION_REQUESTED"
export const SERVICE_REMOVE_ACTION_BEGIN = "SERVICE_REMOVE_ACTION_BEGIN"
export const SERVICE_REMOVE_ACTION_SUCCEEDED = "SERVICE_REMOVE_ACTION_SUCCEEDED"
export const SERVICE_REMOVE_ACTION_FAILED = "SERVICE_REMOVE_ACTION_FAILED"
export const SERVICE_UPDATE_ACTION_REQUESTED = "SERVICE_UPDATE_ACTION_REQUESTED"
export const SERVICE_UPDATE_ACTION_BEGIN = "SERVICE_UPDATE_ACTION_BEGIN"
export const SERVICE_UPDATE_ACTION_SUCCEEDED = "SERVICE_UPDATE_ACTION_SUCCEEDED"
export const SERVICE_UPDATE_ACTION_FAILED = "SERVICE_UPDATE_ACTION_FAILED"

export const SERVICE_SHOW_ADDING_MODAL_ACTION = "SERVICE_SHOW_ADDING_MODAL_ACTION"
export const SERVICE_SHOW_UPLOADING_MODAL_ACTION = "SERVICE_SHOW_UPLOADING_MODAL_ACTION"
export const SERVICE_HIDE_ADDING_MODAL_ACTION = "SERVICE_HIDE_ADDING_MODAL_ACTION"
export const SERVICE_HIDE_UPLOADING_MODAL_ACTION = "SERVICE_HIDE_UPLOADING_MODAL_ACTION"
export const SERVICE_UPDATE_ADDING_MODAL_ACTION = "SERVICE_UPDATE_ADDING_MODAL_ACTION"
export const SERVICE_UPDATE_UPLOADING_MODAL_ACTION = "SERVICE_UPDATE_UPLOADING_MODAL_ACTION"
export const SERVICE_SHOW_EDITING_MODAL_ACTION = "SERVICE_SHOW_EDITING_MODAL_ACTION"
export const SERVICE_HIDE_EDITING_MODAL_ACTION = "SERVICE_HIDE_EDITING_MODAL_ACTION"
export const SERVICE_UPDATE_EDITING_MODAL_ACTION = "SERVICE_UPDATE_EDITING_MODAL_ACTION"
export const SERVICE_UPDATE_VALUE_ACTION = "SERVICE_UPDATE_VALUE_ACTION"
export const SERVICE_SAVE_VALUE_ACTION_REQUESTED = "SERVICE_SAVE_VALUE_ACTION_REQUESTED"
export const SERVICE_SAVE_VALUE_ACTION_BEGIN = "SERVICE_SAVE_VALUE_ACTION_BEGIN"
export const SERVICE_SAVE_VALUE_ACTION_SUCCEEDED = "SERVICE_SAVE_VALUE_ACTION_SUCCEEDED"
export const SERVICE_SAVE_VALUE_ACTION_FAILED = "SERVICE_SAVE_VALUE_ACTION_FAILED"

//Forget password
export const FORGET_PASSWORD_ACTION_REQUESTED = "FORGET_PASSWORD_ACTION_REQUESTED"
export const FORGET_PASSWORD_ACTION_BEGIN = "FORGET_PASSWORD_ACTION_BEGIN"
export const FORGET_PASSWORD_ACTION_SUCCEEDED = "FORGET_PASSWORD_ACTION_SUCCEEDED"
export const FORGET_PASSWORD_ACTION_FAILED = "FORGET_PASSWORD_ACTION_FAILED"


//Reset password
export const RESET_PASSWORD_ACTION_REQUESTED = "RESET_PASSWORD_ACTION_REQUESTED"
export const RESET_PASSWORD_ACTION_BEGIN = "RESET_PASSWORD_ACTION_BEGIN"
export const RESET_PASSWORD_ACTION_SUCCEEDED = "RESET_PASSWORD_ACTION_SUCCEEDED"
export const RESET_PASSWORD_ACTION_FAILED = "RESET_PASSWORD_ACTION_FAILED"
