import {apiRequest, unexpectedError} from "../client";

const addDoctor = async (doctor) => {
  const response = await apiRequest('POST', 'api/doctors', doctor)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const updateDoctor = async (doctor) => {
  const response = await apiRequest('PATCH', 'api/doctors', doctor)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const removeDoctor = async (id) => {
  const response = await apiRequest('DELETE', 'api/doctors', {id})

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}

const getDoctors = async () => {
  const response = await apiRequest('GET', 'api/doctors')

  if (response.status === 200) {
    const {success, errors, items} = response.data
    if (success) {
      return items
    }
    return errors
  }

  return unexpectedError
}

const doctorService = {
  getDoctors,
  updateDoctor,
  removeDoctor,
  addDoctor,
}

export default doctorService
