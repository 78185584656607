import React from 'react'
import {BsChevronDown, BsChevronUp} from "react-icons/bs"

import {AiOutlineCalculator, AiOutlineLineChart, AiOutlineTeam, AiOutlineSetting} from 'react-icons/ai'
import {RiOrganizationChart} from 'react-icons/ri'



export const SidebarLinks = [
  // {
  //   id: 1,
  //   url: '/',
  //   text: 'Strona główna',
  //   icon: <RiHomeLine color={'#000000'} size={20}/>,
  // },
  {
    id: 2,
    text: 'Finanse gabinetu',
    iconClosed: <BsChevronDown color={'#6D6D6D'} size={14}/>,
    iconOpened: <BsChevronUp color={'#6D6D6D'} size={14}/>,
    icon: <AiOutlineLineChart color={'#000000'} size={20}/>,
    subNav: [
      {
        url2: '/koszty',
        text: 'Koszty',

      },
      {
        url2: '/grupy-kosztow',
        text: 'Grupy kosztów',

      },
       {
        url2: '/godziny',
        text: 'Godziny',

      },
      {

        url2: '/cennik',
        text: 'Cennik',

      },
    ]
  },

  {
    id: 3,
    text: 'Organizacja',
    iconClosed: <BsChevronDown color={'#6D6D6D'} size={14}/>,
    iconOpened: <BsChevronUp color={'#6D6D6D'} size={14}/>,
    icon: <RiOrganizationChart color={'#000000'} size={20}/>,
    subNav: [
      {

        url2: '/stanowiska',
        text: 'Stanowiska / unity',

      },
      {

        url2: '/dziedziny',
        text: 'Dziedziny',

      },
    ]
  },

  {
    id: 4,
    url: '/zespol',
    text: 'Zespół',
    icon: <AiOutlineTeam color={'#000000'} size={20}/>,
  },
  {
    id: 5,
    text: 'Kalkulator',
    url: '/kalkulator',
    icon: <AiOutlineCalculator color={'#000000'} size={20}/>,
  },
  {
    id: 6,
    text: 'Ustawienia',
    url: '/ustawienia',
    icon: <AiOutlineSetting color={'#000000'} size={20}/>,
  },
]


