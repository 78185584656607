import React from 'react'

/* eslint-disable */

import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import { Heading } from '../common/heading'
import { Line } from '../common/line'
import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Sidebar } from '../common/sidebar'
import { Costs } from './costss'

import { MobileMenu } from '../common/mobileMenu'




const StyledWrapper = styled(Wrapper) `
    width: 100%;
    background: ${({ theme }) => theme.colors.lightGray};

    @media ${device.laptop} {
        padding: 0.7rem;
    }
`

const StyledContainer = styled(Container) `
    
    display: grid;
    grid-template-columns: auto;
    gap: 0.7rem;
    position: relative;

    @media ${device.laptop} {
        grid-template-columns: 350px auto;
    }
`

const ContainerRightSide = styled.div `
    @media ${device.laptop} {
        display: grid;
        grid-column: 2;
        align-content: start;
    }
`


const CostsPage = () => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <MobileMenu/>
                <Sidebar/>
                <ContainerRightSide>
                   <Costs/>
                </ContainerRightSide>
            </StyledContainer>
        </StyledWrapper>
    )
}

export default CostsPage