import {
    POSITION_GET_ACTION_REQUESTED,
    POSITION_ADD_ACTION_REQUESTED,
    POSITION_REMOVE_ACTION_REQUESTED,
    POSITION_UPDATE_ACTION_REQUESTED,
    POSITION_SHOW_ADDING_MODAL_ACTION,
    POSITION_HIDE_ADDING_MODAL_ACTION,
    POSITION_UPDATE_ADDING_MODAL_ACTION,
    POSITION_SHOW_EDITING_MODAL_ACTION,
    POSITION_HIDE_EDITING_MODAL_ACTION,
    POSITION_UPDATE_EDITING_MODAL_ACTION
} from "../constants/actions";


function getPositionsAction() {
    return {
        type: POSITION_GET_ACTION_REQUESTED,
    }
}


function addPositionAction(name) {
    return {
        type: POSITION_ADD_ACTION_REQUESTED,
        payload: {name}
    }
}

function removePositionAction(id) {
    return {
        type: POSITION_REMOVE_ACTION_REQUESTED,
        payload: id
    }
}

function updatePositionAction(position) {
    return {
        type: POSITION_UPDATE_ACTION_REQUESTED,
        payload: position
    }
}

function showAddingPositionModalAction() {
    return {
        type:  POSITION_SHOW_ADDING_MODAL_ACTION,
    }
}

function hideAddingPositionModalAction() {
    return {
        type:  POSITION_HIDE_ADDING_MODAL_ACTION
    }
}

function updateAddingPositionModalAction(data) {
    return {
        type:  POSITION_UPDATE_ADDING_MODAL_ACTION,
        payload: data,
    }
}

function showEditingPositionModalAction(position) {
    return {
        type:  POSITION_SHOW_EDITING_MODAL_ACTION,
        payload: position,
    }
}

function hideEditingPositionModalAction() {
    return {
        type:  POSITION_HIDE_EDITING_MODAL_ACTION
    }
}

function updateEditingPositionModalAction(data) {
    return {
        type:  POSITION_UPDATE_EDITING_MODAL_ACTION,
        payload: data,
    }
}



export const positionActionCreators = {
    getPositionsAction,
    addPositionAction,
    removePositionAction,
    updatePositionAction,
    showAddingPositionModalAction,
    hideAddingPositionModalAction,
    updateAddingPositionModalAction,
    showEditingPositionModalAction,
    hideEditingPositionModalAction,
    updateEditingPositionModalAction
}
