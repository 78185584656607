import React from 'react'
import styled from 'styled-components'

import image from '../../images/gabinet-stomatologiczny.jpg'
import {device} from '../../utils/breakpoints'

import {Wrapper} from '../common/wrapper'
import {Container} from '../common/container'

import { Loader } from '../common/loader'

import {useSelector} from "react-redux";


import {ResetForgetPasswordSide} from './reset-forget-password-side'


const StyledWrapper = styled(Wrapper)`
  min-height: 100vh;
  height: 100%;
  width: 100%;

`
const StyledContainer = styled(Container)`
  background: ${({theme}) => theme.colors.white};
  display: grid;
  justify-items: center;
  grid-template-columns: auto;
  width: 100%;
  height: 100%;
  position: relative;

  .image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @media ${device.laptop} {
      position: relative;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  :after {
    content: '';
    position: absolute;
    background: rgba(255, 255, 255, 0.97);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @media ${device.laptop} {
      background: transparent;
    }
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 2fr;
  }
`




const ResetPasswordPage = () => {

  // let navigate = useNavigate();
  const {busy} = useSelector(state => state.auth)


    return (
        <StyledWrapper>
        {busy && <Loader/>}
        <StyledContainer>
          <ResetForgetPasswordSide/>
          <img className='image' src={image} title="Gabinet stomatologiczny" alt="Gabinet stomatologiczny"/>
        </StyledContainer>
        </StyledWrapper>
    )
}


export default ResetPasswordPage
