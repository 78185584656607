/* eslint-disable */
import React from 'react'
import styled from 'styled-components'

import {device} from '../../../utils/breakpoints'
import {Controller} from "react-hook-form"
import TextField from '@mui/material/TextField'
import {Heading} from '../../common/heading'
import {ContainerInput} from '../../common/containerInput'
import {Button} from '../../common/button'
import {useReduxForm} from "../../../utils/useReduxForm";
import {useDispatch, useSelector} from "react-redux";
import {initialState} from "../../../reducers/settingsReducer";
import {settingsActionCreators} from "../../../actions/settingsActionCreators";
import LoaderButton from "../../common/loaderButton";

const Container = styled.div`
  width: 100%;

`

const ContainerChangePassword = styled.div`
  width: 100%;
  display: grid;
  align-items: start;
  gap: 3rem;

`

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 2rem;
  align-items: center;
`

const Form = styled.form`
  display: grid;
  grid-template-columns: auto;
  gap: 1.8rem;
  align-items: center;

`

const ContainerTextFields = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  gap: 1.8rem;
  position: relative;

  @media ${device.tablet} {
    grid-template-columns: repeat(3, auto);
  }

`


const MessageInput = styled.p`
  position: absolute;
  bottom: -20px;
  font-size: ${({theme}) => theme.fonts.size.xxs};
  color: ${({theme}) => theme.colors.red};
`


const ContainerButton = styled.div`
  display: grid;
  align-content: center;
  justify-content: flex-start;

`


const {changePasswordAction, setChangePasswordData} = settingsActionCreators
export const ChangePassword = () => {

  const dispatch = useDispatch()
  const changePasswordData = useSelector(state => state.settings.dataChangingPassword)
  const changePasswordErrors = useSelector(state => state.settings.errorsChangingPassword)
  const busyChangingPassword = useSelector(state => state.settings.busyChangingPassword)
  const {
    useFormApi,
  } = useReduxForm(changePasswordData, initialState.dataChangingPassword, data => dispatch(setChangePasswordData(data)))
  const {control, formState: {errors}, setValue, handleSubmit, watch} = useFormApi

  const onSubmit = data => {
    console.log('submitted', data)
    dispatch(changePasswordAction(data.current_password, data.plainPassword1, data.plainPassword2))
  }
  const onErrors = errors => {
    console.log('errors', errors)
  }
  return (
    <Container>
      <ContainerChangePassword>
        <ContainerForm>
          <Heading h2 as='h2'>Zmiana Hasła</Heading>
          <Form
            onSubmit={handleSubmit(onSubmit, onErrors)}
          >
            <ContainerTextFields>
              <ContainerInput>
                <span>Wpisz aktualne hasło</span>

                <Controller
                  id="password"
                  name="current_password"
                  label="E-mail"
                  control={control}
                  text="password"
                  rules={{
                    required: 'To pole jest wymagane.',
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      autoComplete="section-changePassword current-password"
                      onChange={onChange}
                      label="Wpisz hasło"
                      type='password'
                    />
                  }

                />
                <MessageInput>{errors.current_password?.message ?? changePasswordErrors.current_password}</MessageInput>
              </ContainerInput>
              <ContainerInput>
                <span>Wpisz nowe hasło</span>

                <Controller
                  id="newPassword"
                  name="plainPassword1"
                  control={control}
                  text="password"
                  rules={{
                    required: 'To pole jest wymagane.',
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      autoComplete="section-changePassword new-password"
                      onChange={onChange}
                      label="Wpisz hasło"
                      type='password'
                    />
                  }
                />
                <MessageInput>{errors.plainPassword1?.message ?? changePasswordErrors.plainPassword}</MessageInput>
              </ContainerInput>
              <ContainerInput>
                <span>Powtórz nowe hasło</span>

                <Controller
                  id="newPassword2"
                  name="plainPassword2"
                  control={control}
                  text="password"
                  rules={{
                    required: 'To pole jest wymagane.',
                    validate: (value) => {
                      if (watch('plainPassword1') !== value) {
                        return "Twoje hasła się nie zgadzają."
                      }
                    },
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      autoComplete="section-changePassword new-password"
                      onChange={onChange}
                      label="Powtórz hasło"
                      type='password'
                    />
                  }
                />
                <MessageInput>{errors.plainPassword2?.message}</MessageInput>
              </ContainerInput>

            </ContainerTextFields>
            <ContainerButton>
              <LoaderButton busy={busyChangingPassword}>Zmień hasło</LoaderButton>
            </ContainerButton>
          </Form>
        </ContainerForm>
      </ContainerChangePassword>
    </Container>
  )
}
