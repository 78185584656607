/* eslint-disable */
import React from 'react'
import styled from 'styled-components'
import {Wrapper} from '../common/wrapper'
import {useDispatch, useSelector} from 'react-redux'
import {Heading} from '../common/heading'
import {BsPen, BsTrash} from "react-icons/bs"
import {MdOutlineAddCircleOutline} from "react-icons/md"
import CircularProgress from '@mui/material/CircularProgress'

import {device} from '../../utils/breakpoints'

import {RiArrowLeftSLine, RiArrowRightSLine} from 'react-icons/ri'
import {AiOutlineCheck, AiOutlineSave} from 'react-icons/ai'
import {costTypeActionCreators} from "../../actions/costTypeActionCreators";
import {ASSIGNED_COST_TYPE, UNASSIGNED_COST_TYPE} from "../../constants/costTypeDict"
import TextField from '@mui/material/TextField'

//Tooltip
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import InputAdornment from "@mui/material/InputAdornment"

import {SectionLoader} from '../common/sectionLoader'

const {
  removeCostTypeAction,
  updateCostTypeAction,
  showAddingCostModalAction,
  showEditingCostModalAction,
  saveCostTypeValueAction
} = costTypeActionCreators


const StyledWrapper = styled(Wrapper)`
  grid-template-rows: auto;
  align-content: start;
  gap: 2rem;

  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    min-width: 160px;
  }


  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    font-size: 15px !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
    min-width: 100px;
  }

  `


const ContainerGroupCosts = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
`

const ContainerCosts = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 1rem;
  position: relative;
  min-height: 100px;
`



const StyledTextField = styled(TextField)`


`


const ContainerCostItem = styled.div`
  display: grid;
  grid-template-columns: auto;
  background: ${({theme}) => theme.colors.white};
  gap: 1rem;
  align-items: center;

  @media ${device.tablet} {
    grid-template-columns: 3fr 1fr;
  }
`

const ContainerInformationCostItem = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: auto;
  background: ${({theme}) => theme.colors.lightGray};
  padding: 1rem;
  border-radius: 4px;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
`

const LeftSideInformationCostItem = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
    line-height: 1.3;
  }

`

const RightSideInformationCostItem = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 0.5rem;
  justify-content: end;


  @media ${device.tablet} {

  }

  .css-r47a1p-MuiFormControl-root {
    margin: 0px !important;
  }

  span {
    cursor: pointer;
    padding: 0.3rem;
  }
`


const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  width: 200px;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
`

const ContainerRightSideInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 0.5rem;


`


const SaveButton = styled.div`
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;


`

export const AssignedCosts = ({
                                type,
                                title,
                                costTypes,
                                month,
                                costTypeValues,
                                editedCostTypeValues,
                                busyCostTypeValues,
                                errorsCostTypeValues,
                                changeCostTypeValue
                              }) => {

  const dispatch = useDispatch()


  const {
    busy
  } = useSelector(
    (state) => state.costType
  )

  const handleOpen = () => dispatch(showAddingCostModalAction(type))

  return (
    <>
      <StyledWrapper>
        <Heading h2 as='h2'>{title}</Heading>
        <AddItem onClick={handleOpen}>
          <MdOutlineAddCircleOutline
            color='#000'
            size='20px'
          />
          <span>Dodaj element</span>
        </AddItem>
        <ContainerGroupCosts>
          <ContainerCosts>
          {(busy) && <SectionLoader/>}
            {costTypes.filter(costType => costType.type === type).map((costType) => {
              const edited = editedCostTypeValues.includes(costType.id)
              const busy = busyCostTypeValues.includes(costType.id)
              const errors = errorsCostTypeValues[costType.id]
              return (
                <ContainerCostItem key={costType.id}>
                  <ContainerInformationCostItem>
                    <LeftSideInformationCostItem>
                      <span>{costType.doctor ? 'Prowizje: ' : (costType.costGroup && (costType.costGroup.name + ': '))}{costType.name}</span>
                    </LeftSideInformationCostItem>
                    <RightSideInformationCostItem>
                      {costType.user && !costType.doctor && <Tooltip title="Edytuj koszt">
                        <IconButton
                          onClick={() => {
                            dispatch(showEditingCostModalAction(costType))
                          }}
                        >
                          <BsPen color='#000' size='17px'/>
                        </IconButton>
                      </Tooltip>}
                      {costType.user&& !costType.doctor && <Tooltip title="Usuń koszt">
                        <IconButton
                          onClick={() => {
                            dispatch(removeCostTypeAction(costType.id))
                          }}
                        >
                          <BsTrash
                            color='#000'
                            size='17px'
                          />
                        </IconButton>
                      </Tooltip>}

                      {costType.user && !costType.doctor &&
                        (costType.type === ASSIGNED_COST_TYPE ?
                            <Tooltip title="Przenieś do kosztów stałych">
                              <IconButton
                                onClick={() => {
                                  let newCostType = costType
                                  newCostType.type = UNASSIGNED_COST_TYPE
                                  dispatch(updateCostTypeAction(newCostType))
                                }}
                              >
                                <RiArrowLeftSLine color='#000' size='22px'/>
                              </IconButton>
                            </Tooltip> :
                            <Tooltip title="Przenieś do kosztów zmiennych">
                              <IconButton
                                onClick={() => {
                                  let newCostType = costType
                                  newCostType.type = ASSIGNED_COST_TYPE
                                  dispatch(updateCostTypeAction(newCostType))
                                }}
                              >
                                <RiArrowRightSLine color='#000' size='22px'/>
                              </IconButton>
                            </Tooltip>
                        )}
                    </RightSideInformationCostItem>
                  </ContainerInformationCostItem>
                  <StyledTextField label="Kwota"
                                   disabled={busy}
                                   value={costTypeValues[costType.id] !== undefined ? costTypeValues[costType.id] : ''}
                                   onWheel={(e) => e.target.blur()}
                                   onChange={e => {
                                     const val = (e.target.value >= 0 || e.target.value === '') ? e.target.value : '0'
                                     changeCostTypeValue(costType.id, val)
                                   }}
                                   type="number"
                                   min="0"
                                   helperText={errors}
                                   InputProps={{
                                     endAdornment:
                                       <ContainerRightSideInput>
                                         <InputAdornment position="end">PLN</InputAdornment>
                                         {edited ? (busy ? <CircularProgress size={22}/> :
                                             <SaveButton onClick={() => {
                                               dispatch(saveCostTypeValueAction(costType, month, costTypeValues[costType.id] ? costTypeValues[costType.id] : ''))
                                             }}>
                                               <AiOutlineCheck color='#469546' size='22px'/>
                                             </SaveButton> )
                                             :

                                             <SaveButton>
                                               <AiOutlineCheck color='#F0F0F0' size='22px'/>
                                             </SaveButton>

                                         }
                                       </ContainerRightSideInput>
                                   }}/>
                </ContainerCostItem>
              )
            })}
          </ContainerCosts>


        </ContainerGroupCosts>


      </StyledWrapper>
    </>
  )
}



