import React, { useState } from 'react'

import {useGlobalContext} from '../../context'

import styled from 'styled-components'
import { NavLink, useLocation } from "react-router-dom"

const ContainerNavLink = styled(NavLink)`
    width: 100%;
    border-radius: 4px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    padding: 1rem;
    cursor: pointer; 

    :hover {
        background: rgb(210, 36, 22, 0.04);
    }
`

const ContainerNavExtended = styled.div `
    cursor: pointer;
    color: black;
    display: grid;
    grid-template-rows: auto;
    
`

const ItemContainer = styled.div `
    display: grid;
    /* grid-template-columns: auto auto; */
    align-items: center;
    /* justify-content: space-between;  */
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.colors.black};
    
`

const TextContainer = styled.div `
    font-weight: 600;
    font-size: ${({theme}) => theme.fonts.size.xs};
    width: 100%;
    border-radius: 4px;
    display: grid;
    grid-template-columns: auto auto;
    justify-content: start;
    align-items: center;
    gap: 1rem;   
`

const IconContainer = styled.div `
    padding: 0rem 0.5rem;
`
const DropDownLinks = styled.div `
    display: grid;
    grid-template-columns: auto;

`

const DropDownLink = styled(NavLink) `
    font-weight: 600;
    font-size: ${({ theme }) => theme.fonts.size.xs};
    color: ${({ theme }) => theme.colors.black};
    padding: 1rem 49px;
    border-radius: 4px;

    :hover {
        background: rgb(210, 36, 22, 0.04);
    }
`


const ContainerLink = styled.div `
    padding: 1rem;
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-content: space-between; 

    :hover {
        background: rgb(210, 36, 22, 0.04);
    }



`

const StyledContainer = styled.div `
    display: grid;
    grid-template-columns: auto auto;
    gap: 0.8rem;


`

const StyledIcon = styled.div `
    width: 20px;
`

export const SidebarLink = (props) => {

    const { item } = props
    let location = useLocation();

    let expanded = false
    if(item.subNav) {
        for (let i = 0; i < item.subNav.length; i++) {
            if(location.pathname === item.subNav[i].url2) {
                expanded = true
                break;
            }
        }
    }

    const {toggleMobileMenu} = useGlobalContext();
    const [subnav, setSubnav] = useState(expanded)
    const showSubnav = () => setSubnav(!subnav)

        return (
            <>
                {item.url ?
                    <ContainerNavLink
                        onClick={toggleMobileMenu}
                        to={item.url}>
                        <ItemContainer>
                            <StyledContainer>
                                <StyledIcon>
                                    {item.icon }
                                </StyledIcon>
                                <TextContainer>
                                    {item.text}
                                </TextContainer>
                            </StyledContainer>
                            <IconContainer>
                                {item.subNav && subnav
                                ? item.iconOpened
                                : item.subNav
                                ? item.iconClosed
                                : null}
                            </IconContainer>
                        </ItemContainer>
                    </ContainerNavLink>


                :

                <ContainerNavExtended onClick={item.subNav && showSubnav} >
                    <ItemContainer>
                        <ContainerLink>
                            <StyledContainer>
                                <StyledIcon>
                                    {item.icon }
                                </StyledIcon>
                                <TextContainer>
                                    {item.text}
                                </TextContainer>
                            </StyledContainer>
                            <IconContainer >
                                {item.subNav && subnav
                                ? item.iconOpened
                                : item.subNav
                                ? item.iconClosed
                                : null}
                            </IconContainer>
                        </ContainerLink>
                        <DropDownLinks>
                            {subnav && item.subNav.map((item, index) => {
                                return (
                                    <DropDownLink key={index} to={item.url2} onClick={toggleMobileMenu}>
                                        {item.text}
                                    </DropDownLink>
                                )
                            })}
                        </DropDownLinks>
                    </ItemContainer>
                </ContainerNavExtended>


                }


            </>
        )

}

