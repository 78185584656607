import {combineReducers} from "redux";
import createSagaMiddleware from "redux-saga";
import {authReducer} from '../reducers/authReducer'
import {costTypeReducer} from '../reducers/costTypeReducer'
import authSaga from "../sagas/authSaga";
import costTypeSaga from "../sagas/costTypeSaga";
import storageSession from "redux-persist/lib/storage/session";
import {persistReducer, persistStore} from "redux-persist";
import authTransform from "../transforms/authTransform";


import {doctorReducer} from "../reducers/doctorReducer";
import doctorSaga from "../sagas/doctorSaga";


import {positionReducer} from '../reducers/positionReducer'
import positionSaga from "../sagas/positionSaga";

import {branchReducer} from '../reducers/branchReducer'
import branchSaga from "../sagas/branchSaga";

import {costGroupReducer} from "../reducers/costGroupReducer";
import costGroupSaga from "../sagas/costGroupSaga";

import {configureStore} from "@reduxjs/toolkit";
import settingsSaga from "../sagas/settingsSaga";
import {settingsReducer} from "../reducers/settingsReducer";
import {monthStatReducer} from "../reducers/monthStatReducer";
import monthStatSaga from "../sagas/monthStatSaga";
import toastsSaga from "../sagas/toastsSaga";
import {serviceReducer} from "../reducers/serviceReducer";
import serviceSaga from "../sagas/serviceSaga";

const persistConfig = {
  key: "root",
  storage: storageSession,
  whitelist: ['auth'],
  transforms: [authTransform]
};

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  costType: costTypeReducer,
  costGroup: costGroupReducer,
  doctor: doctorReducer,
  position: positionReducer,
  branch: branchReducer,
  monthStat: monthStatReducer,
  service: serviceReducer,
})
export const store = configureStore({
  reducer: persistReducer(persistConfig, rootReducer),
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}).concat(sagaMiddleware),
})
export const persistor = persistStore(store)
sagaMiddleware.run(authSaga)
sagaMiddleware.run(settingsSaga)
sagaMiddleware.run(costTypeSaga)
sagaMiddleware.run(costGroupSaga)
sagaMiddleware.run(doctorSaga)
sagaMiddleware.run(positionSaga)
sagaMiddleware.run(branchSaga)
sagaMiddleware.run(monthStatSaga)
sagaMiddleware.run(serviceSaga)
sagaMiddleware.run(toastsSaga)

