import {
    COST_GROUP_GET_ACTION_BEGIN,
    COST_GROUP_GET_ACTION_FAILED,
    COST_GROUP_GET_ACTION_REQUESTED,
    COST_GROUP_GET_ACTION_SUCCEEDED,
    COST_GROUP_ADD_ACTION_BEGIN,
    COST_GROUP_ADD_ACTION_FAILED,
    COST_GROUP_ADD_ACTION_REQUESTED,
    COST_GROUP_ADD_ACTION_SUCCEEDED,
    COST_GROUP_REMOVE_ACTION_BEGIN,
    COST_GROUP_REMOVE_ACTION_FAILED,
    COST_GROUP_REMOVE_ACTION_REQUESTED,
    COST_GROUP_REMOVE_ACTION_SUCCEEDED,
    COST_GROUP_UPDATE_ACTION_BEGIN,
    COST_GROUP_UPDATE_ACTION_FAILED,
    COST_GROUP_UPDATE_ACTION_REQUESTED,
    COST_GROUP_UPDATE_ACTION_SUCCEEDED
} from "../constants/actions";

import costGroupService from "../features/costGroup/costGroupService";
import {put, takeEvery, select} from "redux-saga/effects";

function* addCostGroup(action) {
    const busy = yield select((state) => state.costGroup.busyAdding)
    if (!busy) {
        yield put({type:COST_GROUP_ADD_ACTION_BEGIN})
        const costGroupResponse = yield costGroupService.addCostGroup(action.payload)
        if (costGroupResponse.id !== undefined) {
            yield put({type:COST_GROUP_ADD_ACTION_SUCCEEDED, payload: costGroupResponse})
        } else {
            yield put({type:COST_GROUP_ADD_ACTION_FAILED, payload: costGroupResponse})
        }
    }
}

function* updateCostGroup(action) {
    const busy = yield select((state) => state.costGroup.busyUpdating)
    if (!busy) {
        yield put({type:COST_GROUP_UPDATE_ACTION_BEGIN})
        const costGroupResponse = yield costGroupService.updateCostGroup(action.payload)
        if (costGroupResponse.id !== undefined) {
            yield put({type:COST_GROUP_UPDATE_ACTION_SUCCEEDED, payload: costGroupResponse})
        } else {
            yield put({type:COST_GROUP_UPDATE_ACTION_FAILED, payload: costGroupResponse})
        }
    }
}

function* removeCostGroup(action) {
    const busy = yield select((state) => state.costGroup.busyRemoving)
    if (!busy) {
        yield put({type:COST_GROUP_REMOVE_ACTION_BEGIN})
        const costGroupResponse = yield costGroupService.removeCostGroup(action.payload)
        if (costGroupResponse === true) {
            yield put({type:COST_GROUP_REMOVE_ACTION_SUCCEEDED, payload: action.payload})
        } else {
            yield put({type:COST_GROUP_REMOVE_ACTION_FAILED, payload: costGroupResponse})
        }
    }
}

function* getCostGroups() {
    const busy = yield select((state) => state.costGroup.busy)
    if (!busy) {
        yield put({type:COST_GROUP_GET_ACTION_BEGIN})
        const costGroupResponse = yield costGroupService.getCostGroups()
        if (costGroupResponse.length !== undefined) {
            yield put({type:COST_GROUP_GET_ACTION_SUCCEEDED, payload: costGroupResponse})
        } else {
            yield put({type:COST_GROUP_GET_ACTION_FAILED, payload: costGroupResponse})
        }
    }
}

export default function* costGroupSaga() {
    yield takeEvery(COST_GROUP_ADD_ACTION_REQUESTED, addCostGroup)
    yield takeEvery(COST_GROUP_UPDATE_ACTION_REQUESTED, updateCostGroup)
    yield takeEvery(COST_GROUP_REMOVE_ACTION_REQUESTED, removeCostGroup)
    yield takeEvery(COST_GROUP_GET_ACTION_REQUESTED, getCostGroups)
}
