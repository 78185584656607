import React, {useEffect} from "react"
import {Routes, Route} from "react-router-dom"


import Layout from './components/layout'
import Login from './components/login'
import LoginForgetPassword from './components/login-forget-password'
import Registration from './components/registration'
import Calculator from './components/calculatorPage'
import CostsPage from './components/costs'
import BranchesPage from './components/branches'
import CostGroupsPage from './components/costGroups'
import DoctorsPage from './components/doctors'
import PositionsPage from './components/positions'
import NotFoundPage from './components/notFoundPage'
import SettignsPanelPage from './components/settingsPanel'
import HoursPage from './components/hoursPage'
import ResetPasswordPage from './components/resetPassword'
import PricesPage from './components/prices'
import AdministratorPanelPage from './components/administratorPanel'

import ProtectedRoutes from './routes/protectedRoutes'
import {useDispatch} from "react-redux";
import {authActionCreators} from "./actions/authActionCreators";
import {GoogleOAuthProvider} from "@react-oauth/google";


function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(authActionCreators.loginInfoAction())
  }, [])
  return (
    <GoogleOAuthProvider clientId="222482505006-1ums4rkidfik7nsha7el13hasq8oag0o.apps.googleusercontent.com">
      <Routes>
        <Route path='/' element={<Layout/>}>
          <Route index element={<Login/>}/>
          <Route path='resetuj-haslo/:token' element={<ResetPasswordPage/>}/>
          <Route path='nowe-haslo' element={<LoginForgetPassword/>}/>
          <Route path='rejestracja' element={<Registration/>}/>
          <Route element={<ProtectedRoutes/>}>
            <Route path='kalkulator' element={<Calculator/>}/>
            <Route path='koszty' element={<CostsPage/>}/>
            <Route path='dziedziny' element={<BranchesPage/>}/>
            <Route path='grupy-kosztow' element={<CostGroupsPage/>}/>
            <Route path='zespol' element={<DoctorsPage/>}/>
            <Route path='godziny' element={<HoursPage/>}/>
            <Route path='stanowiska' element={<PositionsPage/>}/>
            <Route path='ustawienia' element={<SettignsPanelPage/>}/>
            <Route path='admin' element={<AdministratorPanelPage/>}/>
            <Route path='cennik' element={<PricesPage/>}/>
          <Route path='*' element={<NotFoundPage/>}/>
          </Route>
        </Route>
      </Routes>
    </GoogleOAuthProvider>
  )
}

export default App;
