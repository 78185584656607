import {
  SERVICE_ADD_ACTION_BEGIN,
  SERVICE_ADD_ACTION_FAILED,
  SERVICE_ADD_ACTION_SUCCEEDED,
  SERVICE_GET_ACTION_BEGIN,
  SERVICE_GET_ACTION_FAILED,
  SERVICE_GET_ACTION_SUCCEEDED,
  SERVICE_HIDE_ADDING_MODAL_ACTION,
  SERVICE_HIDE_UPLOADING_MODAL_ACTION,
  SERVICE_HIDE_EDITING_MODAL_ACTION,
  SERVICE_REMOVE_ACTION_BEGIN,
  SERVICE_REMOVE_ACTION_FAILED,
  SERVICE_REMOVE_ACTION_SUCCEEDED,
  SERVICE_SAVE_VALUE_ACTION_BEGIN,
  SERVICE_SAVE_VALUE_ACTION_FAILED,
  SERVICE_SAVE_VALUE_ACTION_SUCCEEDED,
  SERVICE_SHOW_ADDING_MODAL_ACTION,
  SERVICE_SHOW_UPLOADING_MODAL_ACTION,
  SERVICE_SHOW_EDITING_MODAL_ACTION,
  SERVICE_UPDATE_ACTION_BEGIN,
  SERVICE_UPDATE_ACTION_FAILED,
  SERVICE_UPDATE_ACTION_SUCCEEDED,
  SERVICE_UPDATE_ADDING_MODAL_ACTION,
  SERVICE_UPDATE_EDITING_MODAL_ACTION,
  SERVICE_UPDATE_VALUE_ACTION,
  SERVICE_UPDATE_UPLOADING_MODAL_ACTION,
  SERVICE_UPLOAD_ACTION_BEGIN,
  SERVICE_UPLOAD_ACTION_SUCCEEDED, SERVICE_UPLOAD_ACTION_FAILED
} from "../constants/actions";

const initialState = {
  services: [],
  serviceValues: {},
  editedServiceValues: [],
  busyServiceValues: [],
  errorsServiceValues: {},
  initialized: false,
  busy: false,
  busyAdding: false,
  busyUpdating: false,
  busyRemoving: false,
  addingServiceModal: false,
  addingService: {name: '', branch: ''},
  uploadingServiceModal: false,
  uploadingService: {},
  editedService: null,
  errors: {},
  errorsAdding: {},
  errorsUpdating: {},
  errorsRemoving: {},
}

export const serviceReducer = (state = initialState, action) => {
  let services = state.services
  let serviceValues = {...state.serviceValues}
  let errorsServiceValues = {...state.errorsServiceValues}
  let editedServiceValues = [...state.editedServiceValues]
  let busyServiceValues = [...state.busyServiceValues]
  switch (action.type) {
    case SERVICE_GET_ACTION_BEGIN:
      return {
        ...state,
        busy: true,
        errors: {},
      }
    case SERVICE_ADD_ACTION_BEGIN:
      return {
        ...state,
        busyAdding: true,
        errorsAdding: {},
      }
    case SERVICE_UPLOAD_ACTION_BEGIN:
      return {
        ...state,
        busyUploading: true,
        errorsUploading: {},
      }
    case SERVICE_REMOVE_ACTION_BEGIN:
      return {
        ...state,
        busyRemoving: true,
        errorsRemoving: {},
      }
    case SERVICE_UPDATE_ACTION_BEGIN:
      return {
        ...state,
        busyUpdating: true,
        errorsUpdating: {},
      }
    case SERVICE_GET_ACTION_FAILED:
      return {
        ...state,
        busy: false,
        errors: action.payload,
      }
    case SERVICE_ADD_ACTION_FAILED:
      return {
        ...state,
        busyAdding: false,
        errorsAdding: action.payload,
      }
    case SERVICE_UPLOAD_ACTION_FAILED:
      return {
        ...state,
        busyUploading: false,
        errorsUploading: action.payload,
      }
    case SERVICE_REMOVE_ACTION_FAILED:
      return {
        ...state,
        busyRemoving: false,
        errorsRemoving: action.payload,
      }
    case SERVICE_UPDATE_ACTION_FAILED:
      return {
        ...state,
        busyUpdating: false,
        errorsUpdating: action.payload,
      }
    case SERVICE_ADD_ACTION_SUCCEEDED:
      return {
        ...state,
        busyAdding: false,
        addingServiceModal: false,
        services: [...services, action.payload]
      }
    case SERVICE_UPLOAD_ACTION_SUCCEEDED:
      return {
        ...state,
        busyUploading: false,
        uploadingServiceModal: false,
        services: [...services, action.payload]
      }
    case SERVICE_REMOVE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyRemoving: false,
        services: services.filter(service => service.id !== action.payload)
      }
    case SERVICE_UPDATE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyUpdating: false,
        services: services.map(c => {
          if (c.id === action.payload.id) {
            return action.payload
          }
          return c
        }),
        editedService: null,
      }
    case SERVICE_GET_ACTION_SUCCEEDED:
      for (let i = 0; i < action.payload.length; i++) {
        const service = action.payload[i]
        serviceValues[service.id] = {
          value: service.value ?? '',
          time: service.time ?? '',
          salary: service.salary ?? '',
          costsPerHour: service.costsPerHour ?? '',
          servicePriceCosts: service.servicePriceCosts
        }
      }
      editedServiceValues = []
      return {
        ...state,
        busy: false,
        initialized: true,
        services: action.payload,
        serviceValues,
        editedServiceValues
      }
    case SERVICE_SHOW_ADDING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        addingServiceModal: true,
        addingService: {...initialState.addingService},
      }
    case SERVICE_SHOW_UPLOADING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        uploadingServiceModal: true,
        uploadingService: {...initialState.uploadingService},
      }
    case SERVICE_HIDE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingServiceModal: false,
      }
    case SERVICE_HIDE_UPLOADING_MODAL_ACTION:
      return {
        ...state,
        uploadingServiceModal: false
      }
    case SERVICE_UPDATE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingService: action.payload,
      }
    case SERVICE_UPDATE_UPLOADING_MODAL_ACTION:
      return {
        ...state,
        uploadingService: action.payload
      }
    case SERVICE_SHOW_EDITING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        editedService: action.payload,
      }
    case SERVICE_UPDATE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedService: action.payload,
      }
    case SERVICE_HIDE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedService: null,
      }
    case SERVICE_UPDATE_VALUE_ACTION:
      serviceValues[action.payload.id] = {...serviceValues[action.payload.id], ...action.payload.value}
      if (!editedServiceValues.includes(action.payload.id)) {
        editedServiceValues.push(action.payload.id)
      }
      return {
        ...state,
        serviceValues,
        editedServiceValues
      }
    case SERVICE_SAVE_VALUE_ACTION_BEGIN:
      if (!busyServiceValues.includes(action.payload.service.id)) {
        busyServiceValues.push(action.payload.service.id)
      }
      delete errorsServiceValues[action.payload.service.id]
      return {
        ...state,
        busyServiceValues,
        errorsServiceValues
      }
    case SERVICE_SAVE_VALUE_ACTION_FAILED:
      errorsServiceValues[action.payload.id] = action.payload.errors
      return {
        ...state,
        busyServiceValues: busyServiceValues.filter(id => id !== action.payload.id),
        errorsServiceValues
      }
    case SERVICE_SAVE_VALUE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyServiceValues: busyServiceValues.filter(id => id !== action.payload.id),
        editedServiceValues: editedServiceValues.filter(id => id !== action.payload.id),
      }
    default:
      return state;
  }
}
