import styled from 'styled-components'

export const Button = styled.button `
    display: grid;
    cursor: pointer;
    align-items: center;
    padding: 1rem 2rem;
    white-space: nowrap;
    border-radius: 30px;

`