/* eslint-disable */

import React, {useEffect} from 'react'

import styled from 'styled-components'
import {device} from '../../utils/breakpoints'

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Sidebar } from '../common/sidebar'
import { Calculator } from './calculator'
import { MobileMenu } from '../common/mobileMenu'
import {useDispatch} from "react-redux";
import {costTypeActionCreators} from "../../actions/costTypeActionCreators";
import {serviceActionCreators} from "../../actions/serviceActionCreators";




const StyledWrapper = styled(Wrapper) `
    width: 100%;
    background: ${({ theme }) => theme.colors.lightGray};
    min-height: 100vh;
    @media ${device.laptop} {
        padding: 0.7rem;
    }
`
const StyledContainer = styled(Container) `
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.7rem;
    position: relative;

    @media ${device.laptop} {
        grid-template-columns: 350px auto;
    }
`

const ContainerRightSide = styled.div `


    @media ${device.laptop} {
        display: grid;
        grid-column: 2;
        align-content: start;
    }
`


const {
  getCostTypesAction,
} = costTypeActionCreators
const {
  getServicesAction,
} = serviceActionCreators


const CalculatorPage = () => {

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getCostTypesAction())
    dispatch(getServicesAction())
  }, [])
    return (
        <StyledWrapper>
            <StyledContainer>
                <MobileMenu/>
                <Sidebar/>
                <ContainerRightSide>
                    <Calculator/>
                </ContainerRightSide>
            </StyledContainer>
        </StyledWrapper>
    )
}

export default CalculatorPage
