import React, {useEffect} from 'react'
import styled from 'styled-components'
import TextField from '@mui/material/TextField'
import {device} from '../../utils/breakpoints'
import {ContainerInput} from '../common/containerInput'
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {formatPrice} from "../../utils/numberUtils";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {apiDateFormat} from "../../features/client";
import {monthStatActionCreators} from "../../actions/monthStatActionCreators";
import {CustomSelectInput} from "../common/customSelectInput";
import LoaderButton from "../common/loaderButton";
import {doctorActionCreators} from "../../actions/doctorActionCreators";
// import {AiOutlineQuestionCircle} from 'react-icons/ai'
// import IconButton from '@mui/material/IconButton'
// import Tooltip from '@mui/material/Tooltip'

const StyledContainer = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-items: start;
  gap: 2rem;
`

const ContainerInputs = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;

  @media ${device.mobileXL} {
    grid-template-columns: 1fr 1fr 1fr;
  }
`

const ContainerButtons = styled.div `
  display: grid;
  grid-template-columns: 1fr;
  gap: 1.5rem;
  align-items: center;
  justify-items: center;

  @media ${device.mobileXL} {
    grid-template-columns: auto auto;
  }
`


const StyledContainerInput = styled(ContainerInput)`
  max-width: 250px;
`

const TextCostPerHour = styled.div`
  font-size: 1.3rem;
  margin-bottom: 7px;
  line-height: 1.3;

  span {
    color: ${({theme}) => theme.colors.red};
  }
`

const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  max-width: 250px;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
  height: 100%;
`



const {getDoctorsAction} = doctorActionCreators
const {getMonthStatAction, setDataMonthStatAction, updateMonthStatAction, addMonthStatAction} = monthStatActionCreators

export const CheckCosts = ({date, onChangeDate, onAccept, costs = 0}) => {
  const monthStat = useSelector(
    (state) => state.monthStat.monthStat
  )
  const doctorsArray = useSelector(
    (state) => state.doctor.doctors
  )
  const busy = useSelector(
    (state) => state.monthStat.busyChanging
  )
  // const errors = useSelector(
  //   (state) => state.monthStat.errors
  // )
  const hoursWorked = monthStat.hoursWorked
  let hours = 0
  // eslint-disable-next-line no-unused-vars
  for (const [key, val] of Object.entries(hoursWorked)) {
    if (key) {
      const addon = parseFloat(val)
      hours += isNaN(addon) ? 0 : addon
    }
  }
  const perHour = costs / hours
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getMonthStatAction(moment(date).format(apiDateFormat)))
    dispatch(getDoctorsAction())
  }, [date])
  const onSave = data => dispatch(data.id ?
    updateMonthStatAction(data) :
    addMonthStatAction(data))
  return (
    <StyledContainer>
        <ContainerInputs>
          <StyledContainerInput>
            <span>Wybierz miesiąc</span>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
              <DatePicker
                id="month"
                views={['year', 'month']}
                renderInput={(props) => <TextField {...props}/>}
                value={date}
                onChange={onChangeDate}
                onAccept={onAccept}
              />
            </LocalizationProvider>
          </StyledContainerInput>
          <StyledContainerInput>
            <span>godz/przepr/mies/każdy lekarz</span>
            <CustomSelectInput data={hoursWorked}
                              setData={hoursWorked => dispatch(setDataMonthStatAction({...monthStat, hoursWorked}))}
                              entities={doctorsArray}/>
          </StyledContainerInput>

          <StyledContainerInput>
            <span>Koszty stałe/suma/miesiąc</span>
            <TextField id="outlined-basic" label="Suma kosztów" type="number"
                       disabled={true}
                       inputProps={{
                         min: 0,
                         step: 0.01
                       }} variant="outlined"
                       value={costs.toFixed(2)}/>
          </StyledContainerInput>
      </ContainerInputs>
      <TextCostPerHour>Koszty stałe na
        godzinę: <span>{(perHour && hours > 0) ? Math.round(formatPrice(perHour) * 100) / 100 : '0'} zł</span>
      </TextCostPerHour>
      <ContainerButtons>
          <LoaderButton busy={busy} onClick={() => onSave({
            ...monthStat,
            date: moment(date).format(apiDateFormat)
          })}>Zapisz</LoaderButton>

              <AddItem onClick={() => {
                if (perHour && hours > 0) {
                  navigate('/kalkulator', {state: {costsPerHour: Math.round(formatPrice(perHour) * 100) / 100}})
                }
              }} style={{opacity: (perHour && hours > 0) ? 1 : 0.5}}>
                <span>Przejdź do kalkulatora</span>
              </AddItem>

          </ContainerButtons>

    </StyledContainer>
  )
}
