import {
  MONTH_STAT_GET_ACTION_BEGIN,
  MONTH_STAT_GET_ACTION_SUCCEEDED,
  MONTH_STAT_GET_ACTION_FAILED,
  MONTH_STAT_ADD_ACTION_BEGIN,
  MONTH_STAT_ADD_ACTION_SUCCEEDED,
  MONTH_STAT_ADD_ACTION_FAILED,
  MONTH_STAT_REMOVE_ACTION_BEGIN,
  MONTH_STAT_REMOVE_ACTION_SUCCEEDED,
  MONTH_STAT_REMOVE_ACTION_FAILED,
  MONTH_STAT_UPDATE_ACTION_BEGIN,
  MONTH_STAT_UPDATE_ACTION_SUCCEEDED,
  MONTH_STAT_UPDATE_ACTION_FAILED,
  MONTH_STAT_SET_DATA_ACTION,
} from "../constants/actions"

export const initialState = {
  monthStat: {
    hoursOpened: {},
    hoursWorked: {},
    costsSummary: '',
  },
  busy: false,
  busyChanging: false,
  busyRemoving: false,
  loggedIn: false,
  errors: {},
  errorsChanging: {},
  errorsRemoving: {},
}

export const monthStatReducer = (state = initialState, action) => {
  switch (action.type) {
    case MONTH_STAT_GET_ACTION_BEGIN:
      return {
        ...state,
        busy: true,
        errors: {},
      }
    case MONTH_STAT_ADD_ACTION_BEGIN:
      return {
        ...state,
        busyChanging: true,
        errorsChanging: {},
      }
    case MONTH_STAT_REMOVE_ACTION_BEGIN:
      return {
        ...state,
        busyRemoving: true,
        errorsRemoving: {},
      }
    case MONTH_STAT_UPDATE_ACTION_BEGIN:
      return {
        ...state,
        busyChanging: true,
        errorsChanging: {},
      }
    case MONTH_STAT_GET_ACTION_FAILED:
      return {
        ...state,
        busy: false,
        errors: action.payload,
      }
    case MONTH_STAT_ADD_ACTION_FAILED:
      return {
        ...state,
        busyChanging: false,
        errorsChanging: action.payload,
      }
    case MONTH_STAT_REMOVE_ACTION_FAILED:
      return {
        ...state,
        busyRemoving: false,
        errorsRemoving: action.payload,
      }
    case MONTH_STAT_UPDATE_ACTION_FAILED:
      return {
        ...state,
        busyChanging: false,
        errorsChanging: action.payload,
      }

    case MONTH_STAT_ADD_ACTION_SUCCEEDED:
      return {
        ...state,
        busyChanging: false,
        monthStat: action.payload
      }
    case MONTH_STAT_REMOVE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyRemoving: false,
        monthStat: initialState.monthStat
      }
    case MONTH_STAT_UPDATE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyChanging: false,
        monthStat: action.payload,
      }
    case MONTH_STAT_GET_ACTION_SUCCEEDED:
      return {
        ...state,
        busy: false,
        errorsChanging: {},
        monthStat: action.payload ?? initialState.monthStat
      }
    case MONTH_STAT_SET_DATA_ACTION:
      return {
        ...state,
        monthStat: action.payload ?? initialState.monthStat
      }
    default:
      return state;
  }
}
