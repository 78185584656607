import {
    RESET_PASSWORD_ACTION_BEGIN,
    RESET_PASSWORD_ACTION_FAILED,
    RESET_PASSWORD_ACTION_REQUESTED,
    RESET_PASSWORD_ACTION_SUCCEEDED,
    FORGET_PASSWORD_ACTION_BEGIN,
    FORGET_PASSWORD_ACTION_FAILED,
    FORGET_PASSWORD_ACTION_REQUESTED,
    FORGET_PASSWORD_ACTION_SUCCEEDED,

    LOGIN_ACTION_BEGIN,
    LOGIN_ACTION_FAILED,
    LOGIN_ACTION_REQUESTED,
    LOGIN_ACTION_SUCCEEDED,
    LOGIN_FACEBOOK_ACTION_BEGIN,
    LOGIN_FACEBOOK_ACTION_FAILED,
    LOGIN_FACEBOOK_ACTION_REQUESTED,
    LOGIN_FACEBOOK_ACTION_SUCCEEDED,
    LOGIN_GOOGLE_ACTION_BEGIN,
    LOGIN_GOOGLE_ACTION_FAILED,
    LOGIN_GOOGLE_ACTION_REQUESTED,
    LOGIN_GOOGLE_ACTION_SUCCEEDED,
    LOGIN_INFO_ACTION_BEGIN,
    LOGIN_INFO_ACTION_FAILED,
    LOGIN_INFO_ACTION_REQUESTED,
    LOGIN_INFO_ACTION_SUCCEEDED,
    LOGOUT_ACTION_BEGIN,
    LOGOUT_ACTION_FAILED,
    LOGOUT_ACTION_REQUESTED,
    LOGOUT_ACTION_SUCCEEDED,
    REGISTER_ACTION_BEGIN,
    REGISTER_ACTION_FAILED,
    REGISTER_ACTION_REQUESTED,
    REGISTER_ACTION_SUCCEEDED
} from "../constants/actions";
import {put, takeEvery, select} from "redux-saga/effects";
import authService from "../features/auth/authService";

function* login(action) {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: LOGIN_ACTION_BEGIN})
        const {email, password} = action.payload
        const loginResponse = yield authService.login(email, password)
        if (loginResponse === true) {
            const loginInfo = yield authService.loginInfo()
            if (loginInfo) {
                yield put({type: LOGIN_ACTION_SUCCEEDED, payload: loginInfo})
            } else {
                yield put({type: LOGIN_ACTION_FAILED, payload: {default: 'Wystąpił nieoczekiwany błąd'}})
            }
        } else {
            yield put({type: LOGIN_ACTION_FAILED, payload: loginResponse})
        }
    }
}

function* loginFacebook(action) {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: LOGIN_FACEBOOK_ACTION_BEGIN})
        const accessToken = action.payload
        const loginResponse = yield authService.loginFacebook(accessToken)
        if (loginResponse === true) {
            const loginInfo = yield authService.loginInfo()
            if (loginInfo) {
                yield put({type: LOGIN_FACEBOOK_ACTION_SUCCEEDED, payload: loginInfo})
            } else {
                yield put({type: LOGIN_FACEBOOK_ACTION_FAILED, payload: {default: 'Wystąpił nieoczekiwany błąd'}})
            }
        } else {
            yield put({type: LOGIN_FACEBOOK_ACTION_FAILED, payload: loginResponse})
        }
    }
}

function* loginGoogle(action) {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: LOGIN_GOOGLE_ACTION_BEGIN})
        const accessToken = action.payload
        const loginResponse = yield authService.loginGoogle(accessToken)
        if (loginResponse === true) {
            const loginInfo = yield authService.loginInfo()
            if (loginInfo) {
                yield put({type: LOGIN_GOOGLE_ACTION_SUCCEEDED, payload: loginInfo})
            } else {
                yield put({type: LOGIN_GOOGLE_ACTION_FAILED, payload: {default: 'Wystąpił nieoczekiwany błąd'}})
            }
        } else {
            yield put({type: LOGIN_GOOGLE_ACTION_FAILED, payload: loginResponse})
        }
    }
}

function* register(action) {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: REGISTER_ACTION_BEGIN})
        const {vatNumber, email, plainPassword, agreeTerms} = action.payload
        const registerResponse = yield authService.register(vatNumber, email, plainPassword, agreeTerms)
        if (registerResponse === true) {
            const loginInfo = yield authService.loginInfo()
            if (loginInfo) {
                yield put({type: REGISTER_ACTION_SUCCEEDED, payload: loginInfo})
            } else {
                yield put({type: REGISTER_ACTION_FAILED, payload: {default: 'Wystąpił nieoczekiwany błąd'}})
            }
        } else {
            yield put({type: REGISTER_ACTION_FAILED, payload: registerResponse})
        }
    }
}

function* logout() {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: LOGOUT_ACTION_BEGIN})
        const logoutResponse = yield authService.logout()
        if (logoutResponse === true) {
            yield put({type: LOGOUT_ACTION_SUCCEEDED})
        } else {
            yield put({type: LOGOUT_ACTION_FAILED, payload: {default: 'Wystąpił nieoczekiwany błąd'}})
        }
    }
}

function* loginInfo() {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: LOGIN_INFO_ACTION_BEGIN})
        const loginInfoResponse = yield authService.loginInfo()
        if (loginInfoResponse) {
            yield put({type: LOGIN_INFO_ACTION_SUCCEEDED, payload: loginInfoResponse})
        } else {
            yield put({type: LOGIN_INFO_ACTION_FAILED, payload: {default: 'Wystąpił nieoczekiwany błąd'}})
        }
    }
}

function* resetPassword(action) {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: RESET_PASSWORD_ACTION_BEGIN})
        const resetPasswordResponse = yield authService.resetPassword(action.payload.plainPassword, action.payload.token)
        if (resetPasswordResponse === true) {
            yield put({type: RESET_PASSWORD_ACTION_SUCCEEDED, payload: resetPasswordResponse})
        } else {
            yield put({type: RESET_PASSWORD_ACTION_FAILED, payload: resetPasswordResponse})
        }
    }
}

function* forgetPassword(action) {
    const busy = yield select((state) => state.auth.busy)
    if (!busy) {
        yield put({type: FORGET_PASSWORD_ACTION_BEGIN})
        const forgetPasswordResponse = yield authService.forgetPassword(action.payload.email)
        if (forgetPasswordResponse === true) {
            yield put({type: FORGET_PASSWORD_ACTION_SUCCEEDED, payload: forgetPasswordResponse})
        } else {
            yield put({type: FORGET_PASSWORD_ACTION_FAILED, payload: forgetPasswordResponse})
        }
    }
}


export default function* authSaga() {
    yield takeEvery(LOGIN_ACTION_REQUESTED, login)
    yield takeEvery(LOGIN_FACEBOOK_ACTION_REQUESTED, loginFacebook)
    yield takeEvery(LOGIN_GOOGLE_ACTION_REQUESTED, loginGoogle)
    yield takeEvery(REGISTER_ACTION_REQUESTED, register)
    yield takeEvery(LOGOUT_ACTION_REQUESTED, logout)
    yield takeEvery(LOGIN_INFO_ACTION_REQUESTED, loginInfo)

    yield takeEvery(RESET_PASSWORD_ACTION_REQUESTED, resetPassword)
     yield takeEvery(FORGET_PASSWORD_ACTION_REQUESTED, forgetPassword)
}
