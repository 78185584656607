import {
    LOGIN_ACTION_REQUESTED,
    LOGIN_FACEBOOK_ACTION_REQUESTED,
    LOGIN_GOOGLE_ACTION_REQUESTED,
    LOGIN_INFO_ACTION_REQUESTED,
    LOGOUT_ACTION_REQUESTED,
    REGISTER_ACTION_REQUESTED,
    FORGET_PASSWORD_ACTION_REQUESTED,
    RESET_PASSWORD_ACTION_REQUESTED,
} from "../constants/actions";

function loginAction(email, password) {
    return {
        type: LOGIN_ACTION_REQUESTED,
        payload: {email, password}
    }
}

function loginFacebookAction(accessToken) {
    return {
        type: LOGIN_FACEBOOK_ACTION_REQUESTED,
        payload: accessToken
    }
}

function loginGoogleAction(accessToken) {
    return {
        type: LOGIN_GOOGLE_ACTION_REQUESTED,
        payload: accessToken
    }
}

function registerAction(vatNumber, email, plainPassword, agreeTerms) {
    return {
        type: REGISTER_ACTION_REQUESTED,
        payload: {vatNumber, email, plainPassword, agreeTerms}
    }
}

function logoutAction() {
    return {
        type: LOGOUT_ACTION_REQUESTED
    }
}

function loginInfoAction() {
    return {
        type: LOGIN_INFO_ACTION_REQUESTED
    }
}

function forgetPasswordAction(email) {
    return {
        type: FORGET_PASSWORD_ACTION_REQUESTED,
        payload: {email}
    }
}

function resetPasswordAction(plainPassword, token) {
    return {
        type: RESET_PASSWORD_ACTION_REQUESTED,
        payload: {plainPassword, token}
    }
}



export const authActionCreators = {loginAction, registerAction, logoutAction, loginInfoAction, loginFacebookAction, loginGoogleAction, forgetPasswordAction, resetPasswordAction}
