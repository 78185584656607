import { createGlobalStyle } from 'styled-components'




export const GlobalStyles = createGlobalStyle`

  *, *::before, *::after {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-variant: normal;
    text-transform: none;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
  }

  body {
    font-size: 0.9rem;
    visibility: hidden;
    overflow-x: hidden;
    font-family: 'Open Sans', sans-serif;
    line-height: 1.2;
    font-kerning: normal;
    font-weight: 400;
    word-wrap: break-word;
    color: #0E042D;
  }

  a, button {

    font-family: 'Open Sans', sans-serif;
    cursor: pointer;
  }

  *:focus {
    outline: none;
  }

  input, textarea, label {
    font-family: 'Open Sans', sans-serif !important;
    font-weight: 400 !important;
  }


  p {
    line-height: 130%;
  }

`
