import {
  COST_TYPE_GET_ACTION_REQUESTED,
  COST_TYPE_ADD_ACTION_REQUESTED,
  COST_TYPE_REMOVE_ACTION_REQUESTED,
  COST_TYPE_UPDATE_ACTION_REQUESTED,
  COST_TYPE_SHOW_ADDING_MODAL_ACTION,
  COST_TYPE_HIDE_ADDING_MODAL_ACTION,
  COST_TYPE_UPDATE_ADDING_MODAL_ACTION,
  COST_TYPE_SHOW_EDITING_MODAL_ACTION,
  COST_TYPE_HIDE_EDITING_MODAL_ACTION,
  COST_TYPE_UPDATE_EDITING_MODAL_ACTION,
  COST_TYPE_UPDATE_VALUE_ACTION,
  COST_TYPE_UPDATE_MONTH_ACTION,
  COST_TYPE_SAVE_VALUE_ACTION_REQUESTED
} from "../constants/actions";
import moment from "moment";
import {apiMonthFormat} from "../features/client";

function getCostTypesAction(month) {
  return {
    type: COST_TYPE_GET_ACTION_REQUESTED,
    payload: {month: moment(month).format(apiMonthFormat)}
  }
}

function addCostTypeAction(name, costGroup, type) {
  return {
    type: COST_TYPE_ADD_ACTION_REQUESTED,
    payload: {name, costGroup, type}
  }
}

function removeCostTypeAction(id) {
  return {
    type: COST_TYPE_REMOVE_ACTION_REQUESTED,
    payload: id
  }
}

function updateCostTypeAction(costType) {
  return {
    type: COST_TYPE_UPDATE_ACTION_REQUESTED,
    payload: {...costType, costGroup: costType.costGroup ? (costType.costGroup.id ?? costType.costGroup) : null}
  }
}

function showAddingCostModalAction(type) {
  return {
    type: COST_TYPE_SHOW_ADDING_MODAL_ACTION,
    payload: type,
  }
}

function hideAddingCostModalAction() {
  return {
    type: COST_TYPE_HIDE_ADDING_MODAL_ACTION
  }
}

function updateAddingCostModalAction(data) {
  return {
    type: COST_TYPE_UPDATE_ADDING_MODAL_ACTION,
    payload: data,
  }
}

function showEditingCostModalAction(costType) {
  return {
    type: COST_TYPE_SHOW_EDITING_MODAL_ACTION,
    payload: costType,
  }
}

function hideEditingCostModalAction() {
  return {
    type: COST_TYPE_HIDE_EDITING_MODAL_ACTION
  }
}

function updateEditingCostModalAction(data) {
  return {
    type: COST_TYPE_UPDATE_EDITING_MODAL_ACTION,
    payload: data,
  }
}

function updateCostTypeValueAction(id, value) {
  return {
    type: COST_TYPE_UPDATE_VALUE_ACTION,
    payload: {id, value},
  }
}

function updateCostTypeMonthAction(month) {
  return {
    type: COST_TYPE_UPDATE_MONTH_ACTION,
    payload: moment(month).format(apiMonthFormat),
  }
}

function saveCostTypeValueAction(costType, month, value) {
  return {
    type: COST_TYPE_SAVE_VALUE_ACTION_REQUESTED,
    payload: {...costType, value, date: month, costGroup: costType.costGroup ? (costType.costGroup.id ?? costType.costGroup) : null},
  }
}

export const costTypeActionCreators = {
  getCostTypesAction,
  addCostTypeAction,
  removeCostTypeAction,
  updateCostTypeAction,
  showAddingCostModalAction,
  hideAddingCostModalAction,
  updateAddingCostModalAction,
  showEditingCostModalAction,
  hideEditingCostModalAction,
  updateEditingCostModalAction,
  updateCostTypeValueAction,
  updateCostTypeMonthAction,
  saveCostTypeValueAction
}
