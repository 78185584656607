import React from 'react'
import styled from 'styled-components'
import Pagination from '@mui/material/Pagination'
import Stack from '@mui/material/Stack'




const ContainerPagination = styled.div `
    display: grid;
    justify-items: start;

    .css-19xm0h7-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
        background-color:   ${({ theme }) => theme.colors.red}; 
        color: ${({ theme }) => theme.colors.white}; 
        border: 0px solid ${({ theme }) => theme.colors.red}; 
    }

`

export const PaginationComponent = ({itemsPerPage, totalItems, paginate}) => {
    const pageNumbers = Math.ceil(totalItems / itemsPerPage)
    const handleChange = (event, value) => {
        paginate(value);
    };

    return (
        <ContainerPagination>
            <Stack spacing={2}>
                <Pagination 
                    count={pageNumbers} 
                    onChange={handleChange}
                    variant="outlined" 
                    shape="rounded"
                />
            </Stack>
        </ContainerPagination>
    )
}