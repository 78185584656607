import {
    MONTH_STAT_GET_ACTION_BEGIN,
    MONTH_STAT_GET_ACTION_FAILED,
    MONTH_STAT_GET_ACTION_REQUESTED,
    MONTH_STAT_GET_ACTION_SUCCEEDED,
    MONTH_STAT_ADD_ACTION_BEGIN,
    MONTH_STAT_ADD_ACTION_FAILED,
    MONTH_STAT_ADD_ACTION_REQUESTED,
    MONTH_STAT_ADD_ACTION_SUCCEEDED,
    MONTH_STAT_REMOVE_ACTION_BEGIN,
    MONTH_STAT_REMOVE_ACTION_FAILED,
    MONTH_STAT_REMOVE_ACTION_REQUESTED,
    MONTH_STAT_REMOVE_ACTION_SUCCEEDED,
    MONTH_STAT_UPDATE_ACTION_BEGIN,
    MONTH_STAT_UPDATE_ACTION_FAILED,
    MONTH_STAT_UPDATE_ACTION_REQUESTED,
    MONTH_STAT_UPDATE_ACTION_SUCCEEDED
} from "../constants/actions";

import monthStatService from "../features/monthStat/monthStatService";
import {put, takeEvery, select} from "redux-saga/effects";
import {initialState} from "../reducers/monthStatReducer";
import {UNASSIGNED_COST_TYPE} from "../constants/costTypeDict";

function* addMonthStat(action) {
    const busy = yield select((state) => state.monthStat.busyAdding)
    if (!busy) {
        yield put({type:MONTH_STAT_ADD_ACTION_BEGIN})
        const monthStatResponse = yield monthStatService.addMonthStat(action.payload)
        if (monthStatResponse.id !== undefined) {
            yield put({type:MONTH_STAT_ADD_ACTION_SUCCEEDED, payload: monthStatResponse})
        } else {
            yield put({type:MONTH_STAT_ADD_ACTION_FAILED, payload: monthStatResponse})
        }
    }
}

function* updateMonthStat(action) {
    const busy = yield select((state) => state.monthStat.busyUpdating)
    if (!busy) {
        yield put({type:MONTH_STAT_UPDATE_ACTION_BEGIN})
        const monthStatResponse = yield monthStatService.updateMonthStat(action.payload)
        if (monthStatResponse.id !== undefined) {
            yield put({type:MONTH_STAT_UPDATE_ACTION_SUCCEEDED, payload: monthStatResponse})
        } else {
            yield put({type:MONTH_STAT_UPDATE_ACTION_FAILED, payload: monthStatResponse})
        }
    }
}

function* removeMonthStat(action) {
    const busy = yield select((state) => state.monthStat.busyRemoving)
    if (!busy) {
        yield put({type:MONTH_STAT_REMOVE_ACTION_BEGIN})
        const monthStatResponse = yield monthStatService.removeMonthStat(action.payload)
        if (monthStatResponse === true) {
            yield put({type:MONTH_STAT_REMOVE_ACTION_SUCCEEDED, payload: action.payload})
        } else {
            yield put({type:MONTH_STAT_REMOVE_ACTION_FAILED, payload: monthStatResponse})
        }
    }
}

function* getMonthStat(action) {
    const busy = yield select((state) => state.monthStat.busy)
    if (!busy) {
        const costTypes = yield select((state) => state.costType.costTypes)
        const costTypeValues = yield select((state) => state.costType.costTypeValues)
        let unassignedCostsSummary = 0
        for (let i = 0; i < costTypes.length; i++) {
            const costType = costTypes[i]
            if (costType.type === UNASSIGNED_COST_TYPE) {
                unassignedCostsSummary += parseFloat(costTypeValues[costType.id] ? costTypeValues[costType.id] : 0)
            }
        }
        yield put({type:MONTH_STAT_GET_ACTION_BEGIN})
        const monthStatResponse = yield monthStatService.getMonthStats(action.payload.date)
        if (monthStatResponse !== undefined && (monthStatResponse === null || monthStatResponse.id)) {
            yield put({type:MONTH_STAT_GET_ACTION_SUCCEEDED, payload: monthStatResponse ?? {...initialState.monthStat, costsSummary: unassignedCostsSummary + ''}})
        } else {
            yield put({type:MONTH_STAT_GET_ACTION_FAILED, payload: monthStatResponse})
        }
    }
}

export default function* monthStatSaga() {
    yield takeEvery(MONTH_STAT_ADD_ACTION_REQUESTED, addMonthStat)
    yield takeEvery(MONTH_STAT_UPDATE_ACTION_REQUESTED, updateMonthStat)
    yield takeEvery(MONTH_STAT_REMOVE_ACTION_REQUESTED, removeMonthStat)
    yield takeEvery(MONTH_STAT_GET_ACTION_REQUESTED, getMonthStat)
}
