import {apiRequest, unexpectedError} from "../client";

const addBranch = async (branch) => {
  const response = await apiRequest('POST', 'api/branches', branch)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const updateBranch = async (branch) => {
  const response = await apiRequest('PATCH', 'api/branches', branch)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const removeBranch = async (id) => {
  const response = await apiRequest('DELETE', 'api/branches', {id})

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}

const getBranches = async () => {
  const response = await apiRequest('GET', 'api/branches')

  if (response.status === 200) {
    const {success, errors, items} = response.data
    if (success) {
      return items
    }
    return errors
  }

  return unexpectedError
}

const branchService = {
  getBranches,
  updateBranch,
  removeBranch,
  addBranch,
}

export default branchService
