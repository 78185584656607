import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import {BsPen} from "react-icons/bs"


export const EditItem = ({handleEdit, tooltipText}) => (
    <Tooltip title={tooltipText}>
        <IconButton
            onClick={handleEdit}
        >
            <BsPen color='#000' size='17px'/>
        </IconButton>

    </Tooltip>
)


