import {apiRequest, unexpectedError} from "../client";

const changePassword = async (currentPassword, newPassword1, newPassword2) => {
  const response = await apiRequest('POST', 'api/users/changePassword', {
    current_password: currentPassword,
    plainPassword: {
      first: newPassword1,
      second: newPassword2
    }
  })

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}
const changeEmail = async (currentPassword, newEmail) => {
  const response = await apiRequest('POST', 'api/users/changeEmail', {
    current_password: currentPassword,
    email: newEmail
  })

  if (response.status === 200) {
    const {success, errors, email} = response.data
    if (success) {
      return email
    }
    return errors
  }

  return unexpectedError
}

const getSettings = async () => {
  const response = await apiRequest('GET', 'api/users/settings')

  if (response.status === 200) {
    const {success, errors, items} = response.data
    if (success) {
      return items
    }
    return errors
  }

  return unexpectedError
}

const settingsService = {
  getSettings,
  changePassword,
  changeEmail
}

export default settingsService
