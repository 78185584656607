import {
  SETTINGS_CHANGE_EMAIL_ACTION_REQUESTED,
  SETTINGS_CHANGE_PASSWORD_ACTION_REQUESTED,
  SETTINGS_GET_ACTION_REQUESTED,
  SETTINGS_SET_CHANGE_EMAIL_DATA_ACTION,
  SETTINGS_SET_CHANGE_PASSWORD_DATA_ACTION
} from "../constants/actions";

function getSettingsAction() {
  return {
    type: SETTINGS_GET_ACTION_REQUESTED,
  }
}

function changeEmailAction(current_password, newEmail) {
  return {
    type: SETTINGS_CHANGE_EMAIL_ACTION_REQUESTED,
    payload: {newEmail, current_password}
  }
}

function changePasswordAction(current_password, plainPassword1, plainPassword2) {
  return {
    type: SETTINGS_CHANGE_PASSWORD_ACTION_REQUESTED,
    payload: {current_password, plainPassword1, plainPassword2}
  }
}

function setChangePasswordData(data) {
  return {
    type: SETTINGS_SET_CHANGE_PASSWORD_DATA_ACTION,
    payload: data
  }
}

function setChangeEmailData(data) {
  return {
    type: SETTINGS_SET_CHANGE_EMAIL_DATA_ACTION,
    payload: data
  }
}

export const settingsActionCreators = {
  getSettingsAction,
  changeEmailAction,
  changePasswordAction,
  setChangePasswordData,
  setChangeEmailData
}
