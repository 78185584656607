import React from 'react'
import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import { Heading } from '../common/heading'
import { Line } from '../common/line'
import { GeneralSettings } from './general-settings'
import {InstructionLink} from '../common/instructionLink'


const WrapperSettings = styled.div `
    display: grid;
    grid-template-rows: auto;
    gap: 2rem;
    align-content: start;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
    display: grid;
    grid-template-columns: auto;
    padding: 7rem 1.5rem;
    min-height: calc(100vh - 22.4px);

    @media ${device.laptop} {
      padding: 2rem 1.5rem;
    }
`
const ContainerHeading = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0%   {transform: scale(.7);}
    50%  {transform: scale(1);}
    100% {transform: scale(.7);}
  }

` 

const ContainerSettingsPanel = styled.div `

`


export const SettingsPanel = () => {

    return (
        <WrapperSettings>
            <ContainerHeading>
                <Heading h1 as='h1'>Ustawienia</Heading>
                <InstructionLink link="/" tooltipText="Zobacz filmik instruktażowy." />
            </ContainerHeading>
            <Line/>
            <ContainerSettingsPanel>
                <GeneralSettings/>
            </ContainerSettingsPanel>
        </WrapperSettings> 
    )
}