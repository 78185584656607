import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import IconButton from '@mui/material/IconButton'
import {BsTrash} from "react-icons/bs"


export const DeleteItem = ({handleDelete, tooltipText}) => (
    <Tooltip title={tooltipText}>
        <IconButton
            onClick={handleDelete}
        >
            <BsTrash color='#000' size='17px'/>
        </IconButton>

    </Tooltip>
)


