import {
  FORGET_PASSWORD_ACTION_SUCCEEDED,
  FORGET_PASSWORD_ACTION_FAILED,
  RESET_PASSWORD_ACTION_SUCCEEDED,
  RESET_PASSWORD_ACTION_FAILED,


  COST_TYPE_ADD_ACTION_SUCCEEDED,
  COST_TYPE_UPDATE_ACTION_SUCCEEDED,
  COST_TYPE_REMOVE_ACTION_SUCCEEDED,

  DOCTOR_ADD_ACTION_SUCCEEDED,
  DOCTOR_UPDATE_ACTION_SUCCEEDED,
  DOCTOR_REMOVE_ACTION_SUCCEEDED,


  BRANCH_ADD_ACTION_SUCCEEDED,
  BRANCH_UPDATE_ACTION_SUCCEEDED,
  BRANCH_REMOVE_ACTION_SUCCEEDED,


  POSITION_ADD_ACTION_SUCCEEDED,
  POSITION_UPDATE_ACTION_SUCCEEDED,
  POSITION_REMOVE_ACTION_SUCCEEDED,


  COST_GROUP_ADD_ACTION_SUCCEEDED,
  COST_GROUP_UPDATE_ACTION_SUCCEEDED,
  COST_GROUP_REMOVE_ACTION_SUCCEEDED,
  SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED,
  SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED,
  SETTINGS_CHANGE_PASSWORD_ACTION_FAILED, SETTINGS_CHANGE_EMAIL_ACTION_FAILED,


  MONTH_STAT_ADD_ACTION_SUCCEEDED,
  MONTH_STAT_ADD_ACTION_FAILED,
  MONTH_STAT_REMOVE_ACTION_SUCCEEDED,
  MONTH_STAT_REMOVE_ACTION_FAILED,
  MONTH_STAT_UPDATE_ACTION_SUCCEEDED,
  MONTH_STAT_UPDATE_ACTION_FAILED,


  SERVICE_ADD_ACTION_SUCCEEDED,
  SERVICE_ADD_ACTION_FAILED,
  SERVICE_REMOVE_ACTION_SUCCEEDED,
  SERVICE_REMOVE_ACTION_FAILED,
  SERVICE_UPDATE_ACTION_SUCCEEDED,
  SERVICE_UPDATE_ACTION_FAILED,

  SERVICE_SAVE_VALUE_ACTION_SUCCEEDED,
  SERVICE_SAVE_VALUE_ACTION_FAILED, SERVICE_UPLOAD_ACTION_SUCCEEDED, SERVICE_UPLOAD_ACTION_FAILED


} from "../constants/actions";
import {takeEvery} from "redux-saga/effects";
import {toast} from "react-toastify";








function* addCostTypeSuccess() {
  yield toast.success("Pomyślnie dodano koszt")
}
function* editCostTypeSuccess() {
  yield toast.success("Pomyślnie edytowano koszt")
}
function* removeCostTypeSuccess() {
  yield toast.success("Pomyślnie usunięto koszt")
}



function* addDoctorSuccess() {
  yield toast.success("Pomyślnie dodano lekarza/higienistkę")
}
function* editDoctorSuccess() {
  yield toast.success("Pomyślnie edytowano lekarza/higienistkę")
}
function* removeDoctorSuccess() {
  yield toast.success("Pomyślnie usunięto lekarza/higienistkę")
}


function* addBranchSuccess() {
  yield toast.success("Pomyślnie dodano dziedzinę")
}
function* editBranchSuccess() {
  yield toast.success("Pomyślnie edytowano dziedzinę")
}
function* removeBranchSuccess() {
  yield toast.success("Pomyślnie usunięto dziedzinę")
}



function* addPositionSuccess() {
  yield toast.success("Pomyślnie dodano wizytę")
}
function* editPositionSuccess() {
  yield toast.success("Pomyślnie edytowano wizytę")
}
function* removePositioSuccess() {
  yield toast.success("Pomyślnie usunięto wizytę")
}



function* addCostGroupSuccess() {
  yield toast.success("Pomyślnie dodano grupę kosztów")
}
function* editCostGroupSuccess() {
  yield toast.success("Pomyślnie edytowano grupę kosztów")
}
function* removeCostGroupSuccess() {
  yield toast.success("Pomyślnie usunięto grupę kosztów")
}




function* forgetPasswordSuccess() {
  yield toast.info("Link z resetowaniem hasła został wysłany na maila")
}
function* resetPassswordSuccess() {
  yield toast.success("Pomyślnie zmieniono hasło")
}



function* forgetPasswordFailed() {
  yield toast.error("Błąd w trakcie wysyłania maila")
}
function* resetPassswordFailed() {
  yield toast.error("Błąd w trakcie resetowania hasła")
}



function* changePassswordSuccess() {
  yield toast.success("Pomyślnie zmieniono hasło")
}
function* changePassswordFailed() {
  yield toast.error("Błąd w trakcie zmiany hasła")
}
function* changeEmailSuccess() {
  yield toast.success("Pomyślnie zmieniono email")
}
function* changeEmailFailed() {
  yield toast.error("Błąd w trakcie zmiany maila")
}




function* addServiceSuccess() {
  yield toast.success("Pomyślnie dodano")
}
function* uploadServiceSuccess() {
  yield toast.success("Pomyślnie wgrano plik")
}
function* addServiceFailed() {
  yield toast.error("Błąd przy dodawaniu")
}
function* uploadServiceFailed() {
  yield toast.error("Błąd przy wgrywaniu pliku")
}
function* editServiceSuccess() {
  yield toast.success("Pomyślnie edytowano")
}
function* editServiceFailed() {
  yield toast.error("Błąd przy edytowaniu")
}
function* removeServiceSuccess() {
  yield toast.success("Pomyślnie usunięto")
}
function* removeServiceFailed() {
  yield toast.error("Błąd przy usuwaniu")
}




function* addMonthStatSuccess() {
  yield toast.success("Pomyślnie dodano")
}
function* addMonthStatFailed() {
  yield toast.error("Błąd przy dodawaniu")
}
function* editMonthStatSuccess() {
  yield toast.success("Pomyślnie edytowano")
}
function* editMonthStatFailed() {
  yield toast.error("Błąd przy edytowaniu")
}
function* removeMonthStatSuccess() {
  yield toast.success("Pomyślnie usunięto")
}
function* removeMonthStatFailed() {
  yield toast.error("Błąd przy usuwaniu")
}

function* saveServiceValueSuccess() {
  yield toast.success("Pomyślnie zapisano")
}
function* saveServiceValueFailed() {
  yield toast.error("Błąd przy zapisywaniu")
}




export default function* toastsSaga() {
  yield takeEvery(COST_TYPE_ADD_ACTION_SUCCEEDED, addCostTypeSuccess)
  yield takeEvery(COST_TYPE_UPDATE_ACTION_SUCCEEDED, editCostTypeSuccess)
  yield takeEvery(COST_TYPE_REMOVE_ACTION_SUCCEEDED , removeCostTypeSuccess)

  yield takeEvery(DOCTOR_ADD_ACTION_SUCCEEDED , addDoctorSuccess)
  yield takeEvery(DOCTOR_UPDATE_ACTION_SUCCEEDED , editDoctorSuccess)
  yield takeEvery(DOCTOR_REMOVE_ACTION_SUCCEEDED , removeDoctorSuccess)

  yield takeEvery(BRANCH_ADD_ACTION_SUCCEEDED, addBranchSuccess)
  yield takeEvery(BRANCH_UPDATE_ACTION_SUCCEEDED, editBranchSuccess)
  yield takeEvery(BRANCH_REMOVE_ACTION_SUCCEEDED , removeBranchSuccess)




  yield takeEvery(POSITION_ADD_ACTION_SUCCEEDED, addPositionSuccess)
  yield takeEvery(POSITION_UPDATE_ACTION_SUCCEEDED, editPositionSuccess)
  yield takeEvery(POSITION_REMOVE_ACTION_SUCCEEDED , removePositioSuccess)


  yield takeEvery(COST_GROUP_ADD_ACTION_SUCCEEDED, addCostGroupSuccess)
  yield takeEvery(COST_GROUP_UPDATE_ACTION_SUCCEEDED, editCostGroupSuccess)
  yield takeEvery(COST_GROUP_REMOVE_ACTION_SUCCEEDED , removeCostGroupSuccess)


  yield takeEvery(FORGET_PASSWORD_ACTION_SUCCEEDED, forgetPasswordSuccess)
  yield takeEvery(FORGET_PASSWORD_ACTION_FAILED, forgetPasswordFailed)
  yield takeEvery(RESET_PASSWORD_ACTION_SUCCEEDED , resetPassswordSuccess)
  yield takeEvery(RESET_PASSWORD_ACTION_FAILED , resetPassswordFailed)


  yield takeEvery(SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED , changePassswordSuccess)
  yield takeEvery(SETTINGS_CHANGE_PASSWORD_ACTION_FAILED , changePassswordFailed)
  yield takeEvery(SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED , changeEmailSuccess)
  yield takeEvery(SETTINGS_CHANGE_EMAIL_ACTION_FAILED , changeEmailFailed)


  yield takeEvery(MONTH_STAT_ADD_ACTION_SUCCEEDED , addMonthStatSuccess)
  yield takeEvery(MONTH_STAT_ADD_ACTION_FAILED , addMonthStatFailed)
  yield takeEvery(MONTH_STAT_REMOVE_ACTION_SUCCEEDED , removeMonthStatSuccess)
  yield takeEvery(MONTH_STAT_REMOVE_ACTION_FAILED , removeMonthStatFailed)
  yield takeEvery(MONTH_STAT_UPDATE_ACTION_SUCCEEDED , editMonthStatSuccess)
  yield takeEvery(MONTH_STAT_UPDATE_ACTION_FAILED , editMonthStatFailed)

  yield takeEvery(SERVICE_ADD_ACTION_SUCCEEDED , addServiceSuccess)
  yield takeEvery(SERVICE_ADD_ACTION_FAILED , addServiceFailed)
  yield takeEvery(SERVICE_UPLOAD_ACTION_SUCCEEDED , uploadServiceSuccess)
  yield takeEvery(SERVICE_UPLOAD_ACTION_FAILED , uploadServiceFailed)
  yield takeEvery(SERVICE_REMOVE_ACTION_SUCCEEDED , removeServiceSuccess)
  yield takeEvery(SERVICE_REMOVE_ACTION_FAILED , removeServiceFailed)
  yield takeEvery(SERVICE_UPDATE_ACTION_SUCCEEDED , editServiceSuccess)
  yield takeEvery(SERVICE_UPDATE_ACTION_FAILED , editServiceFailed)

  yield takeEvery(SERVICE_SAVE_VALUE_ACTION_SUCCEEDED , saveServiceValueSuccess)
  yield takeEvery(SERVICE_SAVE_VALUE_ACTION_FAILED , saveServiceValueFailed)


}
