
//universal

const marksSalary = [
  {
    value: 0,
    label: '0%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 100,
    label: '100%',
  }

];


const marksCosts = [
  {
    value: 0,
    label: '0 zł',
  },
  {
    value: 300,
    label: '300 zł',
  },
  {
    value: 600,
    label: '600 zł',
  }

];


//cal 1
const marksPriceLow = [
  {
    value: 0,
    label: '0 zł',
  },
  {
    value: 2000,
    label: '2tyś zł',
  },
  {
    value: 4000,
    label: '4tyś zł',
  }

];

const marksTimeLow = [
  {
    value: 0,
    label: '0 min',
  },
  {
    value: 120,
    label: '2 h',
  },
  {
    value: 240,
    label: '4 h',
  }

];


//cal 2
const marksPriceMedium = [
  {
    value: 0,
    label: '0 zł',
  },
  {
    value: 10000,
    label: '10tyś zł',
  },
  {
    value: 20000,
    label: '20tyś zł',
  }

];

const marksTimeMedium = [
  {
    value: 0,
    label: '0 min',
  },
  {
    value: 20,
    label: '20 h',
  },
  {
    value: 40,
    label: '40 h',
  }

];

//cal 3
const marksPriceHigh = [
  {
    value: 0,
    label: '0 zł',
  },
  {
    value: 50000,
    label: '50tyś zł',
  },
  {
    value: 100000,
    label: '100tyś zł',
  }

];

const marksTimeHigh = [
  {
    value: 0,
    label: '0 min',
  },
  {
    value: 80,
    label: '80 h',
  },
  {
    value: 160,
    label: '160 h',
  }

];

export const calculatorSettings = {
  marksSalary: {
    1: marksSalary,
    2: marksSalary,
    3: marksSalary
  },
  marksCosts: {
    1: marksCosts,
    2: marksCosts,
    3: marksCosts
  },
  marksPrice: {
    1: marksPriceLow,
    2: marksPriceMedium,
    3: marksPriceHigh
  },
  marksTime: {
    1: marksTimeLow,
    2: marksTimeMedium,
    3: marksTimeHigh
  },
  timeMax: {
    1: 240,
    2: 40,
    3: 160
  },
  timeStep: {
    1: 5,
    2: 1,
    3: 1
  },
  priceMax: {
    1: 4000,
    2: 20000,
    3: 100000
  },
  priceStep: {
    1: 10,
    2: 100,
    3: 1000
  },
}
