import {
  SETTINGS_GET_ACTION_REQUESTED,
  SETTINGS_GET_ACTION_BEGIN,
  SETTINGS_GET_ACTION_SUCCEEDED,
  SETTINGS_GET_ACTION_FAILED,
  SETTINGS_CHANGE_PASSWORD_ACTION_REQUESTED,
  SETTINGS_CHANGE_PASSWORD_ACTION_BEGIN,
  SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED,
  SETTINGS_CHANGE_PASSWORD_ACTION_FAILED,
  SETTINGS_CHANGE_EMAIL_ACTION_REQUESTED,
  SETTINGS_CHANGE_EMAIL_ACTION_BEGIN,
  SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED,
  SETTINGS_CHANGE_EMAIL_ACTION_FAILED,
  LOGIN_INFO_ACTION_SUCCEEDED,
  LOGIN_INFO_ACTION_FAILED
} from "../constants/actions";

import {put, takeEvery, select} from "redux-saga/effects";
import settingsService from "../features/settings/settingsService";
import authService from "../features/auth/authService";


function* changePassword(action) {
  const busy = yield select((state) => state.settings.busyChangingPassword)
  if (!busy) {
    yield put({type: SETTINGS_CHANGE_PASSWORD_ACTION_BEGIN})
    const {current_password, plainPassword1, plainPassword2} = action.payload
    const settingsResponse = yield settingsService.changePassword(current_password, plainPassword1, plainPassword2)
    if (settingsResponse === true) {
      yield put({type: SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED})
    } else {
      yield put({type: SETTINGS_CHANGE_PASSWORD_ACTION_FAILED, payload: settingsResponse})
    }
  }
}

function* changeEmail(action) {
  const busy = yield select((state) => state.settings.busyChangingEmail)
  if (!busy) {
    yield put({type: SETTINGS_CHANGE_EMAIL_ACTION_BEGIN})
    const {current_password, newEmail} = action.payload
    const settingsResponse = yield settingsService.changeEmail(current_password, newEmail)
    if (typeof settingsResponse === 'string' || settingsResponse instanceof String) {
      const loginInfoResponse = yield authService.loginInfo()
      if (loginInfoResponse) {
        yield put({type: LOGIN_INFO_ACTION_SUCCEEDED, payload: loginInfoResponse})
      } else {
        yield put({type: LOGIN_INFO_ACTION_FAILED, payload: {default: 'Wystąpił nieoczekiwany błąd'}})
      }
      yield put({type: SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED, payload: settingsResponse})
    } else {
      yield put({type: SETTINGS_CHANGE_EMAIL_ACTION_FAILED, payload: settingsResponse})
    }
  }
}

function* getSettings() {
  const busy = yield select((state) => state.settings.busy)
  if (!busy) {
    yield put({type: SETTINGS_GET_ACTION_BEGIN})
    const settingsResponse = yield settingsService.getSettings()
    if (settingsResponse.user !== undefined) {
      yield put({type: SETTINGS_GET_ACTION_SUCCEEDED, payload: settingsResponse})
    } else {
      yield put({type: SETTINGS_GET_ACTION_FAILED, payload: settingsResponse})
    }
  }
}

export default function* settingsSaga() {
  yield takeEvery(SETTINGS_CHANGE_PASSWORD_ACTION_REQUESTED, changePassword)
  yield takeEvery(SETTINGS_CHANGE_EMAIL_ACTION_REQUESTED, changeEmail)
  yield takeEvery(SETTINGS_GET_ACTION_REQUESTED, getSettings)
}
