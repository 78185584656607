/* eslint-disable */
import React, {useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
// import {register, reset} from '../../features/auth/authSlice'
import {BsFillArrowLeftCircleFill} from 'react-icons/bs'

import {ContainerInput} from '../common/containerInput'
import {Link} from "react-router-dom"
// import { device  } from '../../utils/breakpoints'
import {useForm} from "react-hook-form"
import {Controller} from "react-hook-form"

import styled from 'styled-components'

import logo from '../../images/logo-dents-play.png'
import {Button} from '../common/button'
import {Heading} from '../common/heading'
import {device} from '../../utils/breakpoints'

import TextField from '@mui/material/TextField'

import {authActionCreators} from '../../actions/authActionCreators'

import {useParams } from 'react-router-dom';


const ContainerBackToLogin = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 1rem;
  align-items: center;
  justify-content: start;

`


const ContainerLoginSide = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  gap: 3rem;
  justify-items: start;
  padding: 3rem 2rem;
  max-width: 800px;
  z-index: 10;

  @media ${device.tablet} {
    padding: 2rem 3rem;
  }

  @media ${device.laptop} {
    padding: 1rem 4rem;
    gap: 1rem;
  }

  .css-19hdqbp-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px !important;
  }
`

const ContainerLogo = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  img {
    width: 117px;
  }
`

const ContainerLogin = styled.div`
  width: 100%;
  display: grid;
  align-content: start;
  gap: 3rem;

`

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 2rem;
`

const Form = styled.form`
  display: grid;
  grid-template-rows: auto;
  gap: 1.8rem;

`

const ContainerTextFields = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  gap: 1.8rem;
  position: relative;

`

const ContainerStyledTextField = styled.div`
  display: grid;
  position: relative;


  span {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.lightBlack};
    font-weight: 700;
    font-size: ${({theme}) => theme.fonts.size.xxs};
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-u9osun.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }
`


const ContainerRegulations = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  align-items: center;
  font-size: ${({theme}) => theme.fonts.size.xs};

  /* .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked {
      color: red;
  } */

  .css-j204z7-MuiFormControlLabel-root {
    font-family: 'Open Sans' !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-family: 'Open Sans' !important;
    font-size: ${({theme}) => theme.fonts.size.xs};
  }

  .css-j204z7-MuiFormControlLabel-root {
    margin-right: 0px;
  }

  span {
    line-height: 1.3;
  }

  div {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5rem;
    justify-content: start;
  }

  .css-8je8zh-MuiTouchRipple-root {
    display: none;
  }

  .css-19hdqbp-MuiButtonBase-root-MuiCheckbox-root:hover {
    background-color: transparent;
  }


`



const ContainerButton = styled.div`


`

const StyledButton = styled(Button)`
  width: 100%;
  background: ${({theme}) => theme.colors.red};
  color: ${({theme}) => theme.colors.white};
  border: 1px solid #D22416;
  font-weight: 700;
  font-size: ${({theme}) => theme.fonts.size.s};
`






const ContainerForgetPassword = styled.div `
  display: grid;
  justify-items: end;

  span {
    font-weight: 700;
    cursor: pointer;
    font-size: ${({theme}) => theme.fonts.size.xs};
  }

`

//message

const MessageInput = styled.p`
  position: absolute;
  bottom: -20px;
  font-size: ${({theme}) => theme.fonts.size.xxs};
  color: ${({theme}) => theme.colors.red};
`


const {resetPasswordAction} = authActionCreators

export const ResetForgetPasswordSide = () => {

  const {token} = useParams();

  const auth = useSelector(state => state.auth)
  const errorsResetPassword = auth.errorsResetPassword

  const {control, handleSubmit, watch, reset, formState: {errors}} = useForm({
    defaultValues: {
      plainPassword1: '',
      plainPassword2: '',
    }
  })

  const dispatch = useDispatch()

  const onSubmit = ({plainPassword1, plainPassword2}) => {
    dispatch(resetPasswordAction({
      first: plainPassword1,
      second: plainPassword2
    }, token))

    reset({
      plainPassword1: "",
      plainPassword2: ""
    });
  }

  return (
    <ContainerLoginSide>
      <ContainerLogo>
        <img src={logo} title="Logo" alt="Logo"/>
      </ContainerLogo>

      <ContainerLogin>
        <Link to='/'>
          <ContainerBackToLogin>
            <BsFillArrowLeftCircleFill color='#D22416' size="25" />
            <span>Wróć do logowania</span>
          </ContainerBackToLogin>
        </Link>
        <Heading h1 as='h1'>Resetowanie Hasła</Heading>
        <ContainerForm>
        <Form
        onSubmit={handleSubmit(onSubmit)}>
        <ContainerTextFields>
        <ContainerInput>
            <span>Wpisz nowe hasło</span>
            <Controller
            id="newPassword"
            name="plainPassword1"
            control={control}
            text="password"
            rules={{
                required: 'To pole jest wymagane.',
            }}
            render={({field: {onChange, value}}) =>
                <TextField
                value={value}
                autoComplete="section-changePassword new-password"
                onChange={onChange}
                label="Wpisz hasło"
                type='password'
                />
            }
            />
            <MessageInput>{errors.plainPassword1?.message}</MessageInput>
        </ContainerInput>
        <ContainerInput>
        <span>Powtórz nowe hasło</span>

        <Controller
            id="newPassword2"
            name="plainPassword2"
            control={control}
            text="password"
            rules={{
            required: 'To pole jest wymagane.',
            validate: (value) => {
                if (watch('plainPassword1') !== value) {
                return "Twoje hasła się nie zgadzają."
                }
            },
            }}
            render={({field: {onChange, value}}) =>
            <TextField
                value={value}
                autoComplete="section-changePassword new-password"
                onChange={onChange}
                label="Powtórz hasło"
                type='password'
            />
            }
        />
        <MessageInput>{errors.plainPassword2?.message}</MessageInput>
        </ContainerInput>
        </ContainerTextFields>

        <ContainerButton>
        <StyledButton>Resetuj hasło</StyledButton>
        </ContainerButton>
        </Form>
        </ContainerForm>
      </ContainerLogin>
    </ContainerLoginSide>
  )
}







