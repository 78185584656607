/* eslint-disable */
import React, {useRef, useState} from 'react'
import styled from 'styled-components'

import {MdOutlineArrowDropDown} from 'react-icons/md'
import {AiFillPlusCircle, AiFillMinusCircle} from 'react-icons/ai'
import {formatPrice} from "../../../utils/numberUtils";
import * as EVENTS from "events";
import {useOutsideAlerter} from "../../../utils/useOutsideAlerter";
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox';
import {TextField} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";

const StyledContainerInput = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 1rem;
  position: relative;
  z-index: 2;
  max-width: 600px;
  min-width: 350px;

`

const StyledLabel = styled.label`
  position: relative;

  .select_icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .icon {
    transform: rotate(180deg);
  }

  .input {
    border-color: #469546;
    border-width: 2px
  }



`

const StyledInput = styled.input`
  height: 58px;
  width: 100%;
  padding: 16.5px 14px;
  padding-right: 3rem;
  border: 1px solid;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  font-size: ${({ theme }) => theme.fonts.size.s};

  :hover {
    border-color: #000;
  }

  :focus {
    border-color: #469546;
  }
`

const ContainerSelectList = styled.div`
  position: absolute;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  top: 57px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #fff;
`
const SelectList = styled.ul`
  display: grid;
  gap: 1rem;
  height: 100%;
  width: 100%;
  grid-template-rows: auto;
  padding-top: 8px;
  padding-bottom: 8px;
`

const Select = styled.li`
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: auto 120px;
  padding: 0rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  user-select: none;
`
const ContainerLeftSide = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, auto);
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  justify-content: start;

`

const ContainerRightSide = styled.div`
  display: grid;


`


const Text = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.xs};

`
const Price = styled.div`
  font-size: ${({ theme }) => theme.fonts.size.xs};  
  white-space: nowrap;
`

const ContainerCountIcons = styled.div`
  display: grid;
  gap: 0.3rem;
  grid-template-columns: repeat(3, auto);
  align-items: center;
`

// const CountValueLabel = styled.label`
//
// `

const CountValue = styled.input`
  max-width: 70px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid black;

`
const StyledTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    fontSize:'.8rem'
  },
  '& .MuiTypography-root': {
    fontSize:'.8rem'
  }
})

const toFixed = value => {
  const f = Number(value)
  return f ? f.toFixed(2) : ''
}

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

export const SelectCostVariables = ({value, onChange, costTypes, name, disabledCalculator}) => {
  const [opened, setOpened] = useState(false)
  const [searchValue, setSearchValue] = useState('')


  const onOpen = () => {
    setOpened(true)
    setSearchValue('')
  }
  const onClose = () => {
    setOpened(false)
  }
  const closeArrow = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setOpened(!opened)
  }
  const getCostType = id => {
    for (let i = 0; i < costTypes.length; i++) {
      let costType = costTypes[i]
      if (costType.id == id) return costType
    }
    return false
  }
  const parseDisplayValue = () => {
    let displayValue = ''
    for (const [id, priceValue] of Object.entries(value)) {
      const costType = getCostType(id)
      let name = 'ID' + id
      let price = ''
      if (costType !== false) {
        name = costType.name
        price = ' (' + formatPrice(priceValue, true) + ' zł)'
      }
      displayValue += name + price + ' '
    }
    return displayValue
  }
  const inputValue = opened ? searchValue : parseDisplayValue()
  const onInputChange = (e) => {
    if (opened) {
      setSearchValue(e.target.value)
    }
  }
  const isCostTypeIncluded = id => {
    return value[id] !== undefined
  }
  const toggleCostTypeIncluded = id => {
    let newValue = value
    if (isCostTypeIncluded(id)) {
      delete newValue[id]
    } else {
      newValue[id] = ''
    }
    onChange({
      target: {value: newValue, name},
      type: EVENTS.CHANGE
    })
  }
  const getCostTypePrice = id => {
    return value[id]
  }
  const setCostTypePrice = (id, priceValue) => {
    let newValue = value
    if (priceValue >= 0) {
      newValue[id] = parseFloat(priceValue)
    }
    onChange({
      target: {value: newValue, name},
      type: EVENTS.CHANGE
    })
  }
  let costTypeRows = []
  for (let i = 0; i < costTypes.length; i++) {
    let costType = costTypes[i]
    let show = false
    if (searchValue.length === 0) {
      show = true
    } else if (value[costType.id] > 0) {
      show = true
    } else if (costType.name.toLowerCase().startsWith(searchValue.toLowerCase())) {
      show = true
    }
    if (show) {
      const priceValue = getCostTypePrice(costType.id)
      costTypeRows.push(
        <Select key={costType.id}>
          <ContainerLeftSide>
            <Checkbox
              {...label}
              checked={isCostTypeIncluded(costType.id)}
              onChange={e => toggleCostTypeIncluded(costType.id)}
              sx={{
                color: '#469546',
                '&.Mui-checked': {
                  color: '#469546',
                },
              }}
            />
            <Text>{costType.name}</Text>
          </ContainerLeftSide>
          <ContainerRightSide>
            <StyledTextField
              type='number'
              disabled={!isCostTypeIncluded(costType.id)}
              value={priceValue ?? ''}
              inputProps={{
                min: 0.01,
                step: 0.01
              }}
              onChange={e => setCostTypePrice(costType.id, e.target.value)}
              InputProps={{
                endAdornment: <InputAdornment position="end">zł</InputAdornment>,
              }}
              size="small"
            />
          </ContainerRightSide>
        </Select>
      )
    }
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, onClose)
  return (
    <StyledContainerInput
      onClick={!disabledCalculator && onOpen}
      ref={wrapperRef}>
      <StyledLabel htmlFor={name}>
        <StyledInput id={name}
          disabled={disabledCalculator}
          placeholder="Koszty przypisane"
          name={name}
          autoComplete='off'
          onChange={onInputChange}
          value={inputValue}
          className={opened && 'input' || disabledCalculator && 'disabled'}
        />
          <IconButton className='select_icon' onClick={!disabledCalculator && closeArrow}>
            <MdOutlineArrowDropDown size="22px" color='rgba(0, 0, 0, 0.54)' className={opened && 'icon'} />
          </IconButton>
        {opened && <ContainerSelectList>
          <SelectList>
            {costTypeRows}
          </SelectList>
        </ContainerSelectList>}
      </StyledLabel>
    </StyledContainerInput>
  )
}
