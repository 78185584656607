import {
  SERVICE_GET_ACTION_BEGIN,
  SERVICE_GET_ACTION_FAILED,
  SERVICE_GET_ACTION_REQUESTED,
  SERVICE_GET_ACTION_SUCCEEDED,
  SERVICE_ADD_ACTION_BEGIN,
  SERVICE_ADD_ACTION_FAILED,
  SERVICE_ADD_ACTION_REQUESTED,
  SERVICE_ADD_ACTION_SUCCEEDED,
  SERVICE_REMOVE_ACTION_BEGIN,
  SERVICE_REMOVE_ACTION_FAILED,
  SERVICE_REMOVE_ACTION_REQUESTED,
  SERVICE_REMOVE_ACTION_SUCCEEDED,
  SERVICE_UPDATE_ACTION_BEGIN,
  SERVICE_UPDATE_ACTION_FAILED,
  SERVICE_UPDATE_ACTION_REQUESTED,
  SERVICE_UPDATE_ACTION_SUCCEEDED,
  SERVICE_SAVE_VALUE_ACTION_REQUESTED,
  SERVICE_SAVE_VALUE_ACTION_BEGIN,
  SERVICE_SAVE_VALUE_ACTION_SUCCEEDED,
  SERVICE_SAVE_VALUE_ACTION_FAILED,
  SERVICE_UPLOAD_ACTION_REQUESTED,
  SERVICE_UPLOAD_ACTION_BEGIN,
  SERVICE_UPLOAD_ACTION_SUCCEEDED, SERVICE_UPLOAD_ACTION_FAILED
} from "../constants/actions";
import serviceService from "../features/service/serviceService";
import {put, takeEvery, select} from "redux-saga/effects";
import {unexpectedError} from "../features/client";

function* addService(action) {
  const busy = yield select((state) => state.service.busyAdding)
  if (!busy) {
    yield put({type: SERVICE_ADD_ACTION_BEGIN})
    const serviceResponse = yield serviceService.addService(action.payload)
    if (serviceResponse.id !== undefined) {
      yield put({type: SERVICE_ADD_ACTION_SUCCEEDED, payload: serviceResponse})
    } else {
      yield put({type: SERVICE_ADD_ACTION_FAILED, payload: serviceResponse})
    }
  }
}

function* uploadService(action) {
  const busy = yield select((state) => state.service.busyAdding)
  if (!busy) {
    yield put({type: SERVICE_UPLOAD_ACTION_BEGIN})
    const serviceResponse = yield serviceService.uploadService(action.payload)
    if (serviceResponse.success !== undefined) {
      yield put({type: SERVICE_UPLOAD_ACTION_SUCCEEDED, payload: serviceResponse})
    } else {
      yield put({type: SERVICE_UPLOAD_ACTION_FAILED, payload: serviceResponse})
    }
  }
}

function* updateService(action) {
  const busy = yield select((state) => state.service.busyUpdating)
  if (!busy) {
    yield put({type: SERVICE_UPDATE_ACTION_BEGIN})
    const serviceResponse = yield serviceService.updateService(action.payload)
    if (serviceResponse.id !== undefined) {
      yield put({type: SERVICE_UPDATE_ACTION_SUCCEEDED, payload: serviceResponse})
    } else {
      yield put({type: SERVICE_UPDATE_ACTION_FAILED, payload: serviceResponse})
    }
  }
}

function* saveValueService(action) {
  const service = action.payload
  const busy = yield select((state) => state.service.busyServiceValues.includes(service.id))
  console.log("Asd");
  if (!busy) {
    yield put({type: SERVICE_SAVE_VALUE_ACTION_BEGIN, payload: {service}})
    const serviceResponse = yield serviceService.updateService(service)
    if (serviceResponse.id !== undefined) {
      yield put({type: SERVICE_SAVE_VALUE_ACTION_SUCCEEDED, payload: serviceResponse})
    } else {
      yield put({
        type: SERVICE_SAVE_VALUE_ACTION_FAILED,
        payload: {
          id: service.id,
          errors: (serviceResponse.value ?? serviceResponse.default) ?? unexpectedError.default
        }
      })
    }
  }
}

function* removeService(action) {
  const busy = yield select((state) => state.service.busyRemoving)
  if (!busy) {
    yield put({type: SERVICE_REMOVE_ACTION_BEGIN})
    const serviceResponse = yield serviceService.removeService(action.payload)
    if (serviceResponse === true) {
      yield put({type: SERVICE_REMOVE_ACTION_SUCCEEDED, payload: action.payload})
    } else {
      yield put({type: SERVICE_REMOVE_ACTION_FAILED, payload: serviceResponse})
    }
  }
}

function* getServices(action) {
  const busy = yield select((state) => state.service.busy)
  if (!busy) {
    yield put({type: SERVICE_GET_ACTION_BEGIN})
    const serviceResponse = yield serviceService.getServices(action.payload.month)
    if (serviceResponse.length !== undefined) {
      yield put({type: SERVICE_GET_ACTION_SUCCEEDED, payload: serviceResponse})
    } else {
      yield put({type: SERVICE_GET_ACTION_FAILED, payload: serviceResponse})
    }
  }
}

export default function* serviceSaga() {
  yield takeEvery(SERVICE_ADD_ACTION_REQUESTED, addService)
  yield takeEvery(SERVICE_UPLOAD_ACTION_REQUESTED, uploadService)
  yield takeEvery(SERVICE_UPDATE_ACTION_REQUESTED, updateService)
  yield takeEvery(SERVICE_SAVE_VALUE_ACTION_REQUESTED, saveValueService)
  yield takeEvery(SERVICE_REMOVE_ACTION_REQUESTED, removeService)
  yield takeEvery(SERVICE_GET_ACTION_REQUESTED, getServices)
}
