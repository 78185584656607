import {
  COST_GROUP_GET_ACTION_BEGIN,
  COST_GROUP_GET_ACTION_SUCCEEDED,
  COST_GROUP_GET_ACTION_FAILED,
  COST_GROUP_ADD_ACTION_BEGIN,
  COST_GROUP_ADD_ACTION_SUCCEEDED,
  COST_GROUP_ADD_ACTION_FAILED,
  COST_GROUP_REMOVE_ACTION_BEGIN,
  COST_GROUP_REMOVE_ACTION_SUCCEEDED,
  COST_GROUP_REMOVE_ACTION_FAILED,
  COST_GROUP_UPDATE_ACTION_BEGIN,
  COST_GROUP_UPDATE_ACTION_SUCCEEDED,
  COST_GROUP_UPDATE_ACTION_FAILED,

  COST_GROUP_HIDE_ADDING_MODAL_ACTION,
  COST_GROUP_HIDE_EDITING_MODAL_ACTION,

  COST_GROUP_SHOW_ADDING_MODAL_ACTION,
  COST_GROUP_SHOW_EDITING_MODAL_ACTION,

  COST_GROUP_UPDATE_ADDING_MODAL_ACTION,
  COST_GROUP_UPDATE_EDITING_MODAL_ACTION
} from "../constants/actions"

const initialState = {
  costGroups: [],
  busy: false,
  busyAdding: false,
  busyUpdating: false,
  busyRemoving: false,
  addingCostGroupModal: false,
  addingCostGroup: {name: ''},
  editedCostGroup: null,
  loggedIn: false,
  errors: {},
  errorsAdding: {},
  errorsUpdating: {},
  errorsRemoving: {},
}

export const costGroupReducer = (state = initialState, action) => {
  let costGroups = state.costGroups
  switch (action.type) {
    case COST_GROUP_GET_ACTION_BEGIN:
      return {
        ...state,
        busy: true,
        errors: {},
      }
    case COST_GROUP_ADD_ACTION_BEGIN:
      return {
        ...state,
        busyAdding: true,
        errorsAdding: {},
      }
    case COST_GROUP_REMOVE_ACTION_BEGIN:
      return {
        ...state,
        busyRemoving: true,
        errorsRemoving: {},
      }
    case COST_GROUP_UPDATE_ACTION_BEGIN:
      return {
        ...state,
        busyUpdating: true,
        errorsUpdating: {},
      }
    case COST_GROUP_GET_ACTION_FAILED:
      return {
        ...state,
        busy: false,
        errors: action.payload,
      }
    case COST_GROUP_ADD_ACTION_FAILED:
      return {
        ...state,
        busyAdding: false,
        errorsAdding: action.payload,
      }
    case COST_GROUP_REMOVE_ACTION_FAILED:
      return {
        ...state,
        busyRemoving: false,
        errorsRemoving: action.payload,
      }
    case COST_GROUP_UPDATE_ACTION_FAILED:
      return {
        ...state,
        busyUpdating: false,
        errorsUpdating: action.payload,
      }

    case COST_GROUP_ADD_ACTION_SUCCEEDED:
      return {
        ...state,
        busyAdding: false,
        addingCostGroupModal: false,
        costGroups: [...costGroups, action.payload]
      }
    case COST_GROUP_REMOVE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyRemoving: false,
        costGroups: costGroups.filter(costGroup => costGroup.id !== action.payload)
      }
    case COST_GROUP_UPDATE_ACTION_SUCCEEDED:
        costGroups = costGroups.filter(costGroup => costGroup.id !== action.payload.id)
      return {
        ...state,
        busyUpdating: false,
        costGroups: [...costGroups, action.payload],
        editedCostGroup: null,
      }
    case COST_GROUP_GET_ACTION_SUCCEEDED:
      return {
        ...state,
        busy: false,
        costGroups: action.payload
      }
    case COST_GROUP_SHOW_ADDING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        addingCostGroupModal: true,
        addingCostGroup: initialState.addingCostGroup,
      }
    case COST_GROUP_HIDE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingCostGroupModal: false,
      }
    case COST_GROUP_UPDATE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingCostGroup: action.payload,
      }
    case COST_GROUP_SHOW_EDITING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        editedCostGroup: action.payload,
      }
    case COST_GROUP_UPDATE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedCostGroup: action.payload,
      }
    case COST_GROUP_HIDE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedCostGroup: null,
      }
    default:
      return state;
  }
}
