import {
  COST_TYPE_ADD_ACTION_BEGIN,
  COST_TYPE_ADD_ACTION_FAILED,
  COST_TYPE_ADD_ACTION_SUCCEEDED,
  COST_TYPE_GET_ACTION_BEGIN,
  COST_TYPE_GET_ACTION_FAILED,
  COST_TYPE_GET_ACTION_SUCCEEDED,
  COST_TYPE_HIDE_ADDING_MODAL_ACTION,
  COST_TYPE_HIDE_EDITING_MODAL_ACTION,
  COST_TYPE_REMOVE_ACTION_BEGIN,
  COST_TYPE_REMOVE_ACTION_FAILED,
  COST_TYPE_REMOVE_ACTION_SUCCEEDED,
  COST_TYPE_SAVE_VALUE_ACTION_BEGIN,
  COST_TYPE_SAVE_VALUE_ACTION_FAILED,
  COST_TYPE_SAVE_VALUE_ACTION_SUCCEEDED,
  COST_TYPE_SHOW_ADDING_MODAL_ACTION,
  COST_TYPE_SHOW_EDITING_MODAL_ACTION,
  COST_TYPE_UPDATE_ACTION_BEGIN,
  COST_TYPE_UPDATE_ACTION_FAILED,
  COST_TYPE_UPDATE_ACTION_SUCCEEDED,
  COST_TYPE_UPDATE_ADDING_MODAL_ACTION,
  COST_TYPE_UPDATE_EDITING_MODAL_ACTION,
  COST_TYPE_UPDATE_MONTH_ACTION,
  COST_TYPE_UPDATE_VALUE_ACTION
} from "../constants/actions";
import {UNASSIGNED_COST_TYPE} from "../constants/costTypeDict";
import moment from "moment";
import {apiMonthFormat} from "../features/client";

const initialState = {
  month: moment().format(apiMonthFormat),
  costTypes: [],
  costTypeValues: {},
  editedCostTypeValues: [],
  busyCostTypeValues: [],
  errorsCostTypeValues: {},
  initialized: false,
  busy: false,
  busyAdding: false,
  busyUpdating: false,
  busyRemoving: false,
  addingCostModal: false,
  addingCost: {name: '', type: UNASSIGNED_COST_TYPE, costGroup: ''},
  editedCost: null,
  errors: {},
  errorsAdding: {},
  errorsUpdating: {},
  errorsRemoving: {},
}

export const costTypeReducer = (state = initialState, action) => {
  let initialized = state.initialized
  let costTypes = state.costTypes
  let costTypeValues = {...state.costTypeValues}
  let errorsCostTypeValues = {...state.errorsCostTypeValues}
  let editedCostTypeValues = [...state.editedCostTypeValues]
  let busyCostTypeValues = [...state.busyCostTypeValues]
  switch (action.type) {
    case COST_TYPE_GET_ACTION_BEGIN:
      return {
        ...state,
        busy: true,
        errors: {},
      }
    case COST_TYPE_ADD_ACTION_BEGIN:
      return {
        ...state,
        busyAdding: true,
        errorsAdding: {},
      }
    case COST_TYPE_REMOVE_ACTION_BEGIN:
      return {
        ...state,
        busyRemoving: true,
        errorsRemoving: {},
      }
    case COST_TYPE_UPDATE_ACTION_BEGIN:
      return {
        ...state,
        busyUpdating: true,
        errorsUpdating: {},
      }
    case COST_TYPE_GET_ACTION_FAILED:
      return {
        ...state,
        busy: false,
        errors: action.payload,
      }
    case COST_TYPE_ADD_ACTION_FAILED:
      return {
        ...state,
        busyAdding: false,
        errorsAdding: action.payload,
      }
    case COST_TYPE_REMOVE_ACTION_FAILED:
      return {
        ...state,
        busyRemoving: false,
        errorsRemoving: action.payload,
      }
    case COST_TYPE_UPDATE_ACTION_FAILED:
      return {
        ...state,
        busyUpdating: false,
        errorsUpdating: action.payload,
      }
    case COST_TYPE_ADD_ACTION_SUCCEEDED:
      return {
        ...state,
        busyAdding: false,
        addingCostModal: false,
        costTypes: [...costTypes, action.payload]
      }
    case COST_TYPE_REMOVE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyRemoving: false,
        costTypes: costTypes.filter(costType => costType.id !== action.payload)
      }
    case COST_TYPE_UPDATE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyUpdating: false,
        costTypes: costTypes.map(c => {
          if (c.id === action.payload.id) {
            return action.payload
          }
          return c
        }),
        editedCost: null,
      }
    case COST_TYPE_GET_ACTION_SUCCEEDED:
      if (!initialized) {
        for (let i = 0; i < action.payload.items.length; i++) {
          const costType = action.payload.items[i]
          costTypeValues[costType.id] = costType.cost ? parseFloat(costType.cost.customValue) : ''
        }
        if(action.payload.month) {
          initialized = true
        }
      }
      editedCostTypeValues = []
      return {
        ...state,
        busy: false,
        initialized,
        costTypes: action.payload.items,
        costTypeValues,
        editedCostTypeValues
      }
    case COST_TYPE_SHOW_ADDING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        addingCostModal: true,
        addingCost: {...initialState.addingCost, type: action.payload},
      }
    case COST_TYPE_HIDE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingCostModal: false,
      }
    case COST_TYPE_UPDATE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingCost: action.payload,
      }
    case COST_TYPE_SHOW_EDITING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        editedCost: action.payload,
      }
    case COST_TYPE_UPDATE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedCost: action.payload,
      }
    case COST_TYPE_HIDE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedCost: null,
      }
    case COST_TYPE_UPDATE_VALUE_ACTION:
      costTypeValues[action.payload.id] = action.payload.value
      if(!editedCostTypeValues.includes(action.payload.id)) {
        editedCostTypeValues.push(action.payload.id)
      }
      return {
        ...state,
        costTypeValues,
        editedCostTypeValues
      }
    case COST_TYPE_UPDATE_MONTH_ACTION:
      return {
        ...state,
        month: action.payload,
        initialized: false,
      }
    case COST_TYPE_SAVE_VALUE_ACTION_BEGIN:
      if(!busyCostTypeValues.includes(action.payload.costType.id)) {
        busyCostTypeValues.push(action.payload.costType.id)
      }
      delete errorsCostTypeValues[action.payload.costType.id]
      return {
        ...state,
        busyCostTypeValues,
        errorsCostTypeValues
      }
    case COST_TYPE_SAVE_VALUE_ACTION_FAILED:
      errorsCostTypeValues[action.payload.id] = action.payload.errors
      return {
        ...state,
        busyCostTypeValues: busyCostTypeValues.filter(id => id !== action.payload.id),
        errorsCostTypeValues
      }
    case COST_TYPE_SAVE_VALUE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyCostTypeValues: busyCostTypeValues.filter(id => id !== action.payload.id),
        editedCostTypeValues: editedCostTypeValues.filter(id => id !== action.payload.id),
      }
    default:
      return state;
  }
}
