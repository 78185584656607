import {
  BRANCH_GET_ACTION_BEGIN,
  BRANCH_GET_ACTION_SUCCEEDED,
  BRANCH_GET_ACTION_FAILED,
  BRANCH_ADD_ACTION_BEGIN,
  BRANCH_ADD_ACTION_SUCCEEDED,
  BRANCH_ADD_ACTION_FAILED,
  BRANCH_REMOVE_ACTION_BEGIN,
  BRANCH_REMOVE_ACTION_SUCCEEDED,
  BRANCH_REMOVE_ACTION_FAILED,
  BRANCH_UPDATE_ACTION_BEGIN,
  BRANCH_UPDATE_ACTION_SUCCEEDED,
  BRANCH_UPDATE_ACTION_FAILED,

  BRANCH_HIDE_ADDING_MODAL_ACTION,
  BRANCH_HIDE_EDITING_MODAL_ACTION,

  BRANCH_SHOW_ADDING_MODAL_ACTION,
  BRANCH_SHOW_EDITING_MODAL_ACTION,

  BRANCH_UPDATE_ADDING_MODAL_ACTION,
  BRANCH_UPDATE_EDITING_MODAL_ACTION
} from "../constants/actions"

const initialState = {
  branches: [],
  busy: false,
  busyAdding: false,
  busyUpdating: false,
  busyRemoving: false,
  addingBranchModal: false,
  addingBranch: {name: ''},
  editedBranch: null,
  loggedIn: false,
  errors: {},
  errorsAdding: {},
  errorsUpdating: {},
  errorsRemoving: {},
}

export const branchReducer = (state = initialState, action) => {
  let branches = state.branches
  switch (action.type) {
    case BRANCH_GET_ACTION_BEGIN:
      return {
        ...state,
        busy: true,
        errors: {},
      }
    case BRANCH_ADD_ACTION_BEGIN:
      return {
        ...state,
        busyAdding: true,
        errorsAdding: {},
      }
    case BRANCH_REMOVE_ACTION_BEGIN:
      return {
        ...state,
        busyRemoving: true,
        errorsRemoving: {},
      }
    case BRANCH_UPDATE_ACTION_BEGIN:
      return {
        ...state,
        busyUpdating: true,
        errorsUpdating: {},
      }
    case BRANCH_GET_ACTION_FAILED:
      return {
        ...state,
        busy: false,
        errors: action.payload,
      }
    case BRANCH_ADD_ACTION_FAILED:
      return {
        ...state,
        busyAdding: false,
        errorsAdding: action.payload,
      }
    case BRANCH_REMOVE_ACTION_FAILED:
      return {
        ...state,
        busyRemoving: false,
        errorsRemoving: action.payload,
      }
    case BRANCH_UPDATE_ACTION_FAILED:
      return {
        ...state,
        busyUpdating: false,
        errorsUpdating: action.payload,
      }

    case BRANCH_ADD_ACTION_SUCCEEDED:
      return {
        ...state,
        busyAdding: false,
        addingBranchModal: false,
        branches: [...branches, action.payload]
      }
    case BRANCH_REMOVE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyRemoving: false,
        branches: branches.filter(branch => branch.id !== action.payload)
      }
    case BRANCH_UPDATE_ACTION_SUCCEEDED:
        branches = branches.filter(branch => branch.id !== action.payload.id)
      return {
        ...state,
        busyUpdating: false,
        branches: [...branches, action.payload],
        editedBranch: null,
      }
    case BRANCH_GET_ACTION_SUCCEEDED:
      return {
        ...state,
        busy: false,
        branches: action.payload
      }
    case BRANCH_SHOW_ADDING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        addingBranchModal: true,
        addingBranch: initialState.addingBranch,
      }
    case BRANCH_HIDE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingBranchModal: false,
      }
    case BRANCH_UPDATE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingBranch: action.payload,
      }
    case BRANCH_SHOW_EDITING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        editedBranch: action.payload,
      }
    case BRANCH_UPDATE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedBranch: action.payload,
      }
    case BRANCH_HIDE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedBranch: null,
      }
    default:
      return state;
  }
}
