/* eslint-disable */
import React, {useEffect} from 'react'
import {Loader} from '../common/loader'

import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'
import {useDispatch, useSelector} from 'react-redux'

import {AssignedCosts} from './assigned-costs'
import {ASSIGNED_COST_TYPE, UNASSIGNED_COST_TYPE} from "../../constants/costTypeDict"
import {AssignedModalCosts} from "../common/modal/assigned-modal-costs";
import {MdOutlineAddCircleOutline} from "react-icons/md"
import {costTypeActionCreators} from "../../actions/costTypeActionCreators";

import {CheckCosts} from './check-costs'
import moment from "moment";
import {apiDateFormat} from "../../features/client";
import {costGroupActionCreators} from "../../actions/costGroupActionCreators";
import {InstructionLink} from "../common/instructionLink";


const {
  addCostTypeAction,
  updateCostTypeAction,
  hideAddingCostModalAction,
  updateAddingCostModalAction,
  hideEditingCostModalAction,
  updateEditingCostModalAction
} = costTypeActionCreators


const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }
`

const ContainerCosts = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;

  @media ${device.laptop} {
    grid-template-columns: auto;
  }

  @media ${device.laptopXl} {
    grid-template-columns: repeat(2, 1fr);
  }
`

const ContainerHeading = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0%   {transform: scale(.7);}
    50%  {transform: scale(1);}
    100% {transform: scale(.7);}
  }
`


const {
  getCostTypesAction,
  updateCostTypeValueAction,
  updateCostTypeMonthAction
} = costTypeActionCreators

const {
  getCostGroupsAction,
} = costGroupActionCreators

export const Costs = () => {


  const {
    errors,
    errorsRemoving,
    errorsAdding,
    errorsUpdating,
    addingCostModal,
    addingCost,
    editedCost,
    costTypes,
    busy,
    busyAdding,
    busyUpdating,
    busyRemoving,
    costTypeValues,
    month,
    editedCostTypeValues,
    busyCostTypeValues,
    errorsCostTypeValues,
  } = useSelector(
    (state) => state.costType
  )
  const {
    costGroups
  } = useSelector(
    (state) => state.costGroup
  )
  const modalAddTitles = {
    ASSIGNED_COST_TYPE: "Dodaj koszt przypisany",
    UNASSIGNED_COST_TYPE: "Dodaj koszt nieprzypisany"
  }
  const modalEditTitles = {
    ASSIGNED_COST_TYPE: "Edytuj koszt przypisany",
    UNASSIGNED_COST_TYPE: "Edytuj koszt nieprzypisany"
  }
  const dispatch = useDispatch()
  const handleClose = () => dispatch(hideAddingCostModalAction())
  const reloadCosts = (value) => {
    dispatch(getCostTypesAction(moment(value ?? month).format(apiDateFormat)))
  }
  useEffect(() => {
    reloadCosts()
    dispatch(getCostGroupsAction())
  }, [])
  let unassignedCostsSummary = 0
  for (let i = 0; i < costTypes.length; i++) {
    const costType = costTypes[i]
    if(costType.type === UNASSIGNED_COST_TYPE) {
      unassignedCostsSummary += parseFloat(costTypeValues[costType.id] ? costTypeValues[costType.id] : 0)
    }
  }

  return (
    <StyledWrapper>

      <ContainerHeading>
        <Heading h1 as='h1'>Koszty</Heading>
        <InstructionLink link="https://dentsplay.pl/2022/10/09/zakladka-koszty/" tooltipText="Zobacz filmik instruktażowy." />
      </ContainerHeading>
      <Line/>
      <CheckCosts
        date={month}
        onChangeDate={val => dispatch(updateCostTypeMonthAction(val))}
        onAccept={reloadCosts}
        costs={unassignedCostsSummary}/>
      <ContainerCosts>
        <AssignedModalCosts
          costGroups={costGroups}
          showModal={addingCostModal}
          handleClose={handleClose}
          data={addingCost}
          setData={data => dispatch(updateAddingCostModalAction(data))}
          onSubmit={() => dispatch(addCostTypeAction(addingCost.name, addingCost.costGroup, addingCost.type))}
          errors={errorsAdding}
          title={addingCost ? modalAddTitles[addingCost.type] : null}
          icon={MdOutlineAddCircleOutline}
          submitLabel="Dodaj pozycję"
        />
        <AssignedModalCosts
          costGroups={costGroups}
          showModal={editedCost !== null}
          handleClose={() => dispatch(hideEditingCostModalAction())}
          data={editedCost}
          setData={data => dispatch(updateEditingCostModalAction(data))}
          onSubmit={() => dispatch(updateCostTypeAction(editedCost))}
          errors={errorsUpdating}
          title={editedCost ? modalEditTitles[editedCost.type] : null}
          icon={MdOutlineAddCircleOutline}
          submitLabel="Zapisz pozycję"
        />
        <AssignedCosts droppableId='droppable-1' type={UNASSIGNED_COST_TYPE} title="Koszty stałe"
                       month={month}
                       costTypes={costTypes}
                       costTypeValues={costTypeValues}
                       editedCostTypeValues={editedCostTypeValues}
                       busyCostTypeValues={busyCostTypeValues}
                       errorsCostTypeValues={errorsCostTypeValues}
                       changeCostTypeValue={(id, value) => dispatch(updateCostTypeValueAction(id, value))}/>
        <AssignedCosts droppableId='droppable-2' type={ASSIGNED_COST_TYPE} title="Koszty zmienne"
                       month={month}
                       costTypes={costTypes}
                       costTypeValues={costTypeValues}
                       editedCostTypeValues={editedCostTypeValues}
                       busyCostTypeValues={busyCostTypeValues}
                       errorsCostTypeValues={errorsCostTypeValues}
                       changeCostTypeValue={(id, value) => dispatch(updateCostTypeValueAction(id, value))}/>
      </ContainerCosts>
    </StyledWrapper>
  )
}

