import React from 'react'
import styled from 'styled-components'

// import { NavLink } from "react-router-dom"
import { device } from '../../../utils/breakpoints'
import logo from '../../../images/logo-dents-play.png'
import { SidebarLinks } from '../data/sidebar-links.js'
import { SidebarLink } from '../sidebar/sidebarLink.js'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {BiLogOut} from 'react-icons/bi'
import {useSelector, useDispatch} from 'react-redux'

import {authActionCreators} from "../../../actions/authActionCreators"

const ContainerNavigation = styled.div `
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100vh;
    background: ${({ theme }) => theme.colors.white};
    transform: translateX(${({ isOpen }) => (isOpen ? '0' : '-100%')});
    transition: all 0.3s ease;
    transition-property: opacity, transform;
    z-index: 3;
    overflow-y: auto;
    padding-bottom: 4rem;

    @media ${device.laptop} {
        display: none;
    }

    .active {
        background: rgb(210, 36, 22, 0.04);
        color: ${({ theme }) => theme.colors.red};
    }
`

const Container = styled.div `
    width: 100%;
    position: relative;
    padding: 1rem 1.5rem;
    height: 100px;
`

const ContainerProfile = styled.div `
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    justify-content: space-between;
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: 4px;
`

const ProfileInformations = styled.div `
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, auto);
    justify-content: start;

    div {
        display: grid;
        gap: 0.7rem;
        grid-template-rows: auto;
    }

    span {
        font-size: ${({ theme }) => theme.fonts.size.xxs};
        color: #6D6D6D;
        font-weight: 700;
    }
`


const LoggedPersonName = styled.div `
    font-size: ${({ theme }) => theme.fonts.size.xs};
    font-weight: 700;
`

const ProfileImage = styled.div `
    border-radius: 30px;
    width: 45px;
    height: 45px;
    position: relative;
    background: #fff url('/fi.png') no-repeat;
    background-size: contain;
    border: 1px solid #eee;

    .person-image {
        border-radius: 30px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

`


const SidebarTop = styled.div `
    display: grid;
    grid-template-columns: auto;
    gap: 2rem;
    align-content: start;

`

const Line = styled.div `
    width: 100%;
    height: 1px;
    border-top: 1px solid #F0F0F0;
`

const StyledSidebarMenu = styled.div `
    display: grid;
    grid-template-columns: auto;
    width: 100%;
`


// //Bottom
// const SidebarBottom = styled.div `
//     display: grid;
//     grid-template-columns: auto;
//     gap: 2rem;
//     align-content: start;
// `




// const ContainerLogout = styled.div`
//     display: grid;
//     width: 100%;
//     height: 100%;
//     justify-items: center;
//     padding: 1rem;
//     cursor: pointer;


  
//     span {
//         font-weight: 700;
//         font-size: ${({ theme }) => theme.fonts.size.xs};
//     }
// `

const MobileHeader = styled.div `
    
`

const LogoContainer = styled.div`
  display: grid;
  position: absolute;
  bottom: 50%;
  transform: translate(0, 50%);

  img {
    width: 100px;
  }


`



const {logoutAction} = authActionCreators

export const Navigation = ({isOpen}) => {

    const dispatch = useDispatch()
    const {loggedIn, user} = useSelector(state => state.auth)

    return (
        <ContainerNavigation isOpen={isOpen}>
        <Container>
        <MobileHeader>
            <LogoContainer>
                <img className='image' src={logo} title="Logo" alt="Logo"/>
            </LogoContainer>

        </MobileHeader>
        </Container>
        <SidebarTop>
        <ContainerProfile>
            <ProfileInformations>
                <ProfileImage>

                </ProfileImage>
                <div>
                    <span>ZALOGOWANY JAKO</span>
                    <LoggedPersonName>{user?.email}</LoggedPersonName>
                </div>
            </ProfileInformations>
            <Tooltip title="Wyloguj się" enterTouchDelay={0} >
            {loggedIn ? <IconButton onClick={() => dispatch(logoutAction())}><BiLogOut size="22" /></IconButton> : ''}
            </Tooltip>
        </ContainerProfile>
            <Line/>
            <StyledSidebarMenu>
                {SidebarLinks.map((item, index) => {
                    return (
                        <SidebarLink item={item} key={index}/>
                    )
                })}
            </StyledSidebarMenu>

            </SidebarTop>

        </ContainerNavigation>
    )
}


// <SidebarBottom>
// <Line/>
//     {loggedIn ?  <ContainerLogout><span onClick={() => dispatch(logoutAction())}>Wyloguj się</span></ContainerLogout> : ''}
// </SidebarBottom>