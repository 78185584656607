import axios from "axios";
import qs from "qs";

const API_URL = 'https://app.dentsplay.pl/backend/'
// const API_URL = 'http://localhost:8000/'

export async function apiRequest(method, path, params = {}) {
    return await axios.request({
        url: API_URL + path,
        withCredentials: true,
        method,
        data: method !== 'GET' ? params : null,
        params: method === 'GET' ? params : null,
        paramsSerializer: function (params) {
            return qs.stringify(params)
        },
    }).catch(function (error) {
        if (error.response) {
            return error.response
        }
        return unexpectedError
    })
}

export async function uploadFile(path, file = {}) {
    let formData = new FormData();
    console.log(file.file);
    formData.append("upload_excel_form", file.file);

    return await axios.post(API_URL + path, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
    }).catch(function (error) {
        if (error.response) {
            return error.response
        }
        return unexpectedError
    })
}

export const unexpectedError = {
    default: 'Wystąpił nieoczekiwany błąd'
}
export const apiDateFormat = 'YYYY-MM-DD'
export const apiMonthFormat = 'YYYY-MM-01'
export const apiTimeFormat = 'HH:mm'
export const apiDateTimeFormat = apiDateFormat + ' ' + apiTimeFormat
export const inputTimeFormat = 'HH:mm'
