import {useCallback} from "react";
import {useForm} from "react-hook-form";
import {useDeepCompareEffect} from "react-use"
import {deepEqual} from "./deepEqual";

const useFormUpdater = (data, setValue) => {
  useDeepCompareEffect(() => {
    const setValueToKey = ([key, value]) => {
      setValue(key, value, {shouldDirty: false})
    }
    Object.entries(data).forEach(setValueToKey)
  }, [data, setValue])
}
const useReduxUpdater = (useFormApi, defaultValues, onChange) => {
  const changes = useFormApi.watch()
  // const dirtyFields = useFormApi.formState.dirtyFields
  useDeepCompareEffect(() => {
    if (!deepEqual(changes, defaultValues)) {
      onChange(changes)
    }
  }, [changes])
}

export const useReduxForm = (data, defaultValues, updateAction) => {
  const useFormApi = useForm({
    defaultValues: data
  })
  const {handleSubmit} = useFormApi

  /* Data source updaters */
  // update the FORM => WHY? change comes from "data" prop
  useFormUpdater(data, useFormApi.setValue)
  // update the STORE => WHY? change comes from the "form"
  useReduxUpdater(useFormApi, defaultValues, updateAction)

  const onSubmitHandler = useCallback(
    () => handleSubmit(updateAction),
    [handleSubmit, updateAction]
  )

  return {
    onSubmitHandler,
    useFormApi
  }
}
