import {
    MONTH_STAT_GET_ACTION_REQUESTED,
    MONTH_STAT_ADD_ACTION_REQUESTED,
    MONTH_STAT_REMOVE_ACTION_REQUESTED,
    MONTH_STAT_UPDATE_ACTION_REQUESTED,
    MONTH_STAT_SET_DATA_ACTION,
} from "../constants/actions";
import {apiMonthFormat} from "../features/client";
import moment from "moment";


function getMonthStatAction(date) {
    return {
        type: MONTH_STAT_GET_ACTION_REQUESTED,
        payload: {date: moment(date).format(apiMonthFormat)}
    }
}


function addMonthStatAction(monthStat) {
    return {
        type: MONTH_STAT_ADD_ACTION_REQUESTED,
        payload: monthStat
    }
}

function removeMonthStatAction(id) {
    return {
        type: MONTH_STAT_REMOVE_ACTION_REQUESTED,
        payload: id
    }
}

function updateMonthStatAction(monthStat) {
    return {
        type: MONTH_STAT_UPDATE_ACTION_REQUESTED,
        payload: monthStat
    }
}
function setDataMonthStatAction(monthStat) {
    return {
        type: MONTH_STAT_SET_DATA_ACTION,
        payload: monthStat
    }
}



export const monthStatActionCreators = {
    getMonthStatAction,
    addMonthStatAction,
    removeMonthStatAction,
    updateMonthStatAction,
    setDataMonthStatAction
}
