import {
    POSITION_GET_ACTION_BEGIN,
    POSITION_GET_ACTION_SUCCEEDED,
    POSITION_GET_ACTION_FAILED,
    POSITION_ADD_ACTION_BEGIN,
    POSITION_ADD_ACTION_SUCCEEDED,
    POSITION_ADD_ACTION_FAILED,
    POSITION_REMOVE_ACTION_BEGIN,
    POSITION_REMOVE_ACTION_SUCCEEDED,
    POSITION_REMOVE_ACTION_FAILED,
    POSITION_UPDATE_ACTION_BEGIN,
    POSITION_UPDATE_ACTION_SUCCEEDED,
    POSITION_UPDATE_ACTION_FAILED,

    POSITION_HIDE_ADDING_MODAL_ACTION,
    POSITION_HIDE_EDITING_MODAL_ACTION,

    POSITION_SHOW_ADDING_MODAL_ACTION,
    POSITION_SHOW_EDITING_MODAL_ACTION,

    POSITION_UPDATE_ADDING_MODAL_ACTION,
    POSITION_UPDATE_EDITING_MODAL_ACTION
  } from "../constants/actions"

  const initialState = {
    positions: [],
    busy: false,
    busyAdding: false,
    busyUpdating: false,
    busyRemoving: false,
    addingPositionModal: false,
    addingPosition: {name: ''},
    editedPosition: null,
    loggedIn: false,
    errors: {},
    errorsAdding: {},
    errorsUpdating: {},
    errorsRemoving: {},
  }

  export const positionReducer = (state = initialState, action) => {
    let positions = state.positions
    switch (action.type) {
      case POSITION_GET_ACTION_BEGIN:
        return {
          ...state,
          busy: true,
          errors: {},
        }
      case POSITION_ADD_ACTION_BEGIN:
        return {
          ...state,
          busyAdding: true,
          errorsAdding: {},
        }
      case POSITION_REMOVE_ACTION_BEGIN:
        return {
          ...state,
          busyRemoving: true,
          errorsRemoving: {},
        }
      case POSITION_UPDATE_ACTION_BEGIN:
        return {
          ...state,
          busyUpdating: true,
          errorsUpdating: {},
        }
      case POSITION_GET_ACTION_FAILED:
        return {
          ...state,
          busy: false,
          errors: action.payload,
        }
      case POSITION_ADD_ACTION_FAILED:
        return {
          ...state,
          busyAdding: false,
          errorsAdding: action.payload,
        }
      case POSITION_REMOVE_ACTION_FAILED:
        return {
          ...state,
          busyRemoving: false,
          errorsRemoving: action.payload,
        }
      case POSITION_UPDATE_ACTION_FAILED:
        return {
          ...state,
          busyUpdating: false,
          errorsUpdating: action.payload,
        }

      case POSITION_ADD_ACTION_SUCCEEDED:
        return {
          ...state,
          busyAdding: false,
          addingPositionModal: false,
          positions: [...positions, action.payload]
        }
      case POSITION_REMOVE_ACTION_SUCCEEDED:
        return {
          ...state,
          busyRemoving: false,
          positions: positions.filter(position => position.id !== action.payload)
        }
      case POSITION_UPDATE_ACTION_SUCCEEDED:
        positions = positions.filter(position => position.id !== action.payload.id)
        return {
          ...state,
          busyUpdating: false,
          positions: [...positions, action.payload],
          editedPosition: null,
        }
      case POSITION_GET_ACTION_SUCCEEDED:
        return {
          ...state,
          busy: false,
          positions: action.payload
        }
      case POSITION_SHOW_ADDING_MODAL_ACTION:
        return {
          ...state,
          errors: {},
          addingPositionModal: true,
          addingPosition: initialState.addingPosition,
        }
      case POSITION_HIDE_ADDING_MODAL_ACTION:
        return {
          ...state,
          addingPositionModal: false,
        }
      case POSITION_UPDATE_ADDING_MODAL_ACTION:
        return {
          ...state,
          addingPosition: action.payload,
        }
      case POSITION_SHOW_EDITING_MODAL_ACTION:
        return {
          ...state,
          errors: {},
          editedPosition: action.payload,
        }
      case POSITION_UPDATE_EDITING_MODAL_ACTION:
        return {
          ...state,
          editedPosition: action.payload,
        }
      case POSITION_HIDE_EDITING_MODAL_ACTION:
        return {
          ...state,
          editedPosition: null,
        }
      default:
        return state;
    }
  }
