import {
  SERVICE_GET_ACTION_REQUESTED,
  SERVICE_UPLOAD_ACTION_REQUESTED,
  SERVICE_ADD_ACTION_REQUESTED,
  SERVICE_REMOVE_ACTION_REQUESTED,
  SERVICE_UPDATE_ACTION_REQUESTED,
  SERVICE_SHOW_ADDING_MODAL_ACTION,
  SERVICE_HIDE_ADDING_MODAL_ACTION,
  SERVICE_HIDE_UPLOADING_MODAL_ACTION,
  SERVICE_UPDATE_ADDING_MODAL_ACTION,
  SERVICE_SHOW_EDITING_MODAL_ACTION,
  SERVICE_HIDE_EDITING_MODAL_ACTION,
  SERVICE_UPDATE_EDITING_MODAL_ACTION,
  SERVICE_UPDATE_VALUE_ACTION,
  SERVICE_SAVE_VALUE_ACTION_REQUESTED,
  SERVICE_SHOW_UPLOADING_MODAL_ACTION,
  SERVICE_UPDATE_UPLOADING_MODAL_ACTION
} from "../constants/actions";
import {apiMonthFormat} from "../features/client";
import moment from "moment";

function getServicesAction(month) {
  return {
    type: SERVICE_GET_ACTION_REQUESTED,
    payload: {month: moment(month).format(apiMonthFormat)}
  }
}

function addServiceAction(name, branch) {
  return {
    type: SERVICE_ADD_ACTION_REQUESTED,
    payload: {name, branch}
  }
}
function uploadServiceAction(service) {
  return {
    type: SERVICE_UPLOAD_ACTION_REQUESTED,
    payload: {...service}
  }
}

function removeServiceAction(id) {
  return {
    type: SERVICE_REMOVE_ACTION_REQUESTED,
    payload: id
  }
}

function updateServiceAction(service) {
  return {
    type: SERVICE_UPDATE_ACTION_REQUESTED,
    payload: {...service, branch: service.branch ? (service.branch.id ?? service.branch) : null}
  }
}

function showAddingServiceModalAction() {
  return {
    type: SERVICE_SHOW_ADDING_MODAL_ACTION,
  }
}
function showUploadingServiceModalAction() {
  return {
    type: SERVICE_SHOW_UPLOADING_MODAL_ACTION,
  }
}

function hideAddingServiceModalAction() {
  return {
    type: SERVICE_HIDE_ADDING_MODAL_ACTION
  }
}

function hideUploadingServiceModalAction() {
  return {
    type: SERVICE_HIDE_UPLOADING_MODAL_ACTION
  }
}

function updateAddingServiceModalAction(data) {
  return {
    type: SERVICE_UPDATE_ADDING_MODAL_ACTION,
    payload: data,
  }
}

function updateUploadingServiceModalAction(data) {
  return {
    type: SERVICE_UPDATE_UPLOADING_MODAL_ACTION,
    payload: data
  }
}

function showEditingServiceModalAction(service) {
  return {
    type: SERVICE_SHOW_EDITING_MODAL_ACTION,
    payload: service,
  }
}

function hideEditingServiceModalAction() {
  return {
    type: SERVICE_HIDE_EDITING_MODAL_ACTION
  }
}

function updateEditingServiceModalAction(data) {
  return {
    type: SERVICE_UPDATE_EDITING_MODAL_ACTION,
    payload: data,
  }
}

function updateServiceValueAction(id, value) {
  return {
    type: SERVICE_UPDATE_VALUE_ACTION,
    payload: {id, value},
  }
}

function saveServiceValueAction(service, value) {
  return {
    type: SERVICE_SAVE_VALUE_ACTION_REQUESTED,
    payload: {...service, ...value, branch: service.branch ? (service.branch.id ?? service.branch) : null},
  }
}

export const serviceActionCreators = {
  getServicesAction,
  addServiceAction,
  uploadServiceAction,
  removeServiceAction,
  updateServiceAction,
  showAddingServiceModalAction,
  showUploadingServiceModalAction,
  hideAddingServiceModalAction,
  hideUploadingServiceModalAction,
  updateAddingServiceModalAction,
  updateUploadingServiceModalAction,
  showEditingServiceModalAction,
  hideEditingServiceModalAction,
  updateEditingServiceModalAction,
  updateServiceValueAction,
  saveServiceValueAction
}
