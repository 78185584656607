import React, {useEffect} from 'react'
// import PropTypes from "prop-types"
import styled from 'styled-components'
import {Outlet} from "react-router-dom"
import {ThemeProvider} from 'styled-components'
import {GlobalStyles} from '../utils/globalStyles'
import {theme} from '../utils/theme'
import {Reset} from '../utils/reset'
import {gsap} from 'gsap'

import {AppProvider} from './context'

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Container = styled.div`
  min-height: 100vh;
`

const Layout = () => {

  useEffect(() => {
    gsap.to("body", 0, {css: {visibility: "visible"}});
  })

  

  return (
    <ThemeProvider theme={theme}>
      <ToastContainer/>
      <AppProvider>
        <GlobalStyles/>
        <Reset/>
        <Container>
          <main>
            <Outlet/>
          </main>
        </Container>
      </AppProvider>
    </ThemeProvider>
  )
}


export default Layout;
