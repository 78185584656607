import React from 'react'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'
import { MdOutlineAddCircleOutline } from "react-icons/md"

export const AddItem = styled.div `
    cursor: pointer;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2rem;
    background: rgb(210, 36, 22, 0.04);
    color: ${({theme}) => theme.colors.red};
    border-radius: 3px;
`
export const AddItemButton = ({textButton, handleOpen, tooltipText}) => (
    <Tooltip title={tooltipText}>
        <AddItem onClick={handleOpen}>
            <MdOutlineAddCircleOutline
                color='#000'
                size='20px'
            />
            <span>{textButton}</span>
        </AddItem>
    </Tooltip>
)


