/* eslint-disable */
import React, {useState, useEffect, onBlur} from 'react'
import styled from 'styled-components'


import Slider from '@mui/material/Slider'
import {Heading} from '../../../common/heading'
import {device} from '../../../../utils/breakpoints'
import {Line} from '../../../common/line'

import {AiOutlineQuestionCircle, AiFillLock} from 'react-icons/ai'
import {BsArrowRightCircle} from 'react-icons/bs'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {useLocation, useNavigate} from "react-router-dom";
import {formatPrice} from "../../../../utils/numberUtils";
import TextField from "@mui/material/TextField"
import InputAdornment from '@mui/material/InputAdornment'




//To do:
//1. Zmiana czasu zabiegu (myślę, że tutaj to nie potrzebne)
//2. Odswieżanie danych po zmianie zakresu kalkulatora.



const ContainerCalculator = styled.div`
  display: grid;
  grid-template-rows: repeat(3, auto);
  padding: 1rem;
  border: 1px solid #F0F0F0;
  border-radius: 8px;
  gap: 5rem;

  @media ${device.mobileM} {
    padding: 1.5rem;
  }

  @media ${device.mobileXL} {
    padding: 2rem;
  }

  position: relative;



`


const ContainerSliders = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 3rem;

  @media ${device.laptopS} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
  }
`

const ContainerSlider = styled.div`


  .css-eg0mwd-MuiSlider-thumb {
    width: 34px;
    height: 22px;
    border-radius: 41%;
    background: white;
  }

  .css-7drnjp {
    width: 34px;
    height: 22px;
    border-radius: 41%;
    background: white;
  }


  .css-1ejytfe {
    display: none;
  }

  .css-1gv0vcd-MuiSlider-track, .css-1t2bqnt, .css-b04pc9 {
    border: 4px solid #009C28;
  }

  .css-14pt78w-MuiSlider-rail {
    height: 8px;
    background-color: #EDEDED;
  }


  .css-eg0mwd-MuiSlider-thumb.Mui-active {
    box-shadow: 0px 0px 0px 14px rgb(70 149 70 / 16%);
  }

  .gPpJdD .css-eg0mwd-MuiSlider-thumb.Mui-active {
    box-shadow: 0px 0px 0px 14px rgb(70 149 70 / 16%);
  }

  .css-eg0mwd-MuiSlider-thumb:hover, .css-eg0mwd-MuiSlider-thumb.Mui-focusVisible {
    box-shadow: 0px 0px 0px 8px rgb(70 149 70 / 16%);
  }

  .css-1tfve6q-MuiSlider-mark, .css-6cwnna-MuiSlider-mark {
    display: none;
  }

  .css-9cct2k {
    color: ${({theme}) => theme.colors.green};
  }

  .css-7drnjp.Mui-active {
    box-shadow: 0px 0px 0px 14px rgb(70 149 70 / 16%);

  }


`

const StyledTextField = styled(TextField)`
  input {
    padding: 6.5px 4px !important;
  }

`


const SliderInformations = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;

`

const ContainerSliderInformation = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  gap: 0.5rem;

  span {
    font-weight: 600;
    font-size: ${({theme}) => theme.fonts.size.s};
    color: ${({theme}) => theme.colors.green};
    min-width: 80px;
  }

`


const StyledHeading = styled(Heading)`
  font-size: ${({theme}) => theme.fonts.size.xs};
  font-weight: 500;
  line-height: 1;

  @media ${device.mobileM} {
    font-size: ${({theme}) => theme.fonts.size.s};
  }


`

const ContainerResults = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-items: center;
  gap: 2rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(3, auto);

  }
`

const ContainerResult = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 1rem;
  justify-items: center;
  align-items: center;


  .black {
    color: ${({theme}) => theme.colors.black};
  }

  .green {
    color: ${({theme}) => theme.colors.green};
  }

  span {
    font-weight: 700;
    text-align: center;
    font-size: ${({theme}) => theme.fonts.size.xl};
    color: ${({theme}) => theme.colors.green};
    min-width: 6rem;
  }
`

const ResultLine = styled.div`
  display: grid;
  border-top: 1px solid #F0F0F0;
  width: 100%;

  @media ${device.tablet} {
    border-left: 1px solid #F0F0F0;
    border-top: 0px solid #F0F0F0;
    height: 100%;
    width: 0%;
  }
`


const DisabledCalculator = styled.div `
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background: #6D6D6D;
  opacity: 70%;
  z-index: 100;
  border-radius: 5px;
  display: grid;
  align-content: center;
  justify-items: center;
`

const HeadingDisabledCalculator = styled(Heading) `
  color: white;
  z-index: 101;

`


const ContainerTooltips = styled.div`
  display: grid;
  grid-template-columns: auto auto;
`


function calculatePrice(price) {

  return 1 * price;
}


function calculateSalary(salary) {

  return salary;
}

function calculateCosts(costs) {

  return 1 * costs;
}

function parseValue(value, priceSuffix = false, time = true) {
  if (time) {
    let hours = Math.floor(value / 60);
    let minutes = value - (hours * 60);
    let returnValue = '';
    if (hours > 0) {
      returnValue += hours + 'h ';
    }
    if (minutes > 0) {
      returnValue += minutes + 'min';
    }
    return returnValue.length > 0 ? returnValue : '0min';
  } else {
    return (Math.round(value * 100) / 100).toString().replace('.', ',') + (priceSuffix ? ' zł' : '')
  }
}


export const BasicCalculator = ({
                                  calculator,
                                  marksPrice,
                                  marksTime,
                                  marksSalary,
                                  marksCosts,
                                  stepTime,
                                  maxTime,
                                  stepPrice,
                                  maxPrice,
                                  text1,
                                  text2,
                                  hourPrice,
                                  setHourPrice,
                                  hourProfit,
                                  setHourProfit,
                                  data,
                                  setData,
                                  assignedCostsSummary,
                                  disabledCalculator
                                }) => {

  const location = useLocation()

  // const [hourPrice, setHourPrice] = useState(0)
  // const [hourProfit, setHourProfit] = useState(0)
  // const [ data, setData ] = useState({
  //     price: 0,
  //     time: 0,
  //     salary: 0,
  //     costs: location.state ? (location.state.costsPerHour ?? 0) : 0
  // })


  



  useEffect(() => {

    setHourPrice(parseFloat((data.price - assignedCostsSummary) * (data.salary / 100)).toFixed(0))

  }, [data.price, data.salary, assignedCostsSummary])

  useEffect(() => {
    setHourProfit(parseFloat(data.price - assignedCostsSummary - (data.costs * (calculator > 1 ? data.time : data.time / 60)) - hourPrice).toFixed(0))
  }, [data.price, data.costs, data.time, hourPrice, assignedCostsSummary])


  useEffect(() => {
    // setData({
    //     price: 0,
    //     time: 0,
    //     salary: 0,
    //     costs: location.state ? (location.state.costsPerHour ?? 0) : 0
    // })
  }, [marksPrice, marksTime])


  const validateNumber = (max, number) => {
    let formattedNumber = Math.max(0, Math.min(max, number));

    return formattedNumber
  }

  const handleChange = max => (event) => {
    const value = validateNumber(max, Number(event.target.value));
    setData({
      ...data,
      [event.target.name]: value
    });
  };
  

 


  const navigate = useNavigate()
  return (
    <ContainerCalculator>
      <ContainerSliders>
        <ContainerSlider>
          <SliderInformations>
            <ContainerSliderInformation>
              <StyledHeading h4 as='h4'>{text1}:</StyledHeading>
              <StyledTextField 
                type='number'
                value={data.price === 0 ? '' : data.price}
                placeholder="0 zł"
                onChange={handleChange(maxPrice)}
                name='price'
              />
            </ContainerSliderInformation>
            <ContainerTooltips>
              <Tooltip title="Podaj cenę zabiegu/ile zapłacił pacjent/przychód." enterTouchDelay={0}>
                <IconButton>
                  <AiOutlineQuestionCircle size="20px" coor="#000"/>
                </IconButton>
              </Tooltip>
            </ContainerTooltips>
          </SliderInformations>
          <Slider
            value={data.price}
            min={0}
            step={stepPrice}
            max={maxPrice}
            scale={calculatePrice}
            onChange={handleChange(maxPrice)}
            valueLabelDisplay="off"
            name='price'
            marks={marksPrice}
            aria-labelledby="non-linear-slider"
            disabled={disabledCalculator}
          />
        </ContainerSlider>
        <ContainerSlider>
          <SliderInformations>
            <ContainerSliderInformation>
              <StyledHeading h4 as='h4'>{text2}:</StyledHeading>
              <StyledTextField 
                type='number'
                value={data.time === 0 ? '' : calculator > 1 ? data.time : data.time}
                placeholder="0 min"
                onChange={handleChange(maxTime)}
                name='time'
              />
            </ContainerSliderInformation>
            <ContainerTooltips>
              <Tooltip title="Podaj czas zabiegu/przepracowane godziny związane z przychodem." enterTouchDelay={0}>
                <IconButton>
                  <AiOutlineQuestionCircle size="20px" coor="#000"/>
                </IconButton>
              </Tooltip>
            </ContainerTooltips>
          </SliderInformations>
          <Slider
            value={data.time}
            min={0}
            step={stepTime}
            max={maxTime}
            scale={parseValue}
            onChange={handleChange(maxTime)}
            valueLabelDisplay="off"
            name='time'
            marks={marksTime}
            aria-labelledby="non-linear-slider"
            disabled={disabledCalculator}
          />
        </ContainerSlider>

        <ContainerSlider>
          <SliderInformations>
            <ContainerSliderInformation>
              <StyledHeading h4 as='h4'>Wynagrodzenie:</StyledHeading>
              <StyledTextField 
                value={data.salary === 0 ? '' : data.salary}
                placeholder="0 zł"
                type='number'
                onChange={handleChange(100)}
                name='salary'
              /> 
            </ContainerSliderInformation>
            <ContainerTooltips>
              <Tooltip title="Wybierz prowizję procentową lekarza/ (jeżeli pracujesz na własny rachunek wybierz 100%)"
                       enterTouchDelay={0}>
                <IconButton>
                  <AiOutlineQuestionCircle size="20px" coor="#000"/>
                </IconButton>
              </Tooltip>
            </ContainerTooltips>
          </SliderInformations>
          <Slider
            value={data.salary}
            min={0}
            step={1}
            max={100}
            scale={calculateSalary}
            onChange={handleChange(100)}
            valueLabelDisplay="off"
            name='salary'
            marks={marksSalary}
            aria-labelledby="non-linear-slider"
            disabled={disabledCalculator}
          />
        </ContainerSlider>
        <ContainerSlider>
          <SliderInformations>
            <ContainerSliderInformation>
              <StyledHeading h4 as='h4'>Koszty stałe za 1h:</StyledHeading>
              <StyledTextField 
                value={data.costs === 0 ? '' : data.costs.toFixed(2)}
                placeholder="0 zł"
                type='number'
                onChange={handleChange(600)}
                name='costs'
                
              /> 
            </ContainerSliderInformation>
            <ContainerTooltips>
              <Tooltip title="Przejdź do zakładki koszty" enterTouchDelay={0}>
                <IconButton onClick={() => navigate('/koszty')}>
                  <BsArrowRightCircle  size="20px" color="#D22416"/>
                </IconButton>
              </Tooltip>
              <Tooltip
                title="Wybierz średni koszt na godzinę pracy gabinetu/sumę kosztów stałych w miesiącu podziel przez ilość przepracowanych godzin na wszystkich stanowiskach."
                enterTouchDelay={0}>
                <IconButton>
                  <AiOutlineQuestionCircle size="22px"/>
                </IconButton>
              </Tooltip>
            </ContainerTooltips>
          </SliderInformations>
          <Slider
            value={data.costs}
            min={0}
            step={5}
            max={600}
            scale={calculateCosts}
            onChange={handleChange(600)}
            valueLabelDisplay="off"
            name='costs'
            marks={marksCosts}
            aria-labelledby="non-linear-slider"
            disabled={disabledCalculator}
          />
        </ContainerSlider>

      </ContainerSliders>
      <Line/>
      <ContainerResults>
        <ContainerResult>
          <StyledHeading h4 as='h4'>Kwota dla lekarza</StyledHeading>
          <span className='black'>{hourPrice} zł</span>
        </ContainerResult>
        <ResultLine/>
        <ContainerResult>
          <StyledHeading h4 as='h4'>Zysk dla gabinetu</StyledHeading>
          <span className='green'>{hourProfit} zł</span>
        </ContainerResult>
      </ContainerResults>
    </ContainerCalculator>
  )
}
