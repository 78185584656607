import {
  SETTINGS_CHANGE_PASSWORD_ACTION_BEGIN,
  SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED,
  SETTINGS_CHANGE_PASSWORD_ACTION_FAILED,
  SETTINGS_CHANGE_EMAIL_ACTION_BEGIN,
  SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED,
  SETTINGS_CHANGE_EMAIL_ACTION_FAILED,
  SETTINGS_GET_ACTION_BEGIN,
  SETTINGS_GET_ACTION_SUCCEEDED,
  SETTINGS_GET_ACTION_FAILED,
  SETTINGS_SET_CHANGE_PASSWORD_DATA_ACTION,
  SETTINGS_SET_CHANGE_EMAIL_DATA_ACTION,
} from "../constants/actions"

export const initialState = {
    settings: {},
    dataChangingPassword: {
      current_password: '',
      plainPassword1: '',
      plainPassword2: '',
    },
    dataChangingEmail: {
      current_password: '',
      newEmail: '',
    },
    busy: false,
    busyChangingPassword: false,
    busyChangingEmail: false,
    errors: {},
    errorsChangingPassword: {},
    errorsChangingEmail: {},
  }

  export const settingsReducer = (state = initialState, action) => {

    switch (action.type) {
      case SETTINGS_GET_ACTION_BEGIN:
        return {
          ...state,
          busy: true,
          errors: {},
        }
      case SETTINGS_CHANGE_PASSWORD_ACTION_BEGIN:
        return {
          ...state,
          busyChangingPassword: true,
          errorsChangingPassword: {},
        }
      case SETTINGS_CHANGE_EMAIL_ACTION_BEGIN:
        return {
          ...state,
          busyChangingEmail: true,
          errorsChangingEmail: {},
        }

      case SETTINGS_GET_ACTION_SUCCEEDED:
        return {
          ...state,
          busy: false,
          settings: action.payload,
          dataChangingEmail: {...state.dataChangingEmail, newEmail: action.payload.email}
        }

      case SETTINGS_CHANGE_PASSWORD_ACTION_SUCCEEDED:
        return {
          ...state,
          busyChangingPassword: false,
          dataChangingPassword: initialState.dataChangingPassword,
        }

      case SETTINGS_CHANGE_EMAIL_ACTION_SUCCEEDED:
        return {
          ...state,
          busyChangingEmail: false,
          dataChangingEmail: {...initialState.dataChangingEmail, newEmail: action.payload},
          settings: {...state.settings, email: action.payload}
        }

      case SETTINGS_GET_ACTION_FAILED:
        return {
          ...state,
          busy: false,
          errors: action.payload,
        }

      case SETTINGS_CHANGE_PASSWORD_ACTION_FAILED:
        return {
          ...state,
          busyChangingPassword: false,
          errorsChangingPassword: action.payload,
        }

      case SETTINGS_CHANGE_EMAIL_ACTION_FAILED:
        return {
          ...state,
          busyChangingEmail: false,
          errorsChangingEmail: action.payload,
        }
      case SETTINGS_SET_CHANGE_PASSWORD_DATA_ACTION:
        return {
          ...state,
          dataChangingPassword: action.payload,
        }
      case SETTINGS_SET_CHANGE_EMAIL_DATA_ACTION:
        return {
          ...state,
          dataChangingEmail: action.payload,
        }

      default:
        return state;
    }
  }
