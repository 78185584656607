/* eslint-disable */
import React, {useEffect} from 'react'
import styled from 'styled-components'
import { device  } from '../../../utils/breakpoints'
import logo from '../../../images/logo-dents-play.png'
import {BiLogOut} from 'react-icons/bi'

import {useSelector, useDispatch} from 'react-redux'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'


import { SidebarLinks } from '../data/sidebar-links'
import { SidebarLink } from './sidebarLink'
import {authActionCreators} from "../../../actions/authActionCreators";



const StyledSidebar = styled.div `
    display: none;

    @media ${device.laptop} {
        position: fixed;
        top: 0.7rem;
        bottom: 0.7rem;
        max-height: 100vh;
        width: 350px;
        background: ${({ theme }) => theme.colors.white};
        box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
        display: grid;
        grid-template-columns: auto;
        padding: 2rem 1rem;
        align-content: space-between;  
        overflow-y: auto;
    }

    .noselect {
        -webkit-touch-callout: none; 
        -webkit-user-select: none; 
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;                          
    }

    .active {
        background: rgb(210, 36, 22, 0.04);
        color: ${({ theme }) => theme.colors.red};
    }
`

//Top

const SidebarTop = styled.div `
    display: grid;
    grid-template-columns: auto;
    gap: 1.5rem;
    align-content: start;

`

const ContainerLogo = styled.div `
    display: grid;
    justify-content: center;
    
    img {
        width: 97px;
    }

`


const ContainerProfile = styled.div `
    padding: 1rem;
    display: grid;
    grid-template-columns: auto;
    background: ${({ theme }) => theme.colors.lightGray};
    border-radius: 4px;
`


const ProfileInformations = styled.div `
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    span {
        font-size: ${({ theme }) => theme.fonts.size.xxs};
        color: #6D6D6D;
        font-weight: 700;
    }

`
const ContainerLoggedPersonName = styled.div `
    display: grid;
    gap: 0.5rem;
    grid-template-rows: 1fr;

`
const VatNumber = styled.div `
  font-size: ${({ theme }) => theme.fonts.size.xxs};
`

const LoggedPersonName = styled.div `
    font-size: ${({ theme }) => theme.fonts.size.xs};
    font-weight: 700;
    width: 130px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    height: 20px;
    display: inline-block; 
`

const ProfileImage = styled.div `
    border-radius: 30px;
    width: 45px;
    height: 45px;
    position: relative;
    background: #fff url('/fi.png') no-repeat;
    background-size: contain;
    border: 1px solid #eee;


    .person-image {
        border-radius: 30px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
    }

`


const Line = styled.div `
    width: 100%;
    height: 1px;
    border-top: 1px solid #F0F0F0;
`

const StyledSidebarMenu = styled.div `
    display: grid;
    grid-template-columns: auto;
    width: 100%;
`



const {logoutAction} = authActionCreators

export const Sidebar = () => {


    // const navigate = useNavigate()
    const dispatch = useDispatch()
    const {loggedIn, user} = useSelector(state => state.auth)

    return (
            <StyledSidebar>
                <SidebarTop>
                    <ContainerLogo>
                        <img  className='image' src={logo} title="Logo" alt="Logo" />
                    </ContainerLogo>
                    <ContainerProfile>
                        <ProfileInformations>
                            <ProfileImage>
                            </ProfileImage>
                            <ContainerLoggedPersonName>
                                <span>ZALOGOWANY JAKO</span>

                                    <LoggedPersonName>{user?.email}</LoggedPersonName>
                                    {user?.vatNumber && <VatNumber>NIP: {user.vatNumber}</VatNumber>}
                             
                                
                            </ContainerLoggedPersonName>
                            <Tooltip title="Wyloguj się">
                                {loggedIn ? <IconButton onClick={() => dispatch(logoutAction())}><BiLogOut size="22" /></IconButton> : ''}
                            </Tooltip>
                        </ProfileInformations>

                    </ContainerProfile>
                    <Line/>
                    <StyledSidebarMenu>
                        {SidebarLinks.map((item, index) => {
                            return (
                                <SidebarLink className='noselect' item={item} key={index}/>
                            )
                        })}
                    </StyledSidebarMenu>

                </SidebarTop>

            </StyledSidebar>
    )
}


