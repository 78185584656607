import React from 'react'
import styled, { css } from 'styled-components'
import { device  } from '../../../../utils/breakpoints'


const Container = styled.div`
    justify-self: end;
    overflow: hidden;
    width: calc(1.6rem + 1rem);
    height: calc(1.4rem + 1rem);
    position: relative;
    cursor: pointer;
    padding: 1rem;
    z-index: 10;

    @media ${device.laptop} {
      display: none;
    }
      


`

const Line = styled.span`
    transform-origin: center;
    position: absolute;
    width: calc(100% - 1rem);
    height: 0.125rem;
    background: #000;
    transition: all 0.3s ease; 
    transition-delay: 0.3s;
    border-radius: 2px;

    ${({ isOpen }) =>
        isOpen &&
            css`
             background: #000;
          
        `
      }



  :nth-child(1) {
    top: 0.7rem;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(7px) rotate(-45deg)' : 'translateY(0) rotate(0)')};
  }

  :nth-child(2) {
    top: 50%;
    opacity: ${({ isOpen }) => (isOpen ? '0' : '1')};
    transform: ${({ isOpen }) => (isOpen ? 'translate(calc(100% + 0rem), -50%)' : 'translate(0%, -50%)')};
    width: 1.3rem;
  }

  :nth-child(3) {
    bottom: 0.7rem;
    transform: ${({ isOpen }) => (isOpen ? 'translateY(-7px) rotate(45deg)' : 'translateY(0) rotate(0)')};
  }

`


export const Hamburger = props => {
    const { isOpen } = props
  
  return (
    <Container {...props }>
      <Line isOpen={isOpen}/>
      <Line isOpen={isOpen}/>
      <Line isOpen={isOpen} />
    </Container>
  )
}

