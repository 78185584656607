import React from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {Link} from "react-router-dom"
// import { device  } from '../../utils/breakpoints'
import {useForm} from "react-hook-form"
import {Controller} from "react-hook-form"

import styled from 'styled-components'

import logo from '../../images/logo-dents-play.png'
import {Button} from '../common/button'
import {Heading} from '../common/heading'
import {device} from '../../utils/breakpoints'

import TextField from '@mui/material/TextField'
import { Checkbox} from '@mui/material'
import {authActionCreators} from '../../actions/authActionCreators'



const ContainerLoginSide = styled.div`
  display: grid;
  width: 100%;
  grid-template-rows: auto;
  gap: 3rem;
  justify-items: start;
  padding: 3rem 2rem;
  max-width: 800px;
  z-index: 1000;

  @media ${device.tablet} {
    padding: 2rem 3rem;
  }

  @media ${device.laptop} {
    padding: 1rem 4rem;
    gap: 1rem;
  }

  .css-19hdqbp-MuiButtonBase-root-MuiCheckbox-root {
    padding: 0px !important;
  }
`

const ContainerLogo = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;

  img {
    width: 117px;
  }
`

const ContainerLogin = styled.div`
  width: 100%;
  display: grid;
  align-content: start;
  gap: 3rem;

`

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 3rem;
`

const Form = styled.form`
  display: grid;
  grid-template-rows: auto;
  gap: 1.8rem;

`

const ContainerTextFields = styled.div`
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  gap: 1.8rem;

`

const ContainerStyledTextField = styled.div`
  display: grid;
  position: relative;


  span {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.lightBlack};
    font-weight: 700;
    font-size: ${({theme}) => theme.fonts.size.xxs};
  }


  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-u9osun.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }
`


const ContainerRegulations = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: repeat(2, auto);
  justify-items: start;
  align-items: center;
  font-size: ${({theme}) => theme.fonts.size.xs};

  .css-j204z7-MuiFormControlLabel-root {
    font-family: 'Open Sans' !important;
  }

  .css-ahj2mt-MuiTypography-root {
    font-family: 'Open Sans' !important;
    font-size: ${({theme}) => theme.fonts.size.xs};
  }

  .css-j204z7-MuiFormControlLabel-root {
    margin-right: 0px;
  }

  span {
    line-height: 1.3;
    align-self: center;
  }

  div {
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 0.5rem;
    justify-content: start;
  }

  /* .css-8je8zh-MuiTouchRipple-root {
    display: none;
  }

  .css-19hdqbp-MuiButtonBase-root-MuiCheckbox-root:hover {
    background-color: transparent;
  } */



`

const StyledLink = styled.a`
  font-weight: 400;
  text-decoration: underline;
`


const ContainerButton = styled.div`


`

const StyledButton = styled(Button)`
  width: 100%;
  background: ${({theme}) => theme.colors.red};
  color: ${({theme}) => theme.colors.white};
  border: 1px solid #D22416;
  font-weight: 700;
  font-size: ${({theme}) => theme.fonts.size.s};
`

// const ContainerSocialLogins = styled.div`
//   display: none;
//   grid-template-columns: auto;
//   justify-items: center;
//   gap: 2rem;
// `
//
// const SocialLogins = styled.div`
//   display: grid;
//   width: 100%;
//   grid-template-columns: repeat(2, 1fr);
//   gap: 0.5rem;
// `
//
// const SocialLogin = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, auto);
//   gap: 0.5rem;
//   justify-content: center;
//   cursor: pointer;
//   padding: 1rem 1.8rem;
//   background: ${({theme}) => theme.colors.gray};
//   border-radius: 30px;
//   text-align: center;
//
//   span {
//     font-weight: 700;
//   }
//
// `


const RegisterLink = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(2, auto);
  gap: 0.5rem;
  color: ${({theme}) => theme.colors.red};

  span, a {
    font-weight: 700;
    color: ${({theme}) => theme.colors.red};
  }

  a {
    text-decoration: underline;
  }
`

//message

const MessageInput = styled.p`
  position: absolute;
  bottom: -20px;
  font-size: ${({theme}) => theme.fonts.size.xxs};
  color: ${({theme}) => theme.colors.red};
`

const {registerAction} = authActionCreators

export const RegistrationSide = () => {

  const auth = useSelector(state => state.auth)
  const errorsAuth = auth.errorsRegister

  const {control, handleSubmit, watch, formState: {errors}} = useForm({
    defaultValues: {
      email: '',
      password: '',
      password2: '',
      vatNumber: '',
      checked: false
    }
  })

  const dispatch = useDispatch()

  const onSubmit = ({vatNumber, email, password}) => {
    console.log(vatNumber);
    dispatch(registerAction(vatNumber, email, password, true))
  }

  return (
    <ContainerLoginSide>
      <ContainerLogo>
        <img src={logo} title="Logo" alt="Logo"/>
      </ContainerLogo>

      <ContainerLogin>
        <Heading h1 as='h1'>Rejestracja</Heading>
        <ContainerForm>
          <Form
            onSubmit={handleSubmit(onSubmit)}>
            <ContainerTextFields>
              <ContainerStyledTextField>

                <Controller
                    id="vatNumber"
                    name="vatNumber"
                    label="NIP"
                    control={control}
                    text="NIP"
                    rules={{
                      required: 'To pole jest wymagane.',

                    }}
                    render={({field: {onChange, value}}) =>
                        <TextField
                            value={value}
                            onChange={onChange}
                            label="NIP"
                            type='test'
                            error={errorsAuth.vatNumber !== undefined}
                        />
                    }
                />
                <MessageInput>{errors.vatNumber?.message}</MessageInput>
                {errorsAuth.vatNumber !== undefined &&<MessageInput>{errorsAuth.vatNumber}</MessageInput>}
              </ContainerStyledTextField>
              <ContainerStyledTextField>

                <Controller
                  id="email"
                  name="email"
                  label="E-mail"
                  control={control}
                  text="E-mail"
                  rules={{
                    required: 'To pole jest wymagane.',

                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      onChange={onChange}
                      label="E-mail"
                      type='email'
                      error={errorsAuth.email !== undefined}
                    />
                  }
                />
                <MessageInput>{errors.email?.message}</MessageInput>
                {errorsAuth.email !== undefined &&<MessageInput>{errorsAuth.email}</MessageInput>}
              </ContainerStyledTextField>
              <ContainerStyledTextField>

                <Controller
                  id="password"
                  name="password"
                  label="Wpisz hasło"
                  control={control}
                  text="Hasło"
                  rules={{
                    required: 'To pole jest wymagane.',
                    minLength: {
                      value: 6,
                      message: 'Hasło powinno zawierać min 6 znaków.'
                    }
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      onChange={onChange}
                      label="Hasło"
                      type="password"
                    />
                  }
                />
                <MessageInput>{errors.password?.message}</MessageInput>

              </ContainerStyledTextField>

              <ContainerStyledTextField>

                <Controller
                  id="password2"
                  name="password2"
                  type="password"
                  label="Powtórz hasło"
                  control={control}
                  text="Powtórz hasło"
                  rules={{
                    required: 'To pole jest wymagane.',
                    validate: (value) => {
                      if (watch('password') != value) {
                        return "Twoje hasła się nie zgadzają."
                      }
                    },
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      onChange={onChange}
                      label="Powtórz hasło"
                      type="password"
                    />
                  }
                />
                <MessageInput>{errors.password2?.message}</MessageInput>

              </ContainerStyledTextField>

            </ContainerTextFields>
            <ContainerRegulations>

              <Controller
                name="checkbox"
                control={control}
                rules={{required: 'To pole jest wymagane.'}}
                render={({field: {onChange}}) =>
                  <div>
                    <Checkbox
                      onChange={onChange}
                      defaultChecked={false}
                      sx={{
                        color: '#D22416',
                        '&.Mui-checked': {
                          color: '#D22416',
                        },
                      }}
                    />
                    <span>Akceptuję <StyledLink href='https://dentsplay.pl/regulamin-serwisu/'>Regulamin</StyledLink>, <StyledLink href='https://dentsplay.pl/umowa-licencji/'>Umowę licencji</StyledLink>, <StyledLink href='https://dentsplay.pl/polityka-prywatnosci/'>Politykę prywatności oraz pliki cookies</StyledLink> i <StyledLink href='https://dentsplay.pl/klauzula-informacyjna/'>Klauzulę informacyjną</StyledLink>.</span>
                  </div>
                }
              />
              <MessageInput>{errors.checkbox?.message}</MessageInput>

            </ContainerRegulations>
            <ContainerButton>
              <StyledButton>Zarejestruj się</StyledButton>
            </ContainerButton>

          </Form>
          <RegisterLink>
            <span>Masz już konto?</span>
            <Link to='/'>Zaloguj się</Link>
          </RegisterLink>
        </ContainerForm>
      </ContainerLogin>
    </ContainerLoginSide>
  )
}

