import {apiRequest, unexpectedError} from "../client";

// Register user
const register = async (vatNumber, email, plainPassword, agreeTerms) => {
    const response = await apiRequest('POST', 'api/users/register', {vatNumber, email, plainPassword, agreeTerms})

    if (response.status === 200) {
        const {success, errors} = response.data
        if (success) {
            return true
        }
        return errors
    }

    return unexpectedError
}

// Login user
const login = async (email, password) => {
    const response = await apiRequest('POST', 'api/users/login/email', {email, password})

    if (response.status === 200) {
        const {success, errors} = response.data
        if (success) {
            return true
        }
        return errors
    }

    return unexpectedError
}

// Login user by Facebook
const loginFacebook = async (serverAuthCode) => {
    const response = await apiRequest('POST', 'api/users/login/facebook', {code: serverAuthCode})

    if (response.status === 200) {
        const {success, errors} = response.data
        if (success) {
            return true
        }
        return errors
    }

    return unexpectedError
}

// Login user by Google
const loginGoogle = async (serverAuthCode) => {
    const response = await apiRequest('POST', 'api/users/login/google', {code: serverAuthCode})

    if (response.status === 200) {
        const {success, errors} = response.data
        if (success) {
            return true
        }
        return errors
    }

    return unexpectedError
}

// User info
const loginInfo = async () => {
    const response = await apiRequest('GET', 'api/users/login_info')

    if (response.status === 200) {
        const {success, user} = response.data
        if (success) {
            return user
        }
        return false
    }

    return false
}


// Reset Password
const resetPassword = async (plainPassword, token) => {
    const response = await apiRequest('POST', 'api/users/resetPassword/' + token, {plainPassword})

    if (response.status === 200) {
        const {success, errors} = response.data
        if (success) {
            return true
        }
        return errors
    }

    return unexpectedError
}



// Forgot Password
const forgetPassword = async (username) => {
    const response = await apiRequest('POST', 'api/users/forgotPassword', {username})

    if (response.status === 200) {
        const {success} = response.data
        if (success) {
            return true
        }
        return unexpectedError
    }

    return unexpectedError
}

// Logout user
const logout = async () => {
    await apiRequest('GET', 'logout')
    return true
}

const authService = {
    register,
    logout,
    login,
    loginFacebook,
    loginGoogle,
    loginInfo,
    resetPassword,
    forgetPassword
}

export default authService
