import React, { useEffect } from 'react'

/* eslint-disable */
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Wrapper} from '../common/wrapper'
import {Line} from '../common/line'
import {SectionLoader} from '../common/sectionLoader'

import {useDispatch, useSelector} from 'react-redux'

import {BsPen, BsTrash} from "react-icons/bs"
import { MdOutlineAddCircleOutline } from "react-icons/md"

import {CostGroupsModal} from '../common/modal/costGroups-modal'

import {costGroupActionCreators} from "../../actions/costGroupActionCreators";

import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import {InstructionLink} from "../common/instructionLink";




const {
    getCostGroupsAction,
    addCostGroupAction,
    removeCostGroupAction,
    updateCostGroupAction,
    showAddingCostGroupModalAction,
    hideAddingCostGroupModalAction,
    updateAddingCostGroupModalAction,
    showEditingCostGroupModalAction,
    hideEditingCostGroupModalAction,
    updateEditingCostGroupModalAction
} = costGroupActionCreators




const StyledWrapper = styled(Wrapper)`
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  align-content: start;
  gap: 3rem;
`


const ContainerItems = styled.div `
  display: grid;
  grid-template-columns: auto;
  align-content: start;
  position: relative;
  gap: 2rem;
  min-height: 150px;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ContainerItem = styled.div `
  display: grid;
  min-width: 250px;
  width: 100%;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  background: ${({theme}) => theme.colors.lightGray};
  padding: 1rem;
  border-radius: 4px; 
`

const LeftSideInformation = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
    line-height: 1.3;
  }
`

const RightSideEdit = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
  }
`

const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
`
const ContainerTop = styled.div `
  display: grid;
  align-items: start;
  justify-items: start;
`

const ContainerHeading = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0%   {transform: scale(.7);}
    50%  {transform: scale(1);}
    100% {transform: scale(.7);}
  }

`

export const CostGroups = () => {

  const {
    errors,
    errorsRemoving,
    errorsAdding,
    errorsUpdating,
    addingCostGroupModal,
    addingCostGroup,
    editedCostGroup,
    costGroups,
    busy,
    busyAdding,
    busyUpdating,
    busyRemoving
  } = useSelector(
    (state) => state.costGroup
  )

  const dispatch = useDispatch()
  const handleClose = () => dispatch(hideAddingCostGroupModalAction())
  const handleOpen = () => dispatch(showAddingCostGroupModalAction())

  useEffect(() => {
    dispatch(getCostGroupsAction())
  }, [])

  return (
    <>
      <CostGroupsModal
        showModal={addingCostGroupModal}
        handleClose={handleClose}
        data={addingCostGroup}
        setData={data => dispatch(updateAddingCostGroupModalAction(data))}
        onSubmit={() => dispatch(addCostGroupAction(addingCostGroup.name))}
        errors={errorsAdding}
        title="Dodaj grupę kosztów"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Dodaj grupę kosztów"
      />

      <CostGroupsModal
        showModal={editedCostGroup !== null}
        handleClose={() => dispatch(hideEditingCostGroupModalAction())}
        data={editedCostGroup}
        setData={data => dispatch(updateEditingCostGroupModalAction(data))}
        onSubmit={() => dispatch(updateCostGroupAction(editedCostGroup))}
        errors={errorsUpdating}
        title="Edytuj grupę kosztów"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Zapisz grupę kosztów"
      />



      <StyledWrapper>
      <ContainerHeading>
        <Heading h1 as='h1'>Grupy kosztów</Heading>
        <InstructionLink link="/" tooltipText="Zobacz filmik instruktażowy." />
      </ContainerHeading>
        <StyledContainer>
          <ContainerTop>
            <Tooltip title="Dodaj grupę kosztów">
              <AddItem onClick={handleOpen}>
                <MdOutlineAddCircleOutline
                  color='#000'
                  size='20px'
                />
                <span>Dodaj grupę kosztów</span>
              </AddItem>
            </Tooltip>
          </ContainerTop>
          <ContainerItems>
           {busy ? <SectionLoader/> :
            <>
              {costGroups.map((costGroup) => {
                return (
                  <ContainerItem key={costGroup.id}>
                    <LeftSideInformation>
                      <span>{costGroup.name}</span>
                    </LeftSideInformation>
                    <RightSideEdit>
                      {costGroup.user && <>
                        <Tooltip title="Edytuj grupę kosztów">
                          <IconButton
                            onClick={() => {
                              dispatch(showEditingCostGroupModalAction(costGroup))
                            }}
                          >
                            <BsPen color='#000' size='17px'/>
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Usuń grupę kosztów">
                          <IconButton
                            onClick={() => {
                              dispatch(removeCostGroupAction(costGroup.id))
                            }}
                          >
                            <BsTrash
                              color='#000'
                              size='17px'
                            />
                          </IconButton>
                        </Tooltip>
                      </>}
                  </RightSideEdit>
                </ContainerItem>
                )
              })}
            </>
          }
          </ContainerItems>


        </StyledContainer>
      </StyledWrapper>
    </>
  )
}

