import React, {useEffect} from 'react'
import styled from 'styled-components'

import image from '../../images/gabinet-stomatologiczny.jpg'
import {device} from '../../utils/breakpoints'

import {Wrapper} from '../common/wrapper'
import {Container} from '../common/container'


import {RegistrationSide} from './registration-side'
import {useNavigate} from "react-router-dom"
import {useSelector} from "react-redux"

import { Loader } from '../common/loader'


const StyledWrapper = styled(Wrapper)`
  min-height: 100vh;
  height: 100%;
  width: 100%;


`

const ContianerImage = styled.div `
  @media ${device.laptop} {
    position: relative;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`


const StyledContainer = styled(Container)`
  background: ${({theme}) => theme.colors.white};
  display: grid;
  justify-items: center;
  grid-template-columns: auto;
  width: 100%;
  height: 100%;
  position: relative;

  .image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    
  }

  :after {
    content: '';
    display: block;
    position: absolute;
    background: rgba(255, 255, 255, 0.97);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    @media ${device.laptop} {
      display: none;
    }
  }

  @media ${device.laptop} {
    grid-template-columns: 1fr 2fr;
  }
`


const Registration = () => {

  document.location = "https://app.easycart.pl/checkout/6721340/applikacja?promo=1"

  let navigate = useNavigate();
  const {loggedIn, busy} = useSelector(state => state.auth)

  useEffect(() => {
    if (loggedIn) {
      navigate('/kalkulator')
    }
  }, [loggedIn])

  return (

    <StyledWrapper>
      {busy && <Loader />}
      <StyledContainer>
        <RegistrationSide/>
        <ContianerImage>
          <img className='image' src={image} title="Gabinet stomatologiczny" alt="Gabinet stomatologiczny"/>
        </ContianerImage>
        
      </StyledContainer>
    </StyledWrapper>
  )

}

export default Registration


