import React from 'react'

import styled from 'styled-components'
import { device } from '../../utils/breakpoints'

import { Wrapper } from '../common/wrapper'
import { Container } from '../common/container'
import { Sidebar } from '../common/sidebar'
import { NotFound } from './not-found'
import { MobileMenu } from '../common/mobileMenu'

/* eslint-disable */


const StyledWrapper = styled(Wrapper) `
    width: 100%;
    background: ${({ theme }) => theme.colors.lightGray};

    @media ${device.laptop} {
        padding: 0.7rem;
    }
`
const StyledContainer = styled(Container) `
    display: grid;
    grid-template-columns: 1fr;
    gap: 0.7rem;
    position: relative;

    @media ${device.laptop} {
        grid-template-columns: 350px auto;
    }
`

const ContainerRightSide = styled.div `


    @media ${device.laptop} {
        display: grid;
        grid-column: 2;
        align-content: start;
    }
`



const NotFoundPage = () => {

    return (
        <StyledWrapper>
            <StyledContainer>
                <MobileMenu/>
                <Sidebar/>
                <ContainerRightSide>
                    <NotFound/>
                </ContainerRightSide>
            </StyledContainer>
        </StyledWrapper>
    )
}

export default NotFoundPage