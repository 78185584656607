
export const theme = {
    fonts: {
      size: {
        xxxs: '.7rem',
        xxs: '.8rem',
        xs: '.9rem',
        s: '1rem',
        m: '1.2rem',
        l: '1.4rem',
        xl: '1.7rem',
        xxl: '2.3rem',
        xxxl: '3.2rem',
        xxxxl: '4.5rem',
      }
    },
    
    colors: {
      white: '#fff',
      black: '#221F1F',
      lightBlack: '#6D6D6D',
      red: '#D22416',
      gray: '#F0F0F0',
      lightGray: '#F9F9F9',
      green: '#469546',
      


        
    },
  }
  
  