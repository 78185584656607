import React from 'react'
import styled from 'styled-components'
import {Autocomplete, TextField} from "@mui/material"

const StyledAutocomplete = styled(Autocomplete) `
width: 100%;


`
const StyledTextField = styled(TextField) `
  width: 100% !important;

`


function ChooseEntityInput({entities, propertyName, propertyIndex, onChange, label, value, propertySeparator = ' '}) {
  entities = entities.filter(item => item.name.length > 1)
  console.log({entities});
  const getEntity = id => {
    for (let i = 0; i < entities.length; i++) {
      if (entities[i].id === id) {
        return entities[i]
      }
    }
    return null
  }
  const optionLabel = (option, nested = false) => {
    console.log({option})
    console.log({propertyName})
    let val = option[propertyName]
    if (Array.isArray(propertyName)) {
      val = ''
      for (let i = 0; i < propertyName.length; i++) {
        const a = option[propertyName[i]]
        if(a !== undefined) {
          val += a
          if (i !== propertyName.length - 1) val += propertySeparator
        }
      }
    }
    if (!nested && (val === undefined || val.length === 0)) {
      return optionLabel(getEntity(option), true)
    }
    return val
  }
  return (
    <StyledAutocomplete
      noOptionsText='Brak opcji'
      options={entities}
      getOptionLabel={optionLabel}
      onChange={(event, newValue) => {
        onChange(newValue ? newValue[propertyIndex] : newValue);
      }}
      value={value ? (value.id ?? value) : null}
      isOptionEqualToValue={(option, value) => {
        return option[propertyIndex] === value
      }}
      clearOnEscape
      renderInput={(params) => (
        <StyledTextField {...params} label={label} id="outlined-basic" variant="outlined"/>
      )}
    />
  );
}

export default ChooseEntityInput;
