import {
  COST_TYPE_GET_ACTION_BEGIN,
  COST_TYPE_GET_ACTION_FAILED,
  COST_TYPE_GET_ACTION_REQUESTED,
  COST_TYPE_GET_ACTION_SUCCEEDED,
  COST_TYPE_ADD_ACTION_BEGIN,
  COST_TYPE_ADD_ACTION_FAILED,
  COST_TYPE_ADD_ACTION_REQUESTED,
  COST_TYPE_ADD_ACTION_SUCCEEDED,
  COST_TYPE_REMOVE_ACTION_BEGIN,
  COST_TYPE_REMOVE_ACTION_FAILED,
  COST_TYPE_REMOVE_ACTION_REQUESTED,
  COST_TYPE_REMOVE_ACTION_SUCCEEDED,
  COST_TYPE_UPDATE_ACTION_BEGIN,
  COST_TYPE_UPDATE_ACTION_FAILED,
  COST_TYPE_UPDATE_ACTION_REQUESTED,
  COST_TYPE_UPDATE_ACTION_SUCCEEDED,
  COST_TYPE_SAVE_VALUE_ACTION_REQUESTED,
  COST_TYPE_SAVE_VALUE_ACTION_BEGIN,
  COST_TYPE_SAVE_VALUE_ACTION_SUCCEEDED,
  COST_TYPE_SAVE_VALUE_ACTION_FAILED
} from "../constants/actions";
import costTypeService from "../features/costType/costTypeService";
import {put, takeEvery, select} from "redux-saga/effects";
import {unexpectedError} from "../features/client";

function* addCostType(action) {
  const busy = yield select((state) => state.costType.busyAdding)
  if (!busy) {
    yield put({type: COST_TYPE_ADD_ACTION_BEGIN})
    const costTypeResponse = yield costTypeService.addCostType(action.payload)
    if (costTypeResponse.id !== undefined) {
      yield put({type: COST_TYPE_ADD_ACTION_SUCCEEDED, payload: costTypeResponse})
    } else {
      yield put({type: COST_TYPE_ADD_ACTION_FAILED, payload: costTypeResponse})
    }
  }
}

function* updateCostType(action) {
  const busy = yield select((state) => state.costType.busyUpdating)
  if (!busy) {
    yield put({type: COST_TYPE_UPDATE_ACTION_BEGIN})
    const costTypeResponse = yield costTypeService.updateCostType(action.payload)
    if (costTypeResponse.id !== undefined) {
      yield put({type: COST_TYPE_UPDATE_ACTION_SUCCEEDED, payload: costTypeResponse})
    } else {
      yield put({type: COST_TYPE_UPDATE_ACTION_FAILED, payload: costTypeResponse})
    }
  }
}

function* saveValueCostType(action) {
  const costType = action.payload
  const busy = yield select((state) => state.costType.busyCostTypeValues.includes(costType.id))
  if (!busy) {
    yield put({type: COST_TYPE_SAVE_VALUE_ACTION_BEGIN, payload: {costType}})
    const costTypeResponse = yield costTypeService.updateCostType(costType)
    if (costTypeResponse.id !== undefined) {
      yield put({type: COST_TYPE_SAVE_VALUE_ACTION_SUCCEEDED, payload: costTypeResponse})
    } else {
      yield put({type: COST_TYPE_SAVE_VALUE_ACTION_FAILED,
        payload: {
          id: costType.id,
          errors: (costTypeResponse.value ?? costTypeResponse.default) ?? unexpectedError.default
        }
      })
    }
  }
}

function* removeCostType(action) {
  const busy = yield select((state) => state.costType.busyRemoving)
  if (!busy) {
    yield put({type: COST_TYPE_REMOVE_ACTION_BEGIN})
    const costTypeResponse = yield costTypeService.removeCostType(action.payload)
    if (costTypeResponse === true) {
      yield put({type: COST_TYPE_REMOVE_ACTION_SUCCEEDED, payload: action.payload})
    } else {
      yield put({type: COST_TYPE_REMOVE_ACTION_FAILED, payload: costTypeResponse})
    }
  }
}

function* getCostTypes(action) {
  const busy = yield select((state) => state.costType.busy)
  if (!busy) {
    yield put({type: COST_TYPE_GET_ACTION_BEGIN})
    const costTypeResponse = yield costTypeService.getCostTypes(action.payload.month)
    if (costTypeResponse.length !== undefined) {
      yield put({type: COST_TYPE_GET_ACTION_SUCCEEDED, payload: {items: costTypeResponse, month: action.payload.month}})
    } else {
      yield put({type: COST_TYPE_GET_ACTION_FAILED, payload: costTypeResponse})
    }
  }
}

export default function* costTypeSaga() {
  yield takeEvery(COST_TYPE_ADD_ACTION_REQUESTED, addCostType)
  yield takeEvery(COST_TYPE_UPDATE_ACTION_REQUESTED, updateCostType)
  yield takeEvery(COST_TYPE_SAVE_VALUE_ACTION_REQUESTED, saveValueCostType)
  yield takeEvery(COST_TYPE_REMOVE_ACTION_REQUESTED, removeCostType)
  yield takeEvery(COST_TYPE_GET_ACTION_REQUESTED, getCostTypes)
}
