import {
    COST_GROUP_GET_ACTION_REQUESTED,
    COST_GROUP_ADD_ACTION_REQUESTED,
    COST_GROUP_REMOVE_ACTION_REQUESTED,
    COST_GROUP_UPDATE_ACTION_REQUESTED,
    COST_GROUP_SHOW_ADDING_MODAL_ACTION,
    COST_GROUP_HIDE_ADDING_MODAL_ACTION,
    COST_GROUP_UPDATE_ADDING_MODAL_ACTION,
    COST_GROUP_SHOW_EDITING_MODAL_ACTION,
    COST_GROUP_HIDE_EDITING_MODAL_ACTION,
    COST_GROUP_UPDATE_EDITING_MODAL_ACTION
} from "../constants/actions";


function getCostGroupsAction() {
    return {
        type: COST_GROUP_GET_ACTION_REQUESTED,
    }
}


function addCostGroupAction(name) {
    return {
        type: COST_GROUP_ADD_ACTION_REQUESTED,
        payload: {name}
    }
}

function removeCostGroupAction(id) {
    return {
        type: COST_GROUP_REMOVE_ACTION_REQUESTED,
        payload: id
    }
}

function updateCostGroupAction(costGroup) {
    return {
        type: COST_GROUP_UPDATE_ACTION_REQUESTED,
        payload: costGroup
    }
}

function showAddingCostGroupModalAction() {
    return {
        type:  COST_GROUP_SHOW_ADDING_MODAL_ACTION,
    }
}

function hideAddingCostGroupModalAction() {
    return {
        type:  COST_GROUP_HIDE_ADDING_MODAL_ACTION
    }
}

function updateAddingCostGroupModalAction(data) {
    return {
        type:  COST_GROUP_UPDATE_ADDING_MODAL_ACTION,
        payload: data,
    }
}

function showEditingCostGroupModalAction(costGroup) {
    return {
        type:  COST_GROUP_SHOW_EDITING_MODAL_ACTION,
        payload: costGroup,
    }
}

function hideEditingCostGroupModalAction() {
    return {
        type:  COST_GROUP_HIDE_EDITING_MODAL_ACTION
    }
}

function updateEditingCostGroupModalAction(data) {
    return {
        type:  COST_GROUP_UPDATE_EDITING_MODAL_ACTION,
        payload: data,
    }
}



export const costGroupActionCreators = {
    getCostGroupsAction,
    addCostGroupAction,
    removeCostGroupAction,
    updateCostGroupAction,
    showAddingCostGroupModalAction,
    hideAddingCostGroupModalAction,
    updateAddingCostGroupModalAction,
    showEditingCostGroupModalAction,
    hideEditingCostGroupModalAction,
    updateEditingCostGroupModalAction
}
