import React from 'react'
import styled from 'styled-components'
import logo from '../../../images/logo-dents-play.png'

import CircularProgress from '@mui/material/CircularProgress'

const ContainerLoader = styled.div `
    position: fixed;
    display: grid;
    grid-template-rows: repeat(2, auto);
    align-content: center;
    justify-items: center;
    gap: 4rem;
    top: 0%;
    left: 0%;
    bottom: 0%;
    width: 100%;
    height: 100vh;
    z-index: 10000;
    background: white;

    .css-18lrjg1-MuiCircularProgress-root {
        color: ${({theme}) => theme.colors.red};
    }
`

const ContainerLogo = styled.div`
    width: 220px;
    text-align: center;
`


export const Loader = () => (
    
    <ContainerLoader>
        <CircularProgress/>
        <ContainerLogo>

            <img  className='image' src={logo} title="Logo" alt="Logo" />

        </ContainerLogo>
    </ContainerLoader>
    )
