

import {
    FORGET_PASSWORD_ACTION_BEGIN,
    FORGET_PASSWORD_ACTION_SUCCEEDED,
    FORGET_PASSWORD_ACTION_FAILED,
    RESET_PASSWORD_ACTION_BEGIN,
    RESET_PASSWORD_ACTION_SUCCEEDED,
    RESET_PASSWORD_ACTION_FAILED,
    LOGIN_ACTION_BEGIN,
    LOGIN_ACTION_FAILED,
    LOGIN_ACTION_SUCCEEDED,
    LOGIN_FACEBOOK_ACTION_BEGIN,
    LOGIN_FACEBOOK_ACTION_FAILED,
    LOGIN_FACEBOOK_ACTION_SUCCEEDED,
    LOGIN_GOOGLE_ACTION_BEGIN,
    LOGIN_GOOGLE_ACTION_FAILED,
    LOGIN_GOOGLE_ACTION_SUCCEEDED,
    LOGIN_INFO_ACTION_BEGIN,
    LOGIN_INFO_ACTION_FAILED,
    LOGIN_INFO_ACTION_SUCCEEDED,
    LOGOUT_ACTION_BEGIN,
    LOGOUT_ACTION_FAILED,
    LOGOUT_ACTION_SUCCEEDED,
    REGISTER_ACTION_BEGIN,
    REGISTER_ACTION_FAILED,
    REGISTER_ACTION_SUCCEEDED
} from "../constants/actions";

export const initialState = {
    user: null,
    busy: false,
    loggedIn: false,
    errorsLoginInfo: {},
    errorsLogout: {},
    errorsLogin: {},
    errorsRegister: {},
    errorsForgetPassword: {},
    errorsResetPassword: {},

}

export const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_ACTION_BEGIN:
        case LOGIN_FACEBOOK_ACTION_BEGIN:
        case LOGIN_GOOGLE_ACTION_BEGIN:
            return {
                ...state,
                busy: true,
                errorsLogin: {},
            }
        case LOGOUT_ACTION_BEGIN:
            return {
                ...state,
                busy: true,
                errorsLogout: {},
            }
        case LOGIN_INFO_ACTION_BEGIN:
            return {
                ...state,
                busy: true,
                errorsLoginInfo: {},
            }
        case REGISTER_ACTION_BEGIN:
            return {
                ...state,
                busy: true,
                errorsRegister: {},
            }
        case FORGET_PASSWORD_ACTION_BEGIN:
            return {
                ...state,
                busy: true,
                errorsForgetPassword: {},
            }
        case RESET_PASSWORD_ACTION_BEGIN:
            return {
                ...state,
                busy: true,
                errorsResetPassword: {},
            }
        case LOGIN_ACTION_SUCCEEDED:
        case LOGIN_FACEBOOK_ACTION_SUCCEEDED:
        case LOGIN_GOOGLE_ACTION_SUCCEEDED:
        case REGISTER_ACTION_SUCCEEDED:
        case LOGIN_INFO_ACTION_SUCCEEDED:
            return {
                ...state,
                busy: false,
                loggedIn: true,
                user: action.payload
            };
        case LOGOUT_ACTION_SUCCEEDED:
            return {
                ...state,
                busy: false,
                user: null,
                loggedIn: false,
            };

        case FORGET_PASSWORD_ACTION_SUCCEEDED:
            return {
                ...state,
                busy: false,
            }
        case RESET_PASSWORD_ACTION_SUCCEEDED:
            return {
                ...state,
                busy: false,
            }
        case LOGIN_ACTION_FAILED:
        case LOGIN_FACEBOOK_ACTION_FAILED:
        case LOGIN_GOOGLE_ACTION_FAILED:
            return {
                ...state,
                errorsLogin: action.payload,
                busy: false,
            };
        case LOGOUT_ACTION_FAILED:
            return {
                ...state,
                errorsLogout: action.payload,
                busy: false,
            };
        case REGISTER_ACTION_FAILED:
            return {
                ...state,
                errorsRegister: action.payload,
                busy: false,
            };
        case LOGIN_INFO_ACTION_FAILED:
            return {
                ...state,
                errorsLoginInfo: action.payload,
                busy: false,
                user: null,
                loggedIn: false,
            };
        case FORGET_PASSWORD_ACTION_FAILED:
            return {
                ...state,
                busy: false,
                errorsForgetPassword: action.payload,
            }
        case RESET_PASSWORD_ACTION_FAILED:
            return {
                ...state,
                busy: false,
                errorsResetPassword: action.payload,
            }
        default:
            return state;
    }
}
