/* eslint-disable */
import React, { useEffect } from 'react'
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'
import {SectionLoader} from '../common/sectionLoader'
import {useDispatch, useSelector} from 'react-redux'
import { MdOutlineAddCircleOutline } from "react-icons/md"
import {BranchesModal} from '../common/modal/branches-modal'
import {branchActionCreators} from "../../actions/branchActionCreators"
import {AddItemButton} from '../common/addItemButton'
import {EditItem} from '../common/editItem'
import {DeleteItem} from '../common/deleteItem'
import {InstructionLink} from '../common/instructionLink'

const {
    getBranchesAction,
    addBranchAction,
    removeBranchAction,
    updateBranchAction,
    showAddingBranchModalAction,
    hideAddingBranchModalAction,
    updateAddingBranchModalAction,
    showEditingBranchModalAction,
    hideEditingBranchModalAction,
    updateEditingBranchModalAction
} = branchActionCreators




const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  align-content: start;
  gap: 3rem;
`


const ContainerItems = styled.div `
  display: grid;
  grid-template-columns: auto;
  align-content: start;
  position: relative;
  gap: 2rem;
  min-height: 150px;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ContainerItem = styled.div `
  display: grid;
  min-width: 250px;
  width: 100%;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  background: ${({theme}) => theme.colors.lightGray};
  padding: 1rem;
  border-radius: 4px; 
`

const LeftSideInformation = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
    line-height: 1.3;
  }
`

const RightSideEdit = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
  }
`

const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
`
const ContainerTop = styled.div `
  display: grid;
  align-items: start;
  justify-items: start;
`

const ContainerHeading = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0%   {transform: scale(.7);}
    50%  {transform: scale(1);}
    100% {transform: scale(.7);}
  }

`

export const Branches = () => {

  const {
    errors,
    errorsRemoving,
    errorsAdding,
    errorsUpdating,
    addingBranchModal,
    addingBranch,
    editedBranch,
    branches,
    busy,
    busyAdding,
    busyUpdating,
    busyRemoving
  } = useSelector(
    (state) => state.branch
  )

  const dispatch = useDispatch()
  const handleClose = () => dispatch(hideAddingBranchModalAction())
  const handleOpen = () => dispatch(showAddingBranchModalAction())

  useEffect(() => {
    dispatch(getBranchesAction())
  }, [])

  return (
    <>
      <BranchesModal
        showModal={addingBranchModal}
        handleClose={handleClose}
        data={addingBranch}
        setData={data => dispatch(updateAddingBranchModalAction(data))}
        onSubmit={() => dispatch(addBranchAction(addingBranch.name))}
        errors={errorsAdding}
        title="Dodaj dziedzinę"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Dodaj dziedzinę"
      />

      <BranchesModal
        showModal={editedBranch !== null}
        handleClose={() => dispatch(hideEditingBranchModalAction())}
        data={editedBranch}
        setData={data => dispatch(updateEditingBranchModalAction(data))}
        onSubmit={() => dispatch(updateBranchAction(editedBranch))}
        errors={errorsUpdating}
        title="Edytuj dziedzinę"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Zapisz dziedzinę"
      />

      <StyledWrapper>
        <ContainerHeading>
          <Heading h1 as='h1'>Dziedziny</Heading>
          <InstructionLink link="https://dentsplay.pl/2022/10/09/zakladka-dziedziny/" tooltipText="Zobacz filmik instruktażowy." />
        </ContainerHeading>
        <Line/>
        <StyledContainer>
          <ContainerTop>
            <AddItemButton
              textButton='Dodaj dziedzinę'
              tooltipText='Dodaj dziedzinę'
              handleOpen={handleOpen}
            />
          </ContainerTop>
          <ContainerItems>
            {busy ? <SectionLoader/> :
              <>
                {branches.map((branch) => {
                  return (
                    <ContainerItem key={branch.id}>
                      <LeftSideInformation>
                        <span>{branch.name}</span>
                      </LeftSideInformation>
                      <RightSideEdit>
                        {branch.user && <>
                          <EditItem
                            handleEdit={() => {dispatch(showEditingBranchModalAction(branch))}}
                            tooltipText="Edytuj dziedzinę"
                          />
                          <DeleteItem
                            handleDelete={() => {dispatch(removeBranchAction(branch.id))}}
                            tooltipText="Usuń dziedzinę"
                          />
                        </>}
                    </RightSideEdit>
                  </ContainerItem>
                  )
                })}
              </>
            }
          </ContainerItems>
        </StyledContainer>
      </StyledWrapper>
    </>
  )
}

