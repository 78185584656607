import React from 'react'
import styled from 'styled-components'
import Tooltip from '@mui/material/Tooltip'
import {BsFillPlayFill} from 'react-icons/bs'

export const AddItem = styled.div `
    cursor: pointer;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(2, auto);
    gap: 2rem;
    background: rgb(210, 36, 22, 0.04);
    color: ${({theme}) => theme.colors.red};
    border-radius: 3px;
`



export const LinkVideo = styled.a `
    border-radius: 110px;
    display: grid;
    cursor: pointer;
    align-items: center;
    justify-items: center;
    background: ${({ theme }) => theme.colors.red};
    width: 40px;
    height: 40px;
    animation: zoom-in-zoom-out 2s ease-out infinite;

    @keyframes zoom-in-zoom-out {
        0%   {transform: scale(.7);}
        50%  {transform: scale(1);}
        100% {transform: scale(.7);}
    }
`

export const InstructionLink = ({link, tooltipText}) => (
    <Tooltip title={tooltipText} enterTouchDelay={0}>
        <LinkVideo href={link}>
            <BsFillPlayFill
                size={30}
                color='#fff'
            />
        </LinkVideo>
    </Tooltip>
)


