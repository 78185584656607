/* eslint-disable */
import React, {useEffect} from 'react'
import styled, {css} from 'styled-components'
import {ChangePassword} from './accountSettings/change-pass'
import {ChangeMail} from './accountSettings/change-mail'
import {settingsActionCreators} from "../../actions/settingsActionCreators"
import {useDispatch, useSelector} from "react-redux"
import {SectionLoader} from '../common/sectionLoader'

const WrapperGeneralSettings = styled.div`

`

const ContainerSettings = styled.div`
  display: grid;
  gap: 3rem;
  justify-content: start;
  position: relative;
  min-height: 150px;
`

const {getSettingsAction} = settingsActionCreators

export const GeneralSettings = () => {

  const dispatch = useDispatch()
  const busy = useSelector(state => state.settings.busy)
  useEffect(() => {
    dispatch(getSettingsAction())
  }, [])

  return (
    <WrapperGeneralSettings>
      <ContainerSettings>
      {busy ? <SectionLoader/> :
      <>
        <ChangePassword/>
        <ChangeMail/>
      </>
      }
      </ContainerSettings>
    </WrapperGeneralSettings>
  )
}
