import {apiRequest, unexpectedError} from "../client";

const addMonthStat = async (monthStat) => {
  const response = await apiRequest('POST', 'api/monthStats', monthStat)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const updateMonthStat = async (monthStat) => {
  const response = await apiRequest('PATCH', 'api/monthStats', monthStat)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const removeMonthStat = async (id) => {
  const response = await apiRequest('DELETE', 'api/monthStats', {id})

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}

const getMonthStats = async (date = null) => {
  const response = await apiRequest('GET', 'api/monthStats', date ? {date} : {})

  if (response.status === 200) {
    const {success, errors, items, item} = response.data
    if (success) {
      return items ?? item
    }
    return errors
  }

  return unexpectedError
}

const monthStatService = {
  getMonthStats,
  updateMonthStat,
  removeMonthStat,
  addMonthStat,
}

export default monthStatService
