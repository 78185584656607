import {apiRequest, uploadFile, unexpectedError} from "../client";

const addService = async (service) => {
  const response = await apiRequest('POST', 'api/services', service)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const uploadService = async (service) => {
  console.log({service})
  const response = await uploadFile('api/services/excel', service);
  if (response.status === 200) {
    return response.data;
  }
  return unexpectedError;
}

const updateService = async (service) => {
  const response = await apiRequest('PATCH', 'api/services', service)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const removeService = async (id) => {
  const response = await apiRequest('DELETE', 'api/services', {id})

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}

const getServices = async (date) => {
  const response = await apiRequest('GET', 'api/services', {date})

  if (response.status === 200) {
    const {success, errors, items} = response.data
    if (success) {
      return items
    }
    return errors
  }

  return unexpectedError
}

const serviceService = {
  getServices,
  updateService,
  removeService,
  addService,
  uploadService
}

export default serviceService
