import {
    BRANCH_GET_ACTION_REQUESTED,
    BRANCH_ADD_ACTION_REQUESTED,
    BRANCH_REMOVE_ACTION_REQUESTED,
    BRANCH_UPDATE_ACTION_REQUESTED,
    BRANCH_SHOW_ADDING_MODAL_ACTION,
    BRANCH_HIDE_ADDING_MODAL_ACTION,
    BRANCH_UPDATE_ADDING_MODAL_ACTION,
    BRANCH_SHOW_EDITING_MODAL_ACTION,
    BRANCH_HIDE_EDITING_MODAL_ACTION,
    BRANCH_UPDATE_EDITING_MODAL_ACTION
} from "../constants/actions";


function getBranchesAction() {
    return {
        type: BRANCH_GET_ACTION_REQUESTED,
    }
}


function addBranchAction(name) {
    return {
        type: BRANCH_ADD_ACTION_REQUESTED,
        payload: {name}
    }
}

function removeBranchAction(id) {
    return {
        type: BRANCH_REMOVE_ACTION_REQUESTED,
        payload: id
    }
}

function updateBranchAction(branch) {
    return {
        type: BRANCH_UPDATE_ACTION_REQUESTED,
        payload: branch
    }
}

function showAddingBranchModalAction() {
    return {
        type:  BRANCH_SHOW_ADDING_MODAL_ACTION,
    }
}

function hideAddingBranchModalAction() {
    return {
        type:  BRANCH_HIDE_ADDING_MODAL_ACTION
    }
}

function updateAddingBranchModalAction(data) {
    return {
        type:  BRANCH_UPDATE_ADDING_MODAL_ACTION,
        payload: data,
    }
}

function showEditingBranchModalAction(branch) {
    return {
        type:  BRANCH_SHOW_EDITING_MODAL_ACTION,
        payload: branch,
    }
}

function hideEditingBranchModalAction() {
    return {
        type:  BRANCH_HIDE_EDITING_MODAL_ACTION
    }
}

function updateEditingBranchModalAction(data) {
    return {
        type:  BRANCH_UPDATE_EDITING_MODAL_ACTION,
        payload: data,
    }
}



export const branchActionCreators = {
    getBranchesAction,
    addBranchAction,
    removeBranchAction,
    updateBranchAction,
    showAddingBranchModalAction,
    hideAddingBranchModalAction,
    updateAddingBranchModalAction,
    showEditingBranchModalAction,
    hideEditingBranchModalAction,
    updateEditingBranchModalAction
}
