import React from 'react'


import styled from 'styled-components'
import { device } from '../../utils/breakpoints'
import { Heading } from '../common/heading'



const WrapperNotFound = styled.div `
    display: grid;
    grid-template-rows: auto;
    height: calc(100vh - 22.4px);
    gap: 2rem;
    align-items: center;
    justify-items: center;
    background: ${({ theme }) => theme.colors.white};
    box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
    display: grid;
    grid-template-columns: auto;
    padding: 2rem 1rem;

    @media ${device.tablet} {
      padding: 2rem 1.5rem;
    }
`
const ContainerNotFound = styled.div `
    display: grid;
    gap: 1.5rem;
    grid-template-rows: repeat(2, auto);
    align-items: center;
    justify-items: center;
`


export const NotFound = () => {

    return (
        <WrapperNotFound>
            <ContainerNotFound>
                <Heading h1 as='h1'>404</Heading>
                <p>Strona nie została znaleziona</p>            
            </ContainerNotFound>
        </WrapperNotFound>
    )
}