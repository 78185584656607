/* eslint-disable */
import React, {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'

import {SectionLoader} from '../common/sectionLoader'

import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'
import {useDispatch, useSelector} from "react-redux"
import {SelectCostVariables} from './selectCostVariables'
import {MdOutlineAddCircleOutline} from "react-icons/md"
import {ContainerInput} from '../common/containerInput'
import FormControl from '@mui/material/FormControl'
import {ASSIGNED_COST_TYPE, UNASSIGNED_COST_TYPE} from "../../constants/costTypeDict";
import {BasicCalculator} from './calculators/basicCalculator'
import {AiFillLock} from 'react-icons/ai'
import ChooseEntityInput from "../common/input/ChooseEntityInput";
import {calculatorSettings} from "../../constants/calculatorSettings";
import {useLocation} from "react-router-dom";
import {serviceActionCreators} from "../../actions/serviceActionCreators";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import {costTypeActionCreators} from "../../actions/costTypeActionCreators";
import {monthStatActionCreators} from "../../actions/monthStatActionCreators";
import {InstructionLink} from "../common/instructionLink";


const WrapperCalculator = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;

  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }



`

const StyledContainerInput = styled(ContainerInput)`
  display: grid;
  grid-template-rows: auto;
  gap: 1rem;
  position: relative;
  justify-content: start;
  align-content: start;

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    min-width: 300px !important;
  }

  .css-feqhe6 {
    min-width: 300px !important;
  }

  .css-13sljp9 {
    min-width: 300px !important;
  }


  ${({nolabel}) =>
          nolabel &&
          css`
            grid-template-rows: auto;
          `}
  span {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.lightBlack};
    font-weight: 700;
    font-size: ${({theme}) => theme.fonts.size.xxs};
  }

`

const StyledContainerInputCalculator = styled(ContainerInput)`
  width: 100%;
  display: grid;
  grid-template-rows: auto;
  gap: 1rem;
  position: relative;
  justify-items: start;
  align-content: start;


  ${({nolabel}) =>
          nolabel &&
          css`
            grid-template-rows: auto;
          `}
  span {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.lightBlack};
    font-weight: 700;
    font-size: ${({theme}) => theme.fonts.size.xxs};
  }

  grid-row: 1;

  @media ${device.laptopL} {
    grid-column: 2;
  }

`

const ContainerHeading = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(.7);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.7);
    }
  }

`

const CalculatorRange = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;

  .active {
    background: rgb(210, 36, 22, 0.04);
  }

  span {
    font-size: ${({theme}) => theme.fonts.size.xs};
    border: 1px solid ${({theme}) => theme.colors.red};
    color: ${({theme}) => theme.colors.black};
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    text-transform: lowercase;
  }

  @media ${device.mobileL} {
    grid-template-columns: repeat(3, auto);
  }
`


const ContainerChooseAndExportCalculator = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1.5rem;
  align-items: end;
  justify-items: start;
`

const ContainerCalculatorAndCosts = styled.div`
  display: grid;
  position: relative;
  grid-template-columns: auto;
  gap: 2rem;
  align-items: start;
  min-height: 150px;

`


const ContainerCalculator = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 2rem;
  width: 100%;
  grid-row: 2;


  @media ${device.laptopL} {
    grid-column: 1;
    grid-row: 1;
  }
`
const ContainerBottom = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-content: start;
  gap: 1.5rem;
  padding: 0.5rem;
  position: relative;

  @media ${device.laptopL} {
    grid-template-columns: repeat(2, auto);
  }
`


const DisabledCalculator = styled.div `
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  width: 100%;
  height: 100%;
  background: rgba(109, 109, 109, 0.7);
  z-index: 100;
  border-radius: 5px;
  display: grid;
  align-content: center;
  justify-items: center;
  
`

const HeadingDisabledCalculator = styled(Heading) `
  color: white;
  z-index: 101;


`

const AddItem = styled.button`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
  border: none;
  font-size: inherit;
`

const ContainerButtonSelect = styled.div`
  display: grid;
  justify-content: start;
  align-items: flex-end;
  grid-template-columns: auto;
  gap: 1rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, auto);
  }
`
const {saveServiceValueAction} = serviceActionCreators
const {getMonthStatAction} = monthStatActionCreators
const {
  updateCostTypeMonthAction,
  getCostTypesAction
} = costTypeActionCreators

export const Calculator = () => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [calculator, setCalculator] = useState(1);
  const [calculatorType, setCalculatorType] = useState(1);
  const [hourPrice, setHourPrice] = useState(0)
  const [hourProfit, setHourProfit] = useState(0)
  const [assignedCosts, setAssignedCosts] = useState({})
  const [data, setData] = useState({
    price: 0,
    time: 0,
    salary: 0,
    costs: location.state ? (location.state.costsPerHour ?? 0) : 0
  })

  const {
    costTypes,
    costTypeValues,
    month,
    busy,
  } = useSelector(
    (state) => state.costType
  )
  const {
    services,
  } = useSelector(
    (state) => state.service
  )
  const {
    monthStat,
  } = useSelector(
    (state) => state.monthStat
  )
  const [service, setService] = useState(null);

  const getCostType = id => {
    for (let i = 0; i < costTypes.length; i++) {
      let costType = costTypes[i]
      if (costType.id == id) return costType
    }
    return false
  }

  const onChangeService = serviceId => {
    if (service !== serviceId) {
      setService(serviceId)
      const serviceObject = serviceId !== null ? services.filter(s => s.id === serviceId)[0] : null
      if (serviceObject) {
        let ac = {}
        for (let i = 0; i < serviceObject.servicePriceCosts.length; i++) {
          const cost = serviceObject.servicePriceCosts[i]
          ac[cost.parentId] = parseFloat(cost.price)
        }
        setData({
          ...data,
          price: parseFloat(serviceObject.value ?? 0),
          time: parseInt(serviceObject.time ?? 0),
          salary: parseInt(serviceObject.salary ?? 0),
        })
        setAssignedCosts(ac)
      }
    }
  }
  const savePrice = () => {
    const serviceObject = service !== null ? services.filter(s => s.id === service)[0] : null
    if (serviceObject !== null) {
      let servicePriceCosts = {}
      for (const [parentId, value] of Object.entries(assignedCosts)) {
        if (assignedCosts.hasOwnProperty(parentId)) {
          if ((value + "").length > 0 && !isNaN(value)) {
            const cost = getCostType(parentId)
            if (cost) {
              servicePriceCosts[parentId] = {
                name: cost.name,
                parentId,
                price: value
              }
            }
          }
        }
      }
      dispatch(saveServiceValueAction(serviceObject, {
        value: data.price,
        time: data.time,
        salary: data.salary,
        costsPerHour: data.costs,
        servicePriceCosts
      }))
    }
  }

  useEffect(() => {
    calculator === 1 && setCalculator(1)
  }, [calculator])
  let assignedCostsSummary = 0
  for (const [parentId, value] of Object.entries(assignedCosts)) {
    if (assignedCosts.hasOwnProperty(parentId)) {
      if ((value + "").length > 0) {
        assignedCostsSummary += parseFloat(isNaN(value) ? 0 : value)
      }
    }
  }
  const changeCalculatorType = type => {
    setCalculatorType(type)
    switch(type) {
      case 1:
        setData({
          ...data,
          price: 0,
          time: 0,
          salary: 0,
        })
        setAssignedCosts({})
        setService(null)
        break;
      case 2:
        setData({
          ...data,
          price: 0,
          time: 0,
          salary: 0,
        })
        setAssignedCosts({})
        break;
    }
  }
  const reloadCosts = (val) => {
    dispatch(getMonthStatAction(val))
    dispatch(getCostTypesAction(val))
  }
  useEffect(() => {
    let unassignedCostsSummary = 0
    for (let i = 0; i < costTypes.length; i++) {
      const costType = costTypes[i]
      if(costType.type === UNASSIGNED_COST_TYPE) {
        unassignedCostsSummary += parseFloat(costTypeValues[costType.id] ? costTypeValues[costType.id] : 0)
      }
    }
    let workedHoursSummary = 0
    for (const [key, value] of Object.entries(monthStat.hoursWorked)) {
      if (key) {
        const addon = parseFloat(value)
        workedHoursSummary += isNaN(addon) ? 0 : addon
      }
    }
    setData({
      ...data,
      costs: workedHoursSummary > 0 ? unassignedCostsSummary / workedHoursSummary : 0,
    })

  }, [monthStat, costTypeValues])
  useEffect(() => reloadCosts(month), [])
  console.log('data', data)


  return (
      <WrapperCalculator>
        <ContainerHeading>
          <Heading h1 as='h1'>Kalkulator</Heading>
          <InstructionLink link='https://dentsplay.pl/2022/10/09/zakladka-kalkulator/' tooltipText="Zobacz filmik instruktażowy."></InstructionLink>
        </ContainerHeading>

        <Line/>
        <ContainerCalculatorAndCosts>
        {busy ? <SectionLoader/> :
            <>
            <ContainerChooseAndExportCalculator>
              <StyledContainerInput>
                <span>Wybierz miesiąc</span>
                <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                  <DatePicker
                    id="month"
                    views={['year', 'month']}
                    renderInput={(props) => <TextField {...props}/>}
                    value={month}
                    onChange={val => dispatch(updateCostTypeMonthAction(val))}
                    onAccept={reloadCosts}
                  />
                </LocalizationProvider>
              </StyledContainerInput>
              <StyledContainerInput>
                <span>Wybierz rodzaj kalkulatora</span>
                <CalculatorRange>
                  <span className={calculatorType === 1 ? 'active' : ''} onClick={() => changeCalculatorType(1)}>Kalkulator podstawowy</span>
                  <span className={calculatorType === 2 ? 'active' : ''} onClick={() => changeCalculatorType(2)}>Kalkulator usług</span>
                </CalculatorRange>
              </StyledContainerInput>
              <StyledContainerInput>
                <span>Wybierz zakres kalkulatora</span>
                <CalculatorRange>
                  <span className={calculator === 1 ? 'active' : ''} onClick={() => setCalculator(1)}>&lt; 4000 zł</span>
                  <span className={calculator === 2 ? 'active' : ''} onClick={() => setCalculator(2)}>&lt; 20000 zł</span>
                  <span className={calculator === 3 ? 'active' : ''} onClick={() => setCalculator(3)}>&lt; 100000 zł</span>
                </CalculatorRange>
              </StyledContainerInput>
              {calculatorType === 2 && <ContainerButtonSelect>
                <StyledContainerInput>
                  <span>Wybierz usługę</span>
                  <FormControl>
                    <ChooseEntityInput
                      label="Wybierz usługę"
                      entities={services}
                      propertyName={'name'}
                      propertyIndex={'id'}
                      value={service}
                      onChange={onChangeService}
                    />
                  </FormControl>

                </StyledContainerInput>
                <AddItem onClick={savePrice} disabled={service === null}>
                  <MdOutlineAddCircleOutline
                    color='#000'
                    size='20px'
                  />
                  <span>Zapisz do cennika</span>
                </AddItem>
              </ContainerButtonSelect>}
            </ContainerChooseAndExportCalculator>

            <ContainerBottom>
            {!service && calculatorType === 2 &&
              <DisabledCalculator>
                <AiFillLock size="60" color="#fff" className="icon"/>
                <HeadingDisabledCalculator h3 as="h3">Najpierw wybierz usługę</HeadingDisabledCalculator>
              </DisabledCalculator>
            }


              <StyledContainerInputCalculator>
                <span>koszty przypisane (zmienne)</span>
                <SelectCostVariables
                  value={assignedCosts}
                  onChange={e => setAssignedCosts({...e.target.value})}
                  costTypes={costTypes.filter(costType => costType.type === ASSIGNED_COST_TYPE && !costType.doctor)}
                  name="visitCosts"
                  disabledCalculator={!service && calculatorType === 2}
                />
              </StyledContainerInputCalculator>


              <ContainerCalculator>
                <BasicCalculator
                  calculator={calculator}
                  stepPrice={calculatorSettings.priceStep[calculator]} 
                  maxPrice={calculatorSettings.priceMax[calculator]}
                  stepTime={calculatorSettings.timeStep[calculator]} 
                  maxTime={calculatorSettings.timeMax[calculator]}
                  marksPrice={calculatorSettings.marksPrice[calculator]}
                  marksTime={calculatorSettings.marksTime[calculator]}
                  marksSalary={calculatorSettings.marksSalary[calculator]}
                  marksCosts={calculatorSettings.marksCosts[calculator]}
                  text1="Cena / Przychód z zabiegu"
                  text2="Czas zabiegu"
                  hourPrice={hourPrice}
                  setHourPrice={setHourPrice}
                  hourProfit={hourProfit}
                  setHourProfit={setHourProfit}
                  data={data}
                  setData={setData}
                  assignedCostsSummary={assignedCostsSummary}
                  disabledCalculator={!service && calculatorType === 2}
                />
              </ContainerCalculator>
            </ContainerBottom>
            </>
        }
        </ContainerCalculatorAndCosts>
      </WrapperCalculator>
  )
}


