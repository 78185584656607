import {apiRequest, unexpectedError} from "../client";

const addPosition = async (position) => {
  const response = await apiRequest('POST', 'api/positions', position)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const updatePosition = async (position) => {
  const response = await apiRequest('PATCH', 'api/positions', position)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const removePosition = async (id) => {
  const response = await apiRequest('DELETE', 'api/positions', {id})

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}

const getPositions = async () => {
  const response = await apiRequest('GET', 'api/positions')

  if (response.status === 200) {
    const {success, errors, items} = response.data
    if (success) {
      return items
    }
    return errors
  }

  return unexpectedError
}

const positionService = {
  getPositions,
  updatePosition,
  removePosition,
  addPosition,
}

export default positionService
