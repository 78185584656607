import {
    BRANCH_GET_ACTION_BEGIN,
    BRANCH_GET_ACTION_FAILED,
    BRANCH_GET_ACTION_REQUESTED,
    BRANCH_GET_ACTION_SUCCEEDED,
    BRANCH_ADD_ACTION_BEGIN,
    BRANCH_ADD_ACTION_FAILED,
    BRANCH_ADD_ACTION_REQUESTED,
    BRANCH_ADD_ACTION_SUCCEEDED,
    BRANCH_REMOVE_ACTION_BEGIN,
    BRANCH_REMOVE_ACTION_FAILED,
    BRANCH_REMOVE_ACTION_REQUESTED,
    BRANCH_REMOVE_ACTION_SUCCEEDED,
    BRANCH_UPDATE_ACTION_BEGIN,
    BRANCH_UPDATE_ACTION_FAILED,
    BRANCH_UPDATE_ACTION_REQUESTED,
    BRANCH_UPDATE_ACTION_SUCCEEDED
} from "../constants/actions";

import branchService from "../features/branch/branchService";
import {put, takeEvery, select} from "redux-saga/effects";

function* addBranch(action) {
    const busy = yield select((state) => state.branch.busyAdding)
    if (!busy) {
        yield put({type:BRANCH_ADD_ACTION_BEGIN})
        const branchResponse = yield branchService.addBranch(action.payload)
        if (branchResponse.id !== undefined) {
            yield put({type:BRANCH_ADD_ACTION_SUCCEEDED, payload: branchResponse})
        } else {
            yield put({type:BRANCH_ADD_ACTION_FAILED, payload: branchResponse})
        }
    }
}

function* updateBranch(action) {
    const busy = yield select((state) => state.branch.busyUpdating)
    if (!busy) {
        yield put({type:BRANCH_UPDATE_ACTION_BEGIN})
        const branchResponse = yield branchService.updateBranch(action.payload)
        if (branchResponse.id !== undefined) {
            yield put({type:BRANCH_UPDATE_ACTION_SUCCEEDED, payload: branchResponse})
        } else {
            yield put({type:BRANCH_UPDATE_ACTION_FAILED, payload: branchResponse})
        }
    }
}

function* removeBranch(action) {
    const busy = yield select((state) => state.branch.busyRemoving)
    if (!busy) {
        yield put({type:BRANCH_REMOVE_ACTION_BEGIN})
        const branchResponse = yield branchService.removeBranch(action.payload)
        if (branchResponse === true) {
            yield put({type:BRANCH_REMOVE_ACTION_SUCCEEDED, payload: action.payload})
        } else {
            yield put({type:BRANCH_REMOVE_ACTION_FAILED, payload: branchResponse})
        }
    }
}

function* getBranches() {
    const busy = yield select((state) => state.branch.busy)
    if (!busy) {
        yield put({type:BRANCH_GET_ACTION_BEGIN})
        const branchResponse = yield branchService.getBranches()
        if (branchResponse.length !== undefined) {
            yield put({type:BRANCH_GET_ACTION_SUCCEEDED, payload: branchResponse})
        } else {
            yield put({type:BRANCH_GET_ACTION_FAILED, payload: branchResponse})
        }
    }
}

export default function* branchSaga() {
    yield takeEvery(BRANCH_ADD_ACTION_REQUESTED, addBranch)
    yield takeEvery(BRANCH_UPDATE_ACTION_REQUESTED, updateBranch)
    yield takeEvery(BRANCH_REMOVE_ACTION_REQUESTED, removeBranch)
    yield takeEvery(BRANCH_GET_ACTION_REQUESTED, getBranches)
}
