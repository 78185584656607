import React, { useEffect } from 'react'

import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {SectionLoader} from '../common/sectionLoader'
import {useDispatch, useSelector} from 'react-redux'
import { MdOutlineAddCircleOutline } from "react-icons/md"
import {PositionsModal} from '../common/modal/positions-modal'
import {positionActionCreators} from "../../actions/positionActionCreators";
import {AddItemButton} from '../common/addItemButton'
import {EditItem} from '../common/editItem'
import {DeleteItem} from '../common/deleteItem'
import {InstructionLink} from '../common/instructionLink'

const {
  getPositionsAction,
  addPositionAction,
  removePositionAction,
  updatePositionAction,
  showAddingPositionModalAction,
  hideAddingPositionModalAction,
  updateAddingPositionModalAction,
  showEditingPositionModalAction,
  hideEditingPositionModalAction,
  updateEditingPositionModalAction
} = positionActionCreators




const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  align-content: start;
  gap: 3rem;
`


const ContainerItems = styled.div `
  display: grid;
  grid-template-columns: auto;
  align-content: start;
  position: relative;
  gap: 2rem;
  min-height: 150px;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ContainerItem = styled.div `
  display: grid;
  min-width: 250px;
  width: 100%;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  background: ${({theme}) => theme.colors.lightGray};
  padding: 1rem;
  border-radius: 4px; 
`

const LeftSideInformation = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
    line-height: 1.3;
  }
`

const RightSideEdit = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
  }
`
const ContainerTop = styled.div `
  display: grid;
  align-items: start;
  justify-items: start;
`

const ContainerHeading = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0%   {transform: scale(.7);}
    50%  {transform: scale(1);}
    100% {transform: scale(.7);}
  }

`


export const Positions = () => {

  const {
    errorsAdding,
    errorsUpdating,
    addingPositionModal,
    addingPosition,
    editedPosition,
    positions,
    busy,
  } = useSelector(
    (state) => state.position
  )

  const dispatch = useDispatch()
  const handleClose = () => dispatch(hideAddingPositionModalAction())
  const handleOpen = () => dispatch(showAddingPositionModalAction())

  useEffect(() => {
    dispatch(getPositionsAction())
  }, [])

  return (
    <>

      <PositionsModal
        showModal={addingPositionModal}
        handleClose={handleClose}
        data={addingPosition}
        setData={data => dispatch(updateAddingPositionModalAction(data))}
        onSubmit={() => dispatch(addPositionAction(addingPosition.name))}
        errors={errorsAdding}
        title="Dodaj stanowisko"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Dodaj stanowisko"
      />

      <PositionsModal
        showModal={editedPosition !== null}
        handleClose={() => dispatch(hideEditingPositionModalAction())}
        data={editedPosition}
        setData={data => dispatch(updateEditingPositionModalAction(data))}
        onSubmit={() => dispatch(updatePositionAction(editedPosition))}
        errors={errorsUpdating}
        title="Edytuj stanowisko"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Zapisz stanowisko"
      />

      <StyledWrapper>
        <ContainerHeading>
          <Heading h1 as='h1'>Stanowiska / unity</Heading>
          <InstructionLink link="https://dentsplay.pl/2022/10/09/zakladka-stanowiska-unity/" tooltipText="Zobacz filmik instruktażowy." />
        </ContainerHeading>
        <StyledContainer>
          <ContainerTop>
            <AddItemButton
              textButton='Dodaj stanowisko / unit'
              tooltipText='Dodaj stanowisko / unit'
              handleOpen={handleOpen}
            />
          </ContainerTop>
          <ContainerItems>
            {busy ? <SectionLoader/> :
            <>
              {positions.map((position) => {
                return (
                  <ContainerItem key={position.id}>
                    <LeftSideInformation>
                      <span>{position.name}</span>
                    </LeftSideInformation>
                    <RightSideEdit>
                      <EditItem
                        handleEdit={() => {dispatch(showEditingPositionModalAction(position))}}
                        tooltipText="Edytuj stanowisko"
                      />
                      <DeleteItem
                        handleDelete={() => {dispatch(removePositionAction(position.id))}}
                        tooltipText="Usuń stanowisko"
                      />
                  </RightSideEdit>
                </ContainerItem>
                )
              })}
            </>
            }
          </ContainerItems>
        </StyledContainer>
      </StyledWrapper>
    </>
  )
}

