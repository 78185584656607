import React, { useEffect } from 'react'

/* eslint-disable */
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'
import {SectionLoader} from '../common/sectionLoader'

import {useDispatch, useSelector} from 'react-redux'

import {BsPen, BsFillPlusCircleFill, BsTrash} from "react-icons/bs"
import { MdOutlineAddCircleOutline } from "react-icons/md"

import {DoctorsModal} from '../common/modal/doctors-modal'

import {doctorActionCreators} from "../../actions/doctorActionCreators";

import {AddItemButton} from '../common/addItemButton'

import {EditItem} from '../common/editItem'
import {DeleteItem} from '../common/deleteItem'
import {InstructionLink} from '../common/instructionLink'

const {
    getDoctorsAction,
    addDoctorAction,
    removeDoctorAction,
    updateDoctorAction,
    showAddingDoctorModalAction,
    hideAddingDoctorModalAction,
    updateAddingDoctorModalAction,
    showEditingDoctorModalAction,
    hideEditingDoctorModalAction,
    updateEditingDoctorModalAction
} = doctorActionCreators




const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  align-content: start;
  gap: 3rem;
`


const ContainerItems = styled.div `
  display: grid;
  grid-template-columns: auto;
  align-content: start;
  position: relative;
  gap: 2rem;
  min-height: 150px;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ContainerItem = styled.div `
  display: grid;
  min-width: 250px;
  width: 100%;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  background: ${({theme}) => theme.colors.lightGray};
  padding: 1rem;
  border-radius: 4px; 
`

const LeftSideInformation = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
    line-height: 1.3;
  }
`

const RightSideEdit = styled.div `
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 1rem;

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
  }
`

const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
`
const ContainerTop = styled.div `
  display: grid;
  align-items: start;
  justify-items: start;
`

const ContainerHeading = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0%   {transform: scale(.7);}
    50%  {transform: scale(1);}
    100% {transform: scale(.7);}
  }

`

export const Doctors = () => {

  const {
    errors,
    errorsRemoving,
    errorsAdding,
    errorsUpdating,
    addingDoctorModal,
    addingDoctor,
    editedDoctor,
    doctors,
    busy,
    busyAdding,
    busyUpdating,
    busyRemoving
  } = useSelector(
    (state) => state.doctor
  )

  const dispatch = useDispatch()
  const handleClose = () => dispatch(hideAddingDoctorModalAction())
  const handleOpen = () => dispatch(showAddingDoctorModalAction())

  useEffect(() => {
    dispatch(getDoctorsAction())
  }, [])

  return (
    <>
      <DoctorsModal
        showModal={addingDoctorModal}
        handleClose={handleClose}
        data={addingDoctor}
        setData={data => dispatch(updateAddingDoctorModalAction(data))}
        onSubmit={() => dispatch(addDoctorAction(addingDoctor.name))}
        errors={errorsAdding}
        title="Dodaj lekarza/higienistkę"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Dodaj lekarza/higienistkę"
      />

      <DoctorsModal
        showModal={editedDoctor !== null}
        handleClose={() => dispatch(hideEditingDoctorModalAction())}
        data={editedDoctor}
        setData={data => dispatch(updateEditingDoctorModalAction(data))}
        onSubmit={() => dispatch(updateDoctorAction(editedDoctor))}
        errors={errorsUpdating}
        title="Edytuj lekarza/higienistkę"
        icon={MdOutlineAddCircleOutline}
        submitLabel="Zapisz lekarza/higienistkę"
      />

      <StyledWrapper>
        <ContainerHeading>
          <Heading h1 as='h1'>Zespół</Heading>
          <InstructionLink link="https://dentsplay.pl/2022/10/09/zakladka-zespol/" tooltipText="Zobacz filmik instruktażowy." />
        </ContainerHeading>
        <StyledContainer>
          <ContainerTop>
            <AddItemButton
                textButton='Dodaj lekarza/higienistkę'
                tooltipText='Dodaj lekarza/higienistkę'
                handleOpen={handleOpen}
            />
          </ContainerTop>
          <ContainerItems>
            {busy ? <SectionLoader/> :
              <>
                {doctors.map((doctor) => {
                  return (
                    <ContainerItem key={doctor.id}>
                      <LeftSideInformation>
                        <span>{doctor.name}</span>
                      </LeftSideInformation>
                      <RightSideEdit>
                        <EditItem
                          handleEdit={() => {dispatch(showEditingDoctorModalAction(doctor))}}
                          tooltipText="Edytuj lekarza/higienistkę"
                        />
                        <DeleteItem
                          handleDelete={() => {dispatch(removeDoctorAction(doctor.id))}}
                          tooltipText="Usuń lekarza/higienistkę"
                        />

                    </RightSideEdit>
                  </ContainerItem>
                  )
                })}
              </>
            }
          </ContainerItems>
        </StyledContainer>
      </StyledWrapper>
    </>
  )
}

