import React from 'react'
import styled from 'styled-components'


import CircularProgress from '@mui/material/CircularProgress'

const ContainerLoader = styled.div `
    position: absolute;
    display: grid;
    align-items: center;
    justify-items: center;
    top: 0%;
    left: 0%;
    bottom: 0%;
    width: 100%;
    height: 100%;
    z-index: 10000;

    .css-18lrjg1-MuiCircularProgress-root {
        color: ${({theme}) => theme.colors.red};
    }
`



export const SectionLoader = () => (
    
    <ContainerLoader>
        <CircularProgress/>

    </ContainerLoader>
    )
