/* eslint-disable */

import React, {useState, useEffect} from 'react'
import styled, {css} from 'styled-components'
import {Link} from "react-router-dom"
import {device} from '../../../utils/breakpoints'
import logo from '../../../images/logo-dents-play.png'
import {Hamburger} from './hamburger'
import {useGlobalContext} from '../../context'
import {Navigation} from './navigation'

const ContainerMobileMenu = styled.header`
  display: grid;
  padding: 1rem 1.5rem;
  justify-items: center;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 1000;
  height: 100px;
  background: ${({theme}) => theme.colors.white};


  ${({navbar}) =>
          navbar &&
          css`
            box-shadow: rgb(0 0 0 / 20%) 0px 1px 3px;
          `
  }
   @media ${device.laptop} {
    display: none;
  }

`

const HeaderNav = styled.nav`
  display: grid;
  grid-template-columns: auto auto;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: ${({theme}) => theme.colors.white};
`

const LogoContainer = styled.div`
  display: grid;
  position: absolute;
  bottom: 50%;
  transform: translate(0, 50%);

  img {
    width: 100px;
  }


`


export const MobileMenu = () => {

  // const [isShown, setIsShown] = useState(false)

  // const [subName, setSubName] = useState({})


  const [navbar, setNavbar] = useState(false)

  const {isMenuOpen, toggleMobileMenu, isOpen, openSubmenu, isSubmenuOpen, closeSubmenu} = useGlobalContext();

  const changeBackground = () => {
    if (window.scrollY >= 40) {
      setNavbar(true)
    } else {
      setNavbar(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', changeBackground)
    return () => {
      window.removeEventListener('scroll', changeBackground)
    }
  });

  typeof window !== 'undefined' &&
  window.addEventListener('resize', () => {
    window.innerWidth >= 1024 && isMenuOpen && toggleMobileMenu()
  })


  return (
    <ContainerMobileMenu navbar={navbar}>
      <HeaderNav>
        <Link to='/'>
          <LogoContainer>
            <img className='image' src={logo} title="Logo" alt="Logo"/>
          </LogoContainer>
        </Link>
        <Hamburger isOpen={isMenuOpen} onClick={toggleMobileMenu}/>
        <Navigation isOpen={isMenuOpen} toggleMobileMenu={toggleMobileMenu}/>
      </HeaderNav>
    </ContainerMobileMenu>
  )
}
