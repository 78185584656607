import React, {useEffect} from 'react'
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'
import TextField from '@mui/material/TextField'

import {BsArrowRightCircle} from 'react-icons/bs'
import {AiOutlineQuestionCircle} from 'react-icons/ai'
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import {useNavigate} from "react-router-dom";

import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";


import {ContainerInput} from '../common/containerInput'
import {CustomSelectInput} from '../common/customSelectInput'
import {useDispatch, useSelector} from "react-redux";
import {costTypeActionCreators} from "../../actions/costTypeActionCreators";
import moment from "moment";
import {apiDateFormat} from "../../features/client";
import {monthStatActionCreators} from "../../actions/monthStatActionCreators";
import LoaderButton from "../common/loaderButton"
// import {SectionLoader} from '../common/sectionLoader'
import {UNASSIGNED_COST_TYPE} from "../../constants/costTypeDict";
import {InstructionLink} from "../common/instructionLink";



const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }

  .css-18lrjg1-MuiCircularProgress-root {
    color: ${({theme}) => theme.colors.white};
  }
`

const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  justify-content: start;
  gap: 3rem;
`

const ContainerHours = styled.div`
  display: grid;
  grid-template-rows: auto;
  justify-content: start;
  gap: 2rem;
`

const ContainerChooseMonths = styled.div`
  display: grid;
  justify-content: start;
`

const ContainerCabinetOccupancy = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  justify-content: start;

`
const ContainerScoreAndTooltips = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  gap: 1rem;
`

const ContainerTooltips = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  align-content: center;
  justify-content: center;
`


const ContainerScore = styled.div`

`

const StyledScore = styled.div`
  display: grid;
  align-items: center;
  justify-items: center;
  padding: 1rem;
  display: grid;
  width: 200px;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.black};
  border-radius: 3px;
`

const ContainerWorkingHours = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  justify-content: start;

`


const ContainerInputsCabinetOccupancy = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
  align-items: start;

  @media ${device.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${device.laptop} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${device.laptopM} {
    grid-template-columns: repeat(3, 1fr);
  }
`

const ContainerInputsWorkingHours = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
  align-items: start;
`

const MessageInput = styled.p`
  position: absolute;
  bottom: -15px;
  font-size: ${({theme}) => theme.fonts.size.xs};
  color: ${({theme}) => theme.colors.red};
`

const ContainerHeading = styled.div `
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0%   {transform: scale(.7);}
    50%  {transform: scale(1);}
    100% {transform: scale(.7);}
  }

`


const {
  updateCostTypeMonthAction,
  getCostTypesAction
} = costTypeActionCreators
const {getMonthStatAction} = monthStatActionCreators
export const Hours = ({data, setData, onSave, busy, errors}) => {
  const {hoursOpened, hoursWorked} = data
  const setHoursOpened = val => {
    setData({...data, hoursOpened: val})
  }
  const setHoursWorked = val => {
    setData({...data, hoursWorked: val})
  }
  const {
    month,
    costTypes,
    costTypeValues,
    initialized
  } = useSelector(
    (state) => state.costType
  )
  const {
    doctors,
  } = useSelector(
    (state) => state.doctor
  )
  const positionsArray = useSelector(
    (state) => state.position.positions
  )
  const dispatch = useDispatch()
  const reloadCosts = (value) => {
    dispatch(getCostTypesAction(moment(value ?? month).format(apiDateFormat)))
  }
  useEffect(() => {
    reloadCosts()
  }, [])
  useEffect(() => {
    if(initialized) {
      dispatch(getMonthStatAction(moment(month).format(apiDateFormat)))
    }
  }, [costTypes])
  let workedHoursSummary = 0
  for (const [key, value] of Object.entries(hoursWorked)) {
    if (key) {
      const addon = parseFloat(value)
      workedHoursSummary += isNaN(addon) ? 0 : addon
    }
  }
  let openedHoursSummary = 0
  for (const [key, value] of Object.entries(hoursOpened)) {
    if (key) {
      const addon = parseFloat(value)
      openedHoursSummary += isNaN(addon) ? 0 : addon
    }
  }
  const navigate = useNavigate()
  let unassignedCostsSummary = 0
  for (let i = 0; i < costTypes.length; i++) {
    const costType = costTypes[i]
    if(costType.type === UNASSIGNED_COST_TYPE) {
      unassignedCostsSummary += parseFloat(costTypeValues[costType.id] ? costTypeValues[costType.id] : 0)
    }
  }
  let occupancy = workedHoursSummary / openedHoursSummary
  let costIntense = unassignedCostsSummary / workedHoursSummary
  return (
    <StyledWrapper>
      <ContainerHeading>
        <Heading h1 as='h1'>Godziny</Heading>
        <InstructionLink link="/" tooltipText="Zobacz filmik instruktażowy." />
      </ContainerHeading>
      <Line/>
      <StyledContainer>
        <ContainerHours>
          <ContainerChooseMonths>
            <ContainerInput>
              <span>Wybierz miesiąc</span>
              <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
                <DatePicker
                  id="month"
                  views={['year', 'month']}
                  value={month}
                  onAccept={reloadCosts}
                  onChange={val => dispatch(updateCostTypeMonthAction(val))}
                  renderInput={(props) => <TextField {...props}/>}

                />
              </LocalizationProvider>
            </ContainerInput>
          </ContainerChooseMonths>

          <ContainerCabinetOccupancy>
            <Heading h2 as='h2'>Oblicz miesięczne obłożenie gabinetu</Heading>
            <ContainerInputsCabinetOccupancy>
              <ContainerInput>
                <span>godz/dostępne/mies/każdy unit. </span>
                <CustomSelectInput data={hoursOpened} setData={setHoursOpened} entities={positionsArray}/>
                <MessageInput>{errors.hoursOpened}</MessageInput>
              </ContainerInput>
              <ContainerInput>
                <span>godz/przepr/mies/każdy lekarz</span>
                <CustomSelectInput data={hoursWorked} setData={setHoursWorked} entities={doctors}/>
                  <MessageInput>{errors.hoursWorked}</MessageInput>
              </ContainerInput>
            </ContainerInputsCabinetOccupancy>
            <ContainerScore>
              <StyledScore>Wynik: {openedHoursSummary > 0 ? Math.round(occupancy * 100) : '-'} %</StyledScore>
            </ContainerScore>
          </ContainerCabinetOccupancy>

          <ContainerWorkingHours>
            <Heading h2 as='h2'>Miesięczne koszty stałe</Heading>
            <ContainerInputsWorkingHours>
              <ContainerInput>
                <span>Koszty stałe</span>
                <TextField id="outlined-basic" label="Wpisz kwotę" type="number"
                           disabled={true}
                           inputProps={{
                             min: 0,
                             step: 0.01
                           }} variant="outlined"
                           helperText={errors.costsSummary} error={errors.costsSummary !== undefined}
                           value={unassignedCostsSummary.toFixed(2)}/>
              </ContainerInput>
            </ContainerInputsWorkingHours>

            <ContainerScoreAndTooltips>
              <ContainerScore>
                <StyledScore>Wynik: {workedHoursSummary > 0 ? costIntense.toFixed(2) : '-'} zł/h</StyledScore>
              </ContainerScore>
                  <ContainerTooltips>
                    <Tooltip title="Przejdź do kalkulatora" enterTouchDelay={0}>
                      <IconButton onClick={() =>
                        navigate('/kalkulator', {state: {costsPerHour: workedHoursSummary > 0 ? costIntense.toFixed(2) : 0}})}>
                        <BsArrowRightCircle  size="20px" color="#D22416"/>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title="Lorem ipsum - potrzebuje tekstu" enterTouchDelay={0} >
                      <IconButton>
                          <AiOutlineQuestionCircle  size="22px"/>
                      </IconButton>
                    </Tooltip>
                  </ContainerTooltips>
            </ContainerScoreAndTooltips>

          </ContainerWorkingHours>
          <LoaderButton busy={busy} onClick={() => onSave({...data, date: moment(month).format(apiDateFormat)})}>Zapisz</LoaderButton>

        </ContainerHours>
      </StyledContainer>
    </StyledWrapper>
  )
}


// value={date}
// onChange={onChangeDate}
// onAccept={onAccept}
