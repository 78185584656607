import {
    DOCTOR_GET_ACTION_BEGIN,
    DOCTOR_GET_ACTION_FAILED,
    DOCTOR_GET_ACTION_REQUESTED,
    DOCTOR_GET_ACTION_SUCCEEDED,
    DOCTOR_ADD_ACTION_BEGIN,
    DOCTOR_ADD_ACTION_FAILED,
    DOCTOR_ADD_ACTION_REQUESTED,
    DOCTOR_ADD_ACTION_SUCCEEDED,
    DOCTOR_REMOVE_ACTION_BEGIN,
    DOCTOR_REMOVE_ACTION_FAILED,
    DOCTOR_REMOVE_ACTION_REQUESTED,
    DOCTOR_REMOVE_ACTION_SUCCEEDED,
    DOCTOR_UPDATE_ACTION_BEGIN,
    DOCTOR_UPDATE_ACTION_FAILED,
    DOCTOR_UPDATE_ACTION_REQUESTED,
    DOCTOR_UPDATE_ACTION_SUCCEEDED
} from "../constants/actions";

import doctorService from "../features/doctor/doctorService";
import {put, takeEvery, select} from "redux-saga/effects";

function* addDoctor(action) {
    const busy = yield select((state) => state.doctor.busyAdding)
    if (!busy) {
        yield put({type:DOCTOR_ADD_ACTION_BEGIN})
        const doctorResponse = yield doctorService.addDoctor(action.payload)
        if (doctorResponse.id !== undefined) {
            yield put({type:DOCTOR_ADD_ACTION_SUCCEEDED, payload: doctorResponse})
        } else {
            yield put({type:DOCTOR_ADD_ACTION_FAILED, payload: doctorResponse})
        }
    }
}

function* updateDoctor(action) {
    const busy = yield select((state) => state.doctor.busyUpdating)
    if (!busy) {
        yield put({type:DOCTOR_UPDATE_ACTION_BEGIN})
        const doctorResponse = yield doctorService.updateDoctor(action.payload)
        if (doctorResponse.id !== undefined) {
            yield put({type:DOCTOR_UPDATE_ACTION_SUCCEEDED, payload: doctorResponse})
        } else {
            yield put({type:DOCTOR_UPDATE_ACTION_FAILED, payload: doctorResponse})
        }
    }
}

function* removeDoctor(action) {
    const busy = yield select((state) => state.doctor.busyRemoving)
    if (!busy) {
        yield put({type:DOCTOR_REMOVE_ACTION_BEGIN})
        const doctorResponse = yield doctorService.removeDoctor(action.payload)
        if (doctorResponse === true) {
            yield put({type:DOCTOR_REMOVE_ACTION_SUCCEEDED, payload: action.payload})
        } else {
            yield put({type:DOCTOR_REMOVE_ACTION_FAILED, payload: doctorResponse})
        }
    }
}

function* getDoctors() {
    const busy = yield select((state) => state.doctor.busy)
    if (!busy) {
        yield put({type:DOCTOR_GET_ACTION_BEGIN})
        const doctorResponse = yield doctorService.getDoctors()
        if (doctorResponse.length !== undefined) {
            yield put({type:DOCTOR_GET_ACTION_SUCCEEDED, payload: doctorResponse})
        } else {
            yield put({type:DOCTOR_GET_ACTION_FAILED, payload: doctorResponse})
        }
    }
}

export default function* doctorSaga() {
    yield takeEvery(DOCTOR_ADD_ACTION_REQUESTED, addDoctor)
    yield takeEvery(DOCTOR_UPDATE_ACTION_REQUESTED, updateDoctor)
    yield takeEvery(DOCTOR_REMOVE_ACTION_REQUESTED, removeDoctor)
    yield takeEvery(DOCTOR_GET_ACTION_REQUESTED, getDoctors)
}
