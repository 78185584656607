import React from 'react';
import styled from "styled-components";
import {Button} from "../button";
import CircularProgress from "@mui/material/CircularProgress";

const StyledButton = styled(Button)`
  width: 100%;
  background: ${({theme}) => theme.colors.red};
  color: ${({theme}) => theme.colors.white};
  border: 1px solid #D22416;
  font-weight: 700;
  font-size: ${({theme}) => theme.fonts.size.s};
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  & .css-18lrjg1-MuiCircularProgress-root {
    color: ${({theme}) => theme.colors.white};
  }
`
const ButtonContent = styled.div`
  padding: 0 .5rem;
`
function LoaderButton({children, busy, onClick}) {
  return (
    <StyledButton onClick={onClick}>
      {
        busy ? <CircularProgress size={25}/> :
        <ButtonContent>
          {children}
        </ButtonContent>
      }
    </StyledButton>
  );
}

export default LoaderButton;
