import {
  DOCTOR_GET_ACTION_BEGIN,
  DOCTOR_GET_ACTION_SUCCEEDED,
  DOCTOR_GET_ACTION_FAILED,
  DOCTOR_ADD_ACTION_BEGIN,
  DOCTOR_ADD_ACTION_SUCCEEDED,
  DOCTOR_ADD_ACTION_FAILED,
  DOCTOR_REMOVE_ACTION_BEGIN,
  DOCTOR_REMOVE_ACTION_SUCCEEDED,
  DOCTOR_REMOVE_ACTION_FAILED,
  DOCTOR_UPDATE_ACTION_BEGIN,
  DOCTOR_UPDATE_ACTION_SUCCEEDED,
  DOCTOR_UPDATE_ACTION_FAILED,

  DOCTOR_HIDE_ADDING_MODAL_ACTION,
  DOCTOR_HIDE_EDITING_MODAL_ACTION,

  DOCTOR_SHOW_ADDING_MODAL_ACTION,
  DOCTOR_SHOW_EDITING_MODAL_ACTION,

  DOCTOR_UPDATE_ADDING_MODAL_ACTION,
  DOCTOR_UPDATE_EDITING_MODAL_ACTION
} from "../constants/actions"

const initialState = {
  doctors: [],
  busy: false,
  busyAdding: false,
  busyUpdating: false,
  busyRemoving: false,
  addingDoctorModal: false,
  addingDoctor: {name: ''},
  editedDoctor: null,
  loggedIn: false,
  errors: {},
  errorsAdding: {},
  errorsUpdating: {},
  errorsRemoving: {},
}

export const doctorReducer = (state = initialState, action) => {
  let doctors = state.doctors
  switch (action.type) {
    case DOCTOR_GET_ACTION_BEGIN:
      return {
        ...state,
        busy: true,
        errors: {},
      }
    case DOCTOR_ADD_ACTION_BEGIN:
      return {
        ...state,
        busyAdding: true,
        errorsAdding: {},
      }
    case DOCTOR_REMOVE_ACTION_BEGIN:
      return {
        ...state,
        busyRemoving: true,
        errorsRemoving: {},
      }
    case DOCTOR_UPDATE_ACTION_BEGIN:
      return {
        ...state,
        busyUpdating: true,
        errorsUpdating: {},
      }
    case DOCTOR_GET_ACTION_FAILED:
      return {
        ...state,
        busy: false,
        errors: action.payload,
      }
    case DOCTOR_ADD_ACTION_FAILED:
      return {
        ...state,
        busyAdding: false,
        errorsAdding: action.payload,
      }
    case DOCTOR_REMOVE_ACTION_FAILED:
      return {
        ...state,
        busyRemoving: false,
        errorsRemoving: action.payload,
      }
    case DOCTOR_UPDATE_ACTION_FAILED:
      return {
        ...state,
        busyUpdating: false,
        errorsUpdating: action.payload,
      }

    case DOCTOR_ADD_ACTION_SUCCEEDED:
      return {
        ...state,
        busyAdding: false,
        addingDoctorModal: false,
        doctors: [...doctors, action.payload]
      }
    case DOCTOR_REMOVE_ACTION_SUCCEEDED:
      return {
        ...state,
        busyRemoving: false,
        doctors: doctors.filter(doctor => doctor.id !== action.payload)
      }
    case DOCTOR_UPDATE_ACTION_SUCCEEDED:
      doctors = doctors.filter(doctor => doctor.id !== action.payload.id)
      return {
        ...state,
        busyUpdating: false,
        doctors: [...doctors, action.payload],
        editedDoctor: null,
      }
    case DOCTOR_GET_ACTION_SUCCEEDED:
      return {
        ...state,
        busy: false,
        doctors: action.payload
      }
    case DOCTOR_SHOW_ADDING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        addingDoctorModal: true,
        addingDoctor: initialState.addingDoctor,
      }
    case DOCTOR_HIDE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingDoctorModal: false,
      }
    case DOCTOR_UPDATE_ADDING_MODAL_ACTION:
      return {
        ...state,
        addingDoctor: action.payload,
      }
    case DOCTOR_SHOW_EDITING_MODAL_ACTION:
      return {
        ...state,
        errors: {},
        editedDoctor: action.payload,
      }
    case DOCTOR_UPDATE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedDoctor: action.payload,
      }
    case DOCTOR_HIDE_EDITING_MODAL_ACTION:
      return {
        ...state,
        editedDoctor: null,
      }
    default:
      return state;
  }
}
