import React from 'react';
import ReactDOM from 'react-dom';
import {
    BrowserRouter, Routes, Route
} from "react-router-dom"

import App from './App';
import registerServiceWorker from './registerServiceWorker';

import {persistor, store} from './app/store'
import { Provider } from 'react-redux'
import {PersistGate} from "redux-persist/integration/react";






ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <Routes>
                    <Route path='/*' element={<App/>}/>
                </Routes>
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'));
registerServiceWorker();