/* eslint-disable */
import React from 'react'
import styled from 'styled-components'


import {device} from '../../../utils/breakpoints'

import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box';


import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";

import ChooseEntityInput from "../input/ChooseEntityInput";
import {Heading} from '../heading'

import {Alert} from "@mui/material";

const StyledModal = styled(Modal)`
  .css-1gkse3u {
    min-width: 300px !important;

    @media ${device.tablet} {
      min-width: 400px !important;

    }
  }
`

const StyledBox = styled(Box)`
  display: grid;
  grid-template-rows: auto;

  gap: 2rem;

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-u9osun.Mui-focused {
    color: ${({theme}) => theme.colors.red} !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${({theme}) => theme.colors.red} !important;
  }


`

const ContainerCostItem = styled.div`
  display: grid;
  grid-template-rows: auto;
  justify-items: center;
  gap: 2rem;
  align-items: center;


`

const CostItem = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, 1fr);

  }
`


const ContainerInformationCostItem = styled.div`
  display: grid;
`


const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(2, auto);
  gap: 1.5rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
  width: 100%;
  align-items: center;


  @media ${device.mobileXL} {
    max-width: 250px;
  }
`


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export const PricesModal = ({
                              showModal,
                              handleClose,
                              data,
                              setData,
                              onSubmit,
                              errors,
                              title,
                              submitLabel,
                              icon,
                              branches
                            }) => {

  const handleChangeServiceName = (event) => {
    setData({...data, name: event.target.value})
  }

  const handleChangeBranch = (branch) => {
    setData({...data, branch})
  }

  const Icon = icon;

  return (data &&
    <StyledModal
      open={showModal}
      onClose={handleClose}
    >
      <StyledBox sx={style}>
        <Heading h3 as='h3'>{title}</Heading>
        <ContainerCostItem>
          <CostItem>
            <ContainerInformationCostItem>
              <FormControl variant="outlined">
                <InputLabel htmlFor="First Name">Nazwa usługi</InputLabel>
                <OutlinedInput
                  id="Nazwa usługi"
                  label="Nazwa usługi"
                  value={data.name}
                  error={errors.name !== undefined}
                  onChange={handleChangeServiceName}
                />
                {errors.name !== undefined && <Alert severity="error">{errors.name}</Alert>}
              </FormControl>
            </ContainerInformationCostItem>

            <ContainerInformationCostItem>
              <FormControl variant="outlined">
                <ChooseEntityInput
                  entities={branches}
                  propertyName={'name'}
                  propertyIndex={'id'}
                  onChange={handleChangeBranch}
                  value={data.branch}
                  label="Wybierz dziedzinę"
                />
                {errors.branch !== undefined && <Alert severity="error">{errors.branch}</Alert>}
              </FormControl>
            </ContainerInformationCostItem>
          </CostItem>
          {errors.default !== undefined && <Alert severity="error">{errors.default}</Alert>}
          <AddItem
            onClick={onSubmit}>
            <span>{submitLabel}</span>
            <Icon size='20px' color='black'/>
          </AddItem>
        </ContainerCostItem>
      </StyledBox>
    </StyledModal>
  )
}
