/* eslint-disable */
import React, {useRef, useState} from 'react'
import styled from 'styled-components'

import {MdOutlineArrowDropDown} from 'react-icons/md'
import {AiFillPlusCircle, AiFillMinusCircle} from 'react-icons/ai'
import {formatPrice} from "../../../utils/numberUtils";
import * as EVENTS from "events";
import {useOutsideAlerter} from "../../../utils/useOutsideAlerter";
import IconButton from '@mui/material/IconButton'
import TextField from '@mui/material/TextField'
import {useSelector} from "react-redux";


const StyledContainerInput = styled.div`
  display: grid;
  position: relative;
`

const StyledLabel = styled.label`
  position: relative;

  .select_icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
  }

  .icon {
    transform: rotate(180deg);
  }

  .input {
    border-color: #469546;
    border-width: 2px
  }
`

const StyledInput = styled.input`
  height: 58px;
  width: 100%;
  padding: 16.5px 14px;
  border: 1px solid;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  color: rgba(0, 0, 0, 0.87);
  font-size: ${({theme}) => theme.fonts.size.s};

  :hover {
    border-color: #000;
  }

  :focus {
    border-color: #469546;
  }
`

const ContainerSelectList = styled.div`
  position: absolute;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%), 0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  top: 57px;
  left: 0px;
  right: 0px;
  width: 100%;
  background: #fff;
  z-index: 10;
`
const SelectList = styled.ul`
  display: grid;
  gap: 1rem;
  height: 100%;
  width: 100%;
  grid-template-rows: auto;
  padding-top: 8px;
  padding-bottom: 8px;
`

const Select = styled.li`
  display: grid;
  align-items: center;
  gap: 1rem;
  grid-template-columns: 2fr 1fr;
  padding: 0rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  cursor: pointer;
  user-select: none;
`
const ContainerLeftSide = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, auto);
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;

`

const ContainerRightSide = styled.div`
  display: grid;


`


const Text = styled.div`
  font-size: ${({theme}) => theme.fonts.size.xs};

`
const Price = styled.div`
  font-size: ${({theme}) => theme.fonts.size.xs};
  white-space: nowrap;
`

const ContainerCountIcons = styled.div`
  display: grid;
  gap: 0.3rem;
  grid-template-columns: repeat(3, auto);
  align-items: center;
`


const CountValue = styled.input`
  max-width: 50px;
  border-radius: 3px;
  border: 1px solid black;

`


export const CustomSelectInput = ({data, setData, entities}) => {
  const [opened, setOpened] = useState(false)
  const [searchValue, setSearchValue] = useState('')

  const onOpen = () => {
    setOpened(true)
    setSearchValue('')
  }
  const onClose = () => {
    setOpened(false)
  }
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef, onClose)
  const onDoctorSetValue = (id, val) => {
    let newValue = {...data}
    newValue[id] = val
    setData(newValue)
  }
  let summary = 0
  for (const [key, value] of Object.entries(data)) {
    if (data.hasOwnProperty(key)) {
      const addon = parseFloat(value)
      summary += isNaN(addon) ? 0 : addon
    }
  }
  return (
    <StyledContainerInput
      onFocus={onOpen}
      ref={wrapperRef}
    >
      <StyledLabel htmlFor={name}>
        <StyledInput
          name={name}
          autoComplete='off'
          className={opened && 'input'}
          value={opened ? searchValue : summary}
          onChange={e => setSearchValue(e.target.value)}
        />
        <IconButton className='select_icon'>
          <MdOutlineArrowDropDown size="22px" color='rgba(0, 0, 0, 0.54)' className={opened && 'icon'}/>
        </IconButton>
        {opened && <ContainerSelectList>
          <SelectList>
            {entities.filter(entity => searchValue.length === 0 || entity.name.startsWith(searchValue)).map(entity =>
              <Select key={entity.id}>
                <ContainerLeftSide>
                  <Text>{entity.name}</Text>
                </ContainerLeftSide>
                <ContainerRightSide>
                  <CountValue type='number' min="0" step="1" value={data[entity.id] ?? ''}
                              onChange={e => onDoctorSetValue(entity.id, e.target.value)}/>
                </ContainerRightSide>
              </Select>)}
          </SelectList>
        </ContainerSelectList>}
      </StyledLabel>
    </StyledContainerInput>
  )
}


