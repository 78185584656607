/* eslint-disable */
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'
import {Wrapper} from '../common/wrapper'
import {Container} from '../common/container'
import {Sidebar} from '../common/sidebar'

import {MobileMenu} from '../common/mobileMenu'
import {Hours} from './hours'
import {positionActionCreators} from "../../actions/positionActionCreators";
import {useDispatch, useSelector} from "react-redux";
import {SectionLoader} from "../common/sectionLoader";
import {doctorActionCreators} from "../../actions/doctorActionCreators";
import {monthStatActionCreators} from "../../actions/monthStatActionCreators";


const StyledWrapper = styled(Wrapper)`
  width: 100%;
  background: ${({theme}) => theme.colors.lightGray};
  height: 100%;
  min-height: 100vh;

  @media ${device.laptop} {
    padding: 0.7rem;
  }
`

const StyledContainer = styled(Container)`

  display: grid;
  grid-template-columns: auto;
  gap: 0.7rem;
  position: relative;

  @media ${device.laptop} {
    grid-template-columns: 350px auto;
  }
`

const ContainerRightSide = styled.div`
  position: relative;

  @media ${device.laptop} {
    display: grid;
    grid-column: 2;
    align-content: start;
  }
`


const {getPositionsAction} = positionActionCreators
const {getDoctorsAction} = doctorActionCreators
const {setDataMonthStatAction, updateMonthStatAction, addMonthStatAction} = monthStatActionCreators
const HoursPage = () => {
  const [localBusy, setLocalBusy] = useState(true)
  const dispatch = useDispatch()
  const busy = useSelector(
    (state) => state.position.busy
  )
  const monthStat = useSelector(
    (state) => state.monthStat.monthStat
  )
  const busySaving = useSelector(
    (state) => state.monthStat.busyChanging
  )
  const errorsSaving = useSelector(
    (state) => state.monthStat.errorsChanging
  )
  useEffect(() => {
    dispatch(getPositionsAction())
    dispatch(getDoctorsAction())
  }, [])
  useEffect(() => {
    if (!busy && localBusy) {
      setLocalBusy(false) //wait until default data is downloaded
    }
  }, [busy])
  return (
    <StyledWrapper>
      <StyledContainer>
        <MobileMenu/>
        <Sidebar/>
        <ContainerRightSide>
          {busy || localBusy ?
            <SectionLoader/> :
            <Hours data={monthStat}
                   busy={busySaving}
                   errors={errorsSaving}
                   setData={data => dispatch(setDataMonthStatAction(data))}
                   onSave={data => dispatch(data.id ?
                     updateMonthStatAction(data) :
                     addMonthStatAction(data))}/>
          }
        </ContainerRightSide>
      </StyledContainer>
    </StyledWrapper>
  )
}


export default HoursPage
