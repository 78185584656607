import {apiRequest, unexpectedError} from "../client";

const addCostType = async (costType) => {
  const response = await apiRequest('POST', 'api/costTypes', costType)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const updateCostType = async (costType) => {
  const response = await apiRequest('PATCH', 'api/costTypes', costType)

  if (response.status === 200) {
    const {success, errors, item} = response.data
    if (success) {
      return item
    }
    return errors
  }

  return unexpectedError
}

const removeCostType = async (id) => {
  const response = await apiRequest('DELETE', 'api/costTypes', {id})

  if (response.status === 200) {
    const {success, errors} = response.data
    if (success) {
      return true
    }
    return errors
  }

  return unexpectedError
}

const getCostTypes = async (date) => {
  const response = await apiRequest('GET', 'api/costTypes', {date})

  if (response.status === 200) {
    const {success, errors, items} = response.data
    if (success) {
      return items
    }
    return errors
  }

  return unexpectedError
}

const costTypeService = {
  getCostTypes,
  updateCostType,
  removeCostType,
  addCostType,
}

export default costTypeService
