import React from 'react'
import { Outlet, Navigate } from 'react-router'
import { useSelector } from 'react-redux'



const ProtectedRoutes = () => {

    const {loggedIn } = useSelector(state => state.auth)
    
    if(loggedIn) {
        return <Outlet />
    }  
    return <Navigate to={'/'} />

}

export default ProtectedRoutes