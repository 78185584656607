import styled, {css} from 'styled-components'


export const ContainerInput = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 1rem;
  position: relative;
  width: 100%;


  ${({nolabel}) =>
          nolabel &&
          css`
            grid-template-rows: auto;
          `}
  span {
    text-transform: uppercase;
    color: ${({theme}) => theme.colors.lightBlack};
    font-weight: 700;
    font-size: ${({theme}) => theme.fonts.size.xxs};
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #469546 !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-u9osun.Mui-focused {
    color: #469546 !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }


`