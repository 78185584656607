import styled, { css } from 'styled-components'
import { device } from '../../../utils/breakpoints'

export const Heading = styled.h1`
  font-size: ${({ theme }) => theme.fonts.size.l};
  line-height: 150%;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black};
  @media ${device.laptop} {
    font-size: ${({ theme }) => theme.fonts.size.xl};
   
  }

  ${({ h2 }) =>
    h2 &&
    css`
      text-transform: unset;
      font-size: ${({ theme }) => theme.fonts.size.m};
      font-weight: 700;
  

      @media ${device.laptop} {
        font-size: ${({ theme }) => theme.fonts.size.l};
      }
    `}

  ${({ h3 }) =>
    h3 &&
    css`
      text-transform: unset;
      font-size: ${({ theme }) => theme.fonts.size.m};
      font-weight: 700;
  

      @media ${device.laptop} {
        font-size: ${({ theme }) => theme.fonts.size.l};
      }
    `}

    ${({ h4 }) =>
    h4 &&
    css`
      font-size: ${({ theme }) => theme.fonts.size.xs};
      font-weight: 700;
  

      @media ${device.laptop} {
        font-size: ${({ theme }) => theme.fonts.size.s};
      }
    `}

  
`
