import {
    DOCTOR_GET_ACTION_REQUESTED,
    DOCTOR_ADD_ACTION_REQUESTED,
    DOCTOR_REMOVE_ACTION_REQUESTED,
    DOCTOR_UPDATE_ACTION_REQUESTED,
    DOCTOR_SHOW_ADDING_MODAL_ACTION,
    DOCTOR_HIDE_ADDING_MODAL_ACTION,
    DOCTOR_UPDATE_ADDING_MODAL_ACTION,
    DOCTOR_SHOW_EDITING_MODAL_ACTION,
    DOCTOR_HIDE_EDITING_MODAL_ACTION,
    DOCTOR_UPDATE_EDITING_MODAL_ACTION
} from "../constants/actions";


function getDoctorsAction() {
    return {
        type: DOCTOR_GET_ACTION_REQUESTED,
    }
}


function addDoctorAction(name) {
    return {
        type: DOCTOR_ADD_ACTION_REQUESTED,
        payload: {name}
    }
}

function removeDoctorAction(id) {
    return {
        type: DOCTOR_REMOVE_ACTION_REQUESTED,
        payload: id
    }
}

function updateDoctorAction(doctor) {
    return {
        type: DOCTOR_UPDATE_ACTION_REQUESTED,
        payload: doctor
    }
}

function showAddingDoctorModalAction() {
    return {
        type:  DOCTOR_SHOW_ADDING_MODAL_ACTION,
    }
}

function hideAddingDoctorModalAction() {
    return {
        type:  DOCTOR_HIDE_ADDING_MODAL_ACTION
    }
}

function updateAddingDoctorModalAction(data) {
    return {
        type:  DOCTOR_UPDATE_ADDING_MODAL_ACTION,
        payload: data,
    }
}

function showEditingDoctorModalAction(doctor) {
    return {
        type:  DOCTOR_SHOW_EDITING_MODAL_ACTION,
        payload: doctor,
    }
}

function hideEditingDoctorModalAction() {
    return {
        type:  DOCTOR_HIDE_EDITING_MODAL_ACTION
    }
}

function updateEditingDoctorModalAction(data) {
    return {
        type:  DOCTOR_UPDATE_EDITING_MODAL_ACTION,
        payload: data,
    }
}



export const doctorActionCreators = {
    getDoctorsAction,
    addDoctorAction,
    removeDoctorAction,
    updateDoctorAction,
    showAddingDoctorModalAction,
    hideAddingDoctorModalAction,
    updateAddingDoctorModalAction,
    showEditingDoctorModalAction,
    hideEditingDoctorModalAction,
    updateEditingDoctorModalAction
}
