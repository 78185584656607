import {
    POSITION_GET_ACTION_BEGIN,
    POSITION_GET_ACTION_FAILED,
    POSITION_GET_ACTION_REQUESTED,
    POSITION_GET_ACTION_SUCCEEDED,
    POSITION_ADD_ACTION_BEGIN,
    POSITION_ADD_ACTION_FAILED,
    POSITION_ADD_ACTION_REQUESTED,
    POSITION_ADD_ACTION_SUCCEEDED,
    POSITION_REMOVE_ACTION_BEGIN,
    POSITION_REMOVE_ACTION_FAILED,
    POSITION_REMOVE_ACTION_REQUESTED,
    POSITION_REMOVE_ACTION_SUCCEEDED,
    POSITION_UPDATE_ACTION_BEGIN,
    POSITION_UPDATE_ACTION_FAILED,
    POSITION_UPDATE_ACTION_REQUESTED,
    POSITION_UPDATE_ACTION_SUCCEEDED
} from "../constants/actions";

import positionService from "../features/position/positionService";
import {put, takeEvery, select} from "redux-saga/effects";

function* addPosition(action) {
    const busy = yield select((state) => state.position.busyAdding)
    if (!busy) {
        yield put({type:POSITION_ADD_ACTION_BEGIN})
        const positionResponse = yield positionService.addPosition(action.payload)
        if (positionResponse.id !== undefined) {
            yield put({type:POSITION_ADD_ACTION_SUCCEEDED, payload: positionResponse})
        } else {
            yield put({type:POSITION_ADD_ACTION_FAILED, payload: positionResponse})
        }
    }
}

function* updatePosition(action) {
    const busy = yield select((state) => state.position.busyUpdating)
    if (!busy) {
        yield put({type:POSITION_UPDATE_ACTION_BEGIN})
        const positionResponse = yield positionService.updatePosition(action.payload)
        if (positionResponse.id !== undefined) {
            yield put({type:POSITION_UPDATE_ACTION_SUCCEEDED, payload: positionResponse})
        } else {
            yield put({type:POSITION_UPDATE_ACTION_FAILED, payload: positionResponse})
        }
    }
}

function* removePosition(action) {
    const busy = yield select((state) => state.position.busyRemoving)
    if (!busy) {
        yield put({type:POSITION_REMOVE_ACTION_BEGIN})
        const positionResponse = yield positionService.removePosition(action.payload)
        if (positionResponse === true) {
            yield put({type:POSITION_REMOVE_ACTION_SUCCEEDED, payload: action.payload})
        } else {
            yield put({type:POSITION_REMOVE_ACTION_FAILED, payload: positionResponse})
        }
    }
}

function* getPositions() {
    const busy = yield select((state) => state.position.busy)
    if (!busy) {
        yield put({type:POSITION_GET_ACTION_BEGIN})
        const positionResponse = yield positionService.getPositions()
        if (positionResponse.length !== undefined) {
            yield put({type:POSITION_GET_ACTION_SUCCEEDED, payload: positionResponse})
        } else {
            yield put({type:POSITION_GET_ACTION_FAILED, payload: positionResponse})
        }
    }
}

export default function* positionSaga() {
    yield takeEvery(POSITION_ADD_ACTION_REQUESTED, addPosition)
    yield takeEvery(POSITION_UPDATE_ACTION_REQUESTED, updatePosition)
    yield takeEvery(POSITION_REMOVE_ACTION_REQUESTED, removePosition)
    yield takeEvery(POSITION_GET_ACTION_REQUESTED, getPositions)
}
