/* eslint-disable */
import React from 'react'
import styled from 'styled-components'

import {device} from '../../../utils/breakpoints'
import {useForm, Controller} from "react-hook-form"
import TextField from '@mui/material/TextField'
import {Heading} from '../../common/heading'
import {ContainerInput} from '../../common/containerInput'
import {Button} from '../../common/button'
import {useDispatch, useSelector} from "react-redux";
import {settingsActionCreators} from "../../../actions/settingsActionCreators";
import {useReduxForm} from "../../../utils/useReduxForm";
import {initialState} from "../../../reducers/settingsReducer";
import LoaderButton from "../../common/loaderButton";

const Container = styled.div`
  width: 100%;

`

const ContainerChangePassword = styled.div`
  width: 100%;
  display: grid;
  align-items: start;
  gap: 3rem;

`

const ContainerForm = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 2rem;
  align-items: center;
`

const Form = styled.form`
  display: grid;
  grid-template-columns: auto;
  gap: 1.8rem;
  align-items: center;

`

const ContainerTextFields = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto;
  gap: 1.8rem;
  position: relative;

  @media ${device.tablet} {
    grid-template-columns: repeat(3, auto);
  }

`


const MessageInput = styled.p`
  position: absolute;
  bottom: -20px;
  font-size: ${({theme}) => theme.fonts.size.xxs};
  color: ${({theme}) => theme.colors.red};
`


const ContainerButton = styled.div`
  display: grid;
  align-content: center;
  justify-content: flex-start;

`


const {changeEmailAction, setChangeEmailData} = settingsActionCreators

export const ChangeMail = () => {


  const dispatch = useDispatch()
  const changeEmailData = useSelector(state => state.settings.dataChangingEmail)
  const changeEmailErrors = useSelector(state => state.settings.errorsChangingEmail)
  const busyChangingEmail = useSelector(state => state.settings.busyChangingEmail)
  const {
    useFormApi,
  } = useReduxForm(changeEmailData, initialState.dataChangingEmail, data => dispatch(setChangeEmailData(data)))
  const {control, formState: {errors}, setValue, handleSubmit} = useFormApi

  const onSubmit = data => {
    console.log('submitted', data)
    dispatch(changeEmailAction(data.current_password, data.newEmail))
  }
  const onErrors = errors => {
    console.log('errors', errors)
  }
  return (
    <Container>
      <ContainerChangePassword>
        <ContainerForm>
          <Heading h2 as='h2'>Zmiana Maila</Heading>
          <Form
            onSubmit={handleSubmit(onSubmit, onErrors)}
          >
            <ContainerTextFields>
              <ContainerInput>
                <span>Wpisz aktualne hasło</span>
                <Controller
                  id="password"
                  name="current_password"
                  control={control}
                  text="password"
                  rules={{
                    required: 'To pole jest wymagane.',
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      autoComplete="section-changeEmail current-password"
                      onChange={onChange}
                      label="Hasło"
                      type='password'

                    />
                  }
                />
                <MessageInput>{errors.current_password?.message ?? changeEmailErrors.current_password}</MessageInput>
              </ContainerInput>
              <ContainerInput>
                <span>Wpisz nowy mail</span>

                <Controller
                  id="newMail"
                  name="newEmail"
                  control={control}
                  text="mail"
                  rules={{
                    required: 'To pole jest wymagane.',
                  }}
                  render={({field: {onChange, value}}) =>
                    <TextField
                      value={value}
                      autoComplete="section-changeEmail email username"
                      onChange={onChange}
                      label="Nowy e-mail"
                      type='email'
                    />
                  }
                />
                <MessageInput>{errors.newEmail?.message ?? changeEmailErrors.email}</MessageInput>
              </ContainerInput>
            </ContainerTextFields>
            <ContainerButton>
              <LoaderButton busy={busyChangingEmail}>Zmień maila</LoaderButton>
            </ContainerButton>
          </Form>
        </ContainerForm>
      </ContainerChangePassword>
    </Container>
  )
}
