/* eslint-disable */
import React, {useEffect, useState} from 'react'

import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'
import {SectionLoader} from '../common/sectionLoader'
import TextField from '@mui/material/TextField'
import {BsPen, BsTrash} from "react-icons/bs"
import {MdOutlineAddCircleOutline, MdOutlineCloudUpload} from "react-icons/md"
import {useDispatch, useSelector} from "react-redux";

import InputAdornment from "@mui/material/InputAdornment"
import {serviceActionCreators} from "../../actions/serviceActionCreators";
import {branchActionCreators} from "../../actions/branchActionCreators";
import {Loader} from "../common/loader";
import CircularProgress from "@mui/material/CircularProgress";
import {AiOutlineSave} from "react-icons/ai";
import {PricesModal} from "../common/modal/prices-modal";

import Button from '@mui/material/Button'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'

import {PaginationComponent} from '../common/pagination'
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import plLocale from "date-fns/locale/pl";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {ContainerInput} from "../common/containerInput";
import {costTypeActionCreators} from "../../actions/costTypeActionCreators";
import {formatPrice} from "../../utils/numberUtils";
import {UNASSIGNED_COST_TYPE} from "../../constants/costTypeDict";
import {InstructionLink} from '../common/instructionLink'
import {PricesUploadModal} from "../common/modal/prices-upload-modal";
import {debounce} from "../../utils/debounce";

const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #469546 !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-u9osun.Mui-focused {
    color: #469546 !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }


  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    min-width: 160px;
  }

  .css-18lrjg1-MuiCircularProgress-root {
    color: ${({theme}) => theme.colors.red};
  }
`
const ContainerTop = styled.div`
  display: grid;
  align-items: start;
  justify-items: start;
  grid-template-columns: 1fr 1fr;
`


const StyledContainer = styled.div`
  display: grid;
  grid-template-rows: auto;
  align-content: start;
  gap: 3rem;
`


const ContainerPricesGroups = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-content: start;
  position: relative;
  gap: 2rem;
  min-height: 150px;
`

const ContainerPriceGroup = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 1rem;

`


const HeadingPriceGroup = styled.span`
  color: ${({theme}) => theme.colors.red};
  font-size: ${({theme}) => theme.fonts.size.s};
  font-weight: 500;

`
const PriceGroup = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;
  align-content: start;
  max-width: 800px;

`

const ContainerPriceItem = styled.div`
  display: grid;
  grid-template-columns: auto;
  background: ${({theme}) => theme.colors.white};
  gap: 1rem;
  align-items: center;
`


const StyledTextField = styled(TextField)`


`
const CircularProgressContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`

const ContainerInformation = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: auto;
  padding: 1rem;
  border-radius: 4px;
  grid-template-columns: repeat(2, auto);
  width: 100%;
  justify-content: space-between;
`
const LeftSideInformation = styled.div`
  display: grid;
  grid-template-columns: auto;
  align-items: center;
  justify-content: start;
  gap: 0.5rem;

  @media ${device.tablet} {
    grid-template-columns: repeat(2, auto);
  }

  span {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    gap: 1rem;
    line-height: 1.3;
  }

`
const RightSideInformation = styled.div`
  display: grid;
  grid-template-columns: repeat(3, auto);
  gap: 0.5rem;
  justify-content: end;


  @media ${device.tablet} {

  }

  .css-r47a1p-MuiFormControl-root {
    margin: 0px !important;
  }

  span {
    cursor: pointer;
    padding: 0.3rem;
  }
`


const ContainerRightSideInput = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;
  gap: 0.5rem;

`

const AddItem = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
`

const UploadExcel = styled.div`
  cursor: pointer;
  padding: 1rem;
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 2rem;
  background: rgb(210, 36, 22, 0.04);
  color: ${({theme}) => theme.colors.red};
  border-radius: 3px;
`

const SaveButton = styled.div`
  cursor: pointer;
  display: grid;
  align-items: center;
  justify-items: center;
`

const ContainerHeading = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  gap: 1.5rem;

  @keyframes zoom-in-zoom-out {
    0% {
      transform: scale(.7);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.7);
    }
  }

`

const ContainerButtonInputs = styled.div`
  display: grid;
  grid-template-rows: repeat(2, auto);
  gap: 1.5rem;
  align-content: center;
`

const ContainerDetails = styled.div`
  display: grid;
  grid-template-columns: auto;
  gap: 1rem;

  @media ${device.mobileXL} {
    grid-template-columns: repeat(2, 1fr);
  }

`

const StyledButton = styled(Button)`
  justify-self: center !important;
  min-width: 150px !important;
  background-color: ${({theme}) => theme.colors.red} !important;
`

const StyledContainerInput = styled(ContainerInput)`
  max-width: 250px;
`

const {
  getServicesAction,
  addServiceAction,
  uploadServiceAction,
  removeServiceAction,
  updateServiceAction,
  showAddingServiceModalAction,
  showUploadingServiceModalAction,
  hideAddingServiceModalAction,
  hideUploadingServiceModalAction,
  updateAddingServiceModalAction,
  updateUploadingServiceModalAction,
  showEditingServiceModalAction,
  hideEditingServiceModalAction,
  updateEditingServiceModalAction,
  updateServiceValueAction,
  saveServiceValueAction
} = serviceActionCreators
const {getBranchesAction} = branchActionCreators
const {
  updateCostTypeMonthAction
} = costTypeActionCreators

export const Prices = () => {
  const dispatch = useDispatch()
  const {
    services,
    serviceValues,
    editedServiceValues,
    busyServiceValues,
    errorsServiceValues,
    initialized,
    busy,
    busyAdding,
    busyUpdating,
    busyRemoving,
    addingServiceModal,
    addingService,
    uploadingServiceModal,
    uploadingService,
    editedService,
    errors,
    errorsAdding,
    errorsUpdating,
    errorsRemoving,
    errorsUploading
  } = useSelector(state => state.service)
  const busyBranches = useSelector(state => state.branch.busy)
  const branches = useSelector(state => state.branch.branches)
  const {
    costTypes,
    costTypeValues,
    month,
  } = useSelector(
    (state) => state.costType
  )
  const hoursWorked = useSelector(
    (state) => state.monthStat.monthStat.hoursWorked
  )
  const reloadServices = (val) => {
    dispatch(getServicesAction(val))
  }
  useEffect(() => {
    dispatch(getBranchesAction())
    reloadServices()
  }, [])

  const [currentPage, setCurrentPage] = useState(1)
  const [itemsPerPage, setItemsPerPage] = useState(10)
  const [searchText, setSearchText] = useState(null);

  const [expand, setExpand] = useState(true)
  const toggleAccordion = id => () => setExpand(expand => expand === id ? null : id)


  //Get current items
  const indexOfLastItem = currentPage * itemsPerPage
  const indexOfFirstItem = indexOfLastItem - itemsPerPage
  let usedServices = [];
  if (searchText) {
    usedServices = services.filter((item) => {
      return item.name.toLowerCase().includes(searchText.toLowerCase()) || item.branch.name.toLowerCase().includes(searchText.toLowerCase());
    })
  } else {
    usedServices = services;
  }
  const currentItems = usedServices.slice(indexOfFirstItem, indexOfLastItem)

  let unassignedCostsSummary = 0
  for (let i = 0; i < costTypes.length; i++) {
    const costType = costTypes[i]
    if(costType.type === UNASSIGNED_COST_TYPE) {
      unassignedCostsSummary += parseFloat(costTypeValues[costType.id] ? costTypeValues[costType.id] : 0)
    }
  }
  let workedHoursSummary = 0
  for (const [key, value] of Object.entries(hoursWorked)) {
    if (key) {
      const addon = parseFloat(value)
      workedHoursSummary += isNaN(addon) ? 0 : addon
    }
  }
  // Chane page

  const paginate = (pageNumber) => setCurrentPage(pageNumber)

  return (
    <>
      <PricesModal
        branches={branches}
        showModal={editedService !== null}
        handleClose={() => dispatch(hideEditingServiceModalAction())}
        data={editedService}
        setData={data => dispatch(updateEditingServiceModalAction(data))}
        onSubmit={() => dispatch(updateServiceAction(editedService))}
        errors={errorsUpdating}
        title='Edytuj usługę'
        icon={MdOutlineAddCircleOutline}
        submitLabel="Zapisz pozycję"
      />
      <PricesUploadModal
        showModal={uploadingServiceModal}
        handleClose={() => dispatch(hideUploadingServiceModalAction())}
        onSubmit={() => dispatch(uploadServiceAction(uploadingService))}
        errors={errorsUploading}
        data={uploadingService}
        setData={data => dispatch(updateUploadingServiceModalAction(data))}
      />
      <PricesModal
        branches={branches}
        showModal={addingServiceModal}
        handleClose={() => dispatch(hideAddingServiceModalAction())}
        data={addingService}
        setData={data => dispatch(updateAddingServiceModalAction(data))}
        onSubmit={() => dispatch(addServiceAction(addingService.name, addingService.branch))}
        errors={errorsAdding}
        title='Dodaj usługę'
        icon={MdOutlineAddCircleOutline}
        submitLabel="Dodaj pozycję"
      />
      <StyledWrapper>
        <ContainerHeading>
          <Heading h1 as='h1'>Cennik</Heading>
          <InstructionLink link="/" tooltipText="Zobacz filmik instruktażowy." />
        </ContainerHeading>
        <Line/>
        <StyledContainerInput>
          <span>Wybierz miesiąc</span>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={plLocale}>
            <DatePicker
              id="month"
              views={['year', 'month']}
              renderInput={(props) => <TextField {...props}/>}
              value={month}
              onChange={val => dispatch(updateCostTypeMonthAction(val))}
              onAccept={reloadServices}
            />
          </LocalizationProvider>
        </StyledContainerInput>
        <ContainerTop>
          <Tooltip title="Dodaj usługę">
            <AddItem onClick={() => {
              dispatch(showAddingServiceModalAction())
            }}>
              <MdOutlineAddCircleOutline
                color='#000'
                size='20px'
              />
              <span>Dodaj</span>
            </AddItem>
          </Tooltip>
          <Tooltip title="Wgraj cennik w excelu">
            <AddItem onClick={() => {
              dispatch(showUploadingServiceModalAction())
            }}>
              <MdOutlineCloudUpload color="#000" size="20px" />
              <span>Wgraj cennik</span>
            </AddItem>
          </Tooltip>
        </ContainerTop>
        <StyledContainer>
          <ContainerPricesGroups>
            {busy || busyBranches ? <SectionLoader/> :
              <>

                <ContainerPriceGroup>
                  <PriceGroup>
                    <StyledTextField
                        label="Szukaj zabiegu"
                        onChange={debounce((e) => {paginate(1); setSearchText(e.target.value)})}
                    />
                    {currentItems.map((service, index) => {
                      const edited = editedServiceValues.includes(service.id)
                      const busy = busyServiceValues.includes(service.id)
                      const errors = errorsServiceValues[service.id]
                      const priceFloat = serviceValues[service.id] ? parseFloat(serviceValues[service.id].value) : 0
                      const timeInteger = serviceValues[service.id] ? parseInt(serviceValues[service.id].time) : 0
                      const salaryInteger = serviceValues[service.id] ? parseInt(serviceValues[service.id].salary) : 0
                      const costsPerHourFloat = workedHoursSummary > 0 ? parseFloat(unassignedCostsSummary / workedHoursSummary) : 0
                      let assignedCostsSummary = 0
                      for (let i = 0; i < (serviceValues[service.id] ? serviceValues[service.id].servicePriceCosts.length : 0); i++) {
                        assignedCostsSummary += parseFloat(serviceValues[service.id].servicePriceCosts[i].price ?? 0)
                      }
                      const doctorIncome = parseFloat((priceFloat - assignedCostsSummary) * (salaryInteger / 100))
                      const cabinetIncome = parseFloat(priceFloat - assignedCostsSummary - (costsPerHourFloat * (timeInteger / 60)) - doctorIncome)
                      return <ContainerPriceItem key={service.id}>

                        <Accordion expanded={expand === index}>

                          <AccordionSummary
                            expandIcon={
                              <IconButton onClick={toggleAccordion(index)}>
                                <ExpandMoreIcon/>
                              </IconButton>
                            }
                            aria-controls="panel1a-content"
                          >

                            <ContainerInformation>
                              <LeftSideInformation>
                                {service.branch && (
                                  <HeadingPriceGroup>{service.branch.name}</HeadingPriceGroup>)}<span>{service.name}</span>
                              </LeftSideInformation>
                              <RightSideInformation>
                                {service.user && <Tooltip title="Edytuj usługę">
                                  <IconButton
                                    onClick={() => {
                                      dispatch(showEditingServiceModalAction(service))
                                    }}>
                                    <BsPen color='#000' size='17px'/>
                                  </IconButton>
                                </Tooltip>}
                                {service.user && <Tooltip title="Usuń usługę">
                                  <IconButton
                                    onClick={() => {
                                      dispatch(removeServiceAction(service.id))
                                    }}>
                                    <BsTrash
                                      color='#000'
                                      size='17px'
                                    />
                                  </IconButton>
                                </Tooltip>}
                              </RightSideInformation>
                            </ContainerInformation>
                          </AccordionSummary>
                          <AccordionDetails>
                            <ContainerButtonInputs>
                              <ContainerDetails>
                                <StyledTextField
                                  label="Kwota zabiegu"
                                  disabled={busy}
                                  value={serviceValues[service.id] ? serviceValues[service.id].value : ''}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={e => {
                                    const val = (e.target.value >= 0 || e.target.value === '') ? e.target.value : '0'
                                    dispatch(updateServiceValueAction(service.id, {value: val}))
                                  }}
                                  type="number"
                                  min="0"
                                  inputProps={{
                                    step: 0.01
                                  }}
                                  helperText={errors}
                                  InputProps={{
                                    endAdornment:
                                      <ContainerRightSideInput>
                                        <InputAdornment position="end">PLN</InputAdornment>
                                      </ContainerRightSideInput>
                                  }}/>
                                <StyledTextField
                                  label="Czas zabiegu"
                                  disabled={busy}
                                  value={serviceValues[service.id] ? serviceValues[service.id].time : ''}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={e => {
                                    const val = (e.target.value >= 0 || e.target.value === '') ? e.target.value : '0'
                                    dispatch(updateServiceValueAction(service.id, {time: val}))
                                  }}
                                  type="number"
                                  min="0"
                                  inputProps={{
                                    step: 1
                                  }}
                                  InputProps={{
                                    endAdornment:
                                      <ContainerRightSideInput>
                                        <InputAdornment position="end">min</InputAdornment>
                                      </ContainerRightSideInput>
                                  }}/>
                                <StyledTextField
                                  label="Wynagrodzenie"
                                  disabled={busy}
                                  value={serviceValues[service.id] ? serviceValues[service.id].salary : ''}
                                  onWheel={(e) => e.target.blur()}
                                  onChange={e => {
                                    const val = (e.target.value >= 0 || e.target.value === '') ? e.target.value : '0'
                                    dispatch(updateServiceValueAction(service.id, {salary: val}))
                                  }}
                                  type="number"
                                  min="0"
                                  inputProps={{
                                    step: 1
                                  }}
                                  InputProps={{
                                    endAdornment:
                                      <ContainerRightSideInput>
                                        <InputAdornment position="end">%</InputAdornment>
                                      </ContainerRightSideInput>
                                  }}/>

                                <StyledTextField
                                  label="Koszty stałe/godz"
                                  disabled={true}
                                  value={formatPrice(costsPerHourFloat)}
                                  onWheel={(e) => e.target.blur()}
                                  // onChange={e => {
                                  //   const val = (e.target.value >= 0 || e.target.value === '') ? e.target.value : '0'
                                  //   dispatch(updateServiceValueAction(service.id, {costsPerHour: val}))
                                  // }}
                                  type="number"
                                  min="0"
                                  inputProps={{
                                    step: 0.01
                                  }}
                                  InputProps={{
                                    endAdornment:
                                      <ContainerRightSideInput>
                                        <InputAdornment position="end">PLN</InputAdornment>
                                      </ContainerRightSideInput>
                                  }}/>

                                <StyledTextField
                                  label="Kwota dla lekarza"
                                  disabled={true}
                                  value={formatPrice(doctorIncome)}
                                  onWheel={(e) => e.target.blur()}
                                  type="number"
                                  min="0"
                                  InputProps={{
                                    endAdornment:
                                      <ContainerRightSideInput>
                                        <InputAdornment position="end">PLN</InputAdornment>
                                      </ContainerRightSideInput>
                                  }}/>
                                <StyledTextField
                                  label="Zysk dla gabinetu"
                                  disabled={true}
                                  value={formatPrice(cabinetIncome)}
                                  onWheel={(e) => e.target.blur()}
                                  type="number"
                                  min="0"
                                  InputProps={{
                                    endAdornment:
                                      <ContainerRightSideInput>
                                        <InputAdornment position="end">PLN</InputAdornment>
                                      </ContainerRightSideInput>
                                  }}/>
                                {serviceValues[service.id] && serviceValues[service.id].servicePriceCosts.map(spc => {
                                  return <StyledTextField
                                    key={spc.id}
                                    label={spc.name}
                                    disabled={busy}
                                    value={spc.price ?? ''}
                                    onWheel={(e) => e.target.blur()}
                                    onChange={e => {
                                      const val = (e.target.value >= 0 || e.target.value === '') ? e.target.value : '0'
                                      let spcArray = []
                                      for (let i = 0; i < serviceValues[service.id].servicePriceCosts.length; i++) {
                                        if (serviceValues[service.id].servicePriceCosts[i].parentId === spc.parentId) {
                                          spcArray[i] = {...serviceValues[service.id].servicePriceCosts[i], price: val}
                                        } else {
                                          spcArray[i] = serviceValues[service.id].servicePriceCosts[i]
                                        }
                                      }
                                      dispatch(updateServiceValueAction(service.id, {servicePriceCosts: spcArray}))
                                    }}
                                    type="number"
                                    min="0"
                                    inputProps={{
                                      step: 0.01
                                    }}
                                    InputProps={{
                                      endAdornment:
                                        <ContainerRightSideInput>
                                          <InputAdornment position="end">PLN</InputAdornment>
                                        </ContainerRightSideInput>
                                    }}/>
                                })}


                              </ContainerDetails>
                              {edited && (busy ?
                                  <CircularProgressContainer><CircularProgress size={30}/></CircularProgressContainer> :
                                  <StyledButton variant="contained" onClick={() => {
                                    dispatch(saveServiceValueAction(service, serviceValues[service.id] ? serviceValues[service.id] : ''))
                                  }}>Zapisz</StyledButton>
                              )}
                            </ContainerButtonInputs>
                          </AccordionDetails>
                        </Accordion>
                      </ContainerPriceItem>
                    })}
                  </PriceGroup>
                </ContainerPriceGroup>
                <PaginationComponent totalItems={usedServices.length} itemsPerPage={itemsPerPage} paginate={paginate}/>
              </>
            }
          </ContainerPricesGroups>

        </StyledContainer>
      </StyledWrapper>
    </>
  )
}



