/* eslint-disable */
import React, {useState} from 'react'
import styled from 'styled-components'
import {device} from '../../utils/breakpoints'
import {Heading} from '../common/heading'
import {Line} from '../common/line'


const StyledWrapper = styled.div`
  display: grid;
  grid-template-rows: auto;
  gap: 2rem;
  align-content: start;
  background: ${({theme}) => theme.colors.white};
  box-shadow: 1px 1px 0px 0px rgba(0, 0, 0, 0.06);
  display: grid;
  grid-template-columns: auto;
  padding: 7rem 1.5rem;

  @media ${device.laptop} {
    padding: 2rem 1.5rem;
  }

  .css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
    color: #469546 !important;
  }

  .css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-u9osun.Mui-focused {
    color: #469546 !important;
  }

  .css-1v4ccyo.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-fvipm8.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }

  .css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #469546 !important;
  }


  .css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    min-width: 160px;
  }

  .css-18lrjg1-MuiCircularProgress-root {
    color: ${({theme}) => theme.colors.red};
  }
`

const ContainerTop = styled.div `
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;


    .active {
        border-bottom: 1px solid ${({theme}) => theme.colors.red} !important;   
        color: ${({theme}) => theme.colors.red};
    }

`

const ContainerSettingsTab = styled.div `
    display: grid;
    align-items: center;
    cursor: pointer;
    justify-items: center;
    border-bottom: 1px solid #F0F0F0;
    padding-bottom: 2rem;

    
`


export const AdministratorPanel = () => {

    const [view, setView] = useState(0)

    return (
        <StyledWrapper>
            <Heading h1 as='h1'>Ustawienia</Heading>
            <Line/>
            <ContainerTop>
                <ContainerSettingsTab className={view === 0 && 'active'} onClick={() => setView(0)}>
                    <span>Ustawienia ogólne</span>
                </ContainerSettingsTab>
                <ContainerSettingsTab className={view === 1 && 'active'} onClick={() => setView(1)}>
                    <span>Uprawnienia osób</span>
                </ContainerSettingsTab>
            </ContainerTop>
        </StyledWrapper>
    )
}